import React from 'react';
import { Button } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import PropTypes from 'prop-types';
import '../App.css';
import logo from '../assets/unauthorized.svg';

const UnauthorizedUser = ({ isOpen, handleClose }) => (
  <Dialog onClose={handleClose} open={isOpen} aria-labelledby="form-dialog-title">
    <DialogTitle id="form-dialog-title"> Unauthorized User </DialogTitle>
    <DialogContent>
      <DialogContentText component="div">
        <div className="text-center">
          <img title="Unauthorized User" alt="Unauthorized User" width={250} height={250} src={logo} />
        </div>
        Please reach out to your County Welfare Department for assistance.
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={handleClose} color="primary">
        Ok
      </Button>
    </DialogActions>
  </Dialog>
);

UnauthorizedUser.propTypes = {
  profile: PropTypes.shape({ name: PropTypes.string }),
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func,
};

UnauthorizedUser.defaultProps = {
  profile: { name: '' },
  handleClose: () => { },
};

export default UnauthorizedUser;
