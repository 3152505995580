const isPositiveInteger = (string) => {
  const number = Number(string);
  const isInteger = Number.isInteger(number);
  const isPositive = number > 0;
  return isInteger && isPositive;
};

const validationFunctions = {
  required: (text) => text.length > 0,
  minLength: (text, min) => text.length >= min.minLength,
  maxLength: (text, max) => text.length <= max.maxLength,
  isNumeric: (text) => isPositiveInteger(text),
  isCountyCode: (text) => /^\d{2}$/.test(text) && Number(text) > 0 && Number(text) <= 58,
};

function validate(value, validationObj, hidden) {
  let shortCircut = null;
  if (hidden || validationObj.length === 0) {
    return shortCircut;
  }

  for (let i = 0; i < validationObj.length; i++) {
    if (validationObj[i].type !== 'required' && value.text === '') {
      break;
    }

    const didPass = validationFunctions[validationObj[i].type].apply(null, [value.text, ...validationObj[i].args]);
    if (didPass === false) {
      const { message } = validationObj[i];
      shortCircut = { message };
      break;
    }
  }
  return shortCircut;
}

export default validate;
