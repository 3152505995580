import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { styled } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

const CssDatePicker = styled(DatePicker)({
  '& .MuiIconButton-root': {
    padding: 14,
  },
  '& .MuiSvgIcon-root': {
    fill: '#3f51b5',
  },
  '& .MuiInputLabel-root': {
    borderColor: 'grey',
    fontWeight: '500',
    '&.Mui-error': {
      color: '#b71c1c !important',
    },
  },
  '& .MuiFormHelperText-root': {
    '&.Mui-error': {
      color: '#b71c1c',
      fontWeight: 400,
    },
  },
  '& .MuiInputBase-root': {
    letterSpacing: '0.13em',
  },
  '&.MuiFormControl-root': {
    marginTop: '0.5em',
    marginBottom: '0.5em',
  },
  '& .MuiInputLabel-outlined': {
    '&.MuiInputLabel-shrink': {
      transform: 'translate(14px, -6px) scale(1)',
    },
  },
  '& .MuiOutlinedInput-input': {
    letterSpacing: 'normal',
    '&::placeholder': {
      opacity: 0.6,
      fontWeight: 500,
      fontSize: '18px',
    },
  },
  '& .MuiOutlinedInput-root': {
    borderRadius: '5px',
    '& fieldset': {
      border: '1px solid rgb(40, 72, 141)',
      borderColor: 'rgb(40, 72, 141) !important',
    },
    '&.Mui-error fieldset': {
      borderColor: '#b71c1c !important',
    },
  },
});

const CHDatePicker = ({
  label, id, handler, format, helperText, minDate, maxDate,
  minDateMessage, maxDateMessage, required, errorHandler,
  invalidDateMessage, sx, hidden,
}) => {
  const [pristine, setPristine] = useState(true);
  const [selectedDate, setSelectedDate] = useState(null);
  const [value, setFormattedDate] = useState({ required, id });
  const [hasError, setError] = useState(false);
  const [message, setMessage] = useState(helperText);

  const handleDateChange = (date) => {
    setSelectedDate(date);
    /*
      need to do a null check here because when the date gets deleted the value
      comes back as null unlike in CHTextfield which comes back as an empty string
    */
    setFormattedDate({ ...value, id, text: date === null ? null : date.format('MM/DD/YYYY') });
    setPristine(false);
  };

  const handleError = (err, date) => {
    if (err !== '' && err !== null && date !== null) {
      setMessage(invalidDateMessage);
      setError(true);
      value.hasError = true;
      setFormattedDate(value);
    }
    else if (date === null || err === '' || err === null) {
      setMessage(helperText);
      setError(false);
      delete value.hasError;
      setFormattedDate(value);
    }
  };

  useEffect(() => {
    handler(value);
    errorHandler({ value, pristine });
  }, [value, handler, errorHandler, pristine]);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <CssDatePicker
        id={id}
        type="search"
        onError={handleError}
        variant="outlined"
        format={format}
        aria-label={label}
        value={selectedDate}
        color="primary"
        minDate={dayjs(minDate)}
        maxDate={dayjs(maxDate)}
        required={required}
        minDateMessage={minDateMessage}
        maxDateMessage={maxDateMessage}
        disableFuture
        slotProps={{
          textField: {
            helperText: message,
            invaliddatemessage: invalidDateMessage,
          },
          inputAdornment: {
            position: 'end',
            color: 'primary',
            title: 'Pick a date',
          },
        }}
        error={hasError}
        openTo="year"
        autoComplete="off"
        label={label}
        views={['year', 'month', 'day']}
        KeyboardButtonProps={{ 'aria-label': 'Date Picker button' }}
        onChange={(date) => handleDateChange(date)}
        sx={sx}
        hidden={hidden}
      />
    </LocalizationProvider>
  );
};

CHDatePicker.propTypes = {
  label: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  addAdornment: PropTypes.shape({ position: PropTypes.string }),
  handler: PropTypes.func,
  helperText: PropTypes.string,
  format: PropTypes.string.isRequired,
  minDate: PropTypes.string.isRequired,
  maxDate: PropTypes.string.isRequired,
  minDateMessage: PropTypes.string.isRequired,
  maxDateMessage: PropTypes.string.isRequired,
  required: PropTypes.bool,
  errorHandler: PropTypes.func.isRequired,
  invalidDateMessage: PropTypes.string.isRequired,
  sx: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool]),
    ),
    PropTypes.func,
    PropTypes.object,
  ]),
  hidden: PropTypes.bool,
};

CHDatePicker.defaultProps = {
  addAdornment: null,
  handler: () => { },
  helperText: '',
  required: false,
  sx: {},
  hidden: false,
};

export default CHDatePicker;
