import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import '../App.css';

const CHDialog = ({
  title, contentText, contentTextPlacement, handleClose, isOpen, children, maxWidth, id, fullWidth, buttonText,
}) => (
  <Dialog
    fullWidth={fullWidth}
    maxWidth={maxWidth}
    role="presentation"
    open={isOpen}
    onClose={handleClose}
    aria-labelledby={`${id}-modal`}
  >
    {
      title !== null
        ? (
          <DialogTitle id="form-dialog-title">
            {title}
          </DialogTitle>
        )
        : null
    }
    <DialogContent>
      {
        contentText && contentTextPlacement === 'top'
          ? (
            <>
              <DialogContentText>
                {contentText}
              </DialogContentText>
              {children}
            </>
          )
          : null
      }
      {
        contentText && contentTextPlacement === 'bottom'
          ? (
            <>
              {children}
              <DialogContentText>
                {contentText}
              </DialogContentText>
            </>
          )
          : null
      }
      {
        !contentText
          ? children
          : null
      }
    </DialogContent>
    <DialogActions>
      <Button onClick={handleClose} color="primary">
        {buttonText}
      </Button>
    </DialogActions>
  </Dialog>
);

CHDialog.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string,
  contentText: PropTypes.string,
  handleClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  contentTextPlacement: PropTypes.oneOf(['top', 'bottom']),
  children: PropTypes.element.isRequired,
  maxWidth: PropTypes.string,
  fullWidth: PropTypes.bool,
  buttonText: PropTypes.string,
};

CHDialog.defaultProps = {
  title: null,
  contentText: null,
  contentTextPlacement: 'top',
  maxWidth: 'md',
  fullWidth: true,
  buttonText: '',
};

export default CHDialog;
