import React, { useCallback, useEffect, useMemo } from 'react';
import dayjs from 'dayjs';
import { useDispatch, useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import CHRadioGroupRow from '../shared-components/CHRadioGroupRow';
import CHSelectCounty from '../shared-components/CHSelectCounty';
import CHTextField from '../shared-components/CHTextField';
import CHButton from '../shared-components/CHButton';
import CHDatePicker from '../shared-components/CHDatePicker';

import {
  handleValues,
  handlePossibleFormErrors,
  searchCriteriaSelector,
  formErrorChecker,
  attemptedFormSubmission,
  isFormBeingSubmitted,
  getFormSearchMethod,
  setFormSearchMethod,
} from '../store/SearchCriteria.slice';
import {
  initSearch,
  loadSearch,
  searchError,
} from '../store/Search.slice';
import {
  getAccessToken, getMonths, generateMonths, getCSRFToken,
} from '../store/AppState.slice';
import apiRequest, {
  handleMonthlyReport, SEARCH_CHILD_CARE_ENDPOINT,
} from '../api/apiRequest';
import '../App.css';

const SearchCriteria = () => {
  const dispatch = useDispatch();

  const criteria = useSelector(searchCriteriaSelector);
  const errorCheck = useSelector(formErrorChecker);
  const accessToken = useSelector(getAccessToken);
  const csrfToken = useSelector(getCSRFToken);
  const isFormSubmissionHappening = useSelector(isFormBeingSubmitted);
  const selectedSearchMethod = useSelector(getFormSearchMethod);
  const months = useSelector(getMonths);

  const search = () => {
    dispatch(initSearch());

    apiRequest(
      SEARCH_CHILD_CARE_ENDPOINT.method, SEARCH_CHILD_CARE_ENDPOINT.url,
      criteria, null, { accessToken, CSRF_STATE_TOKEN: csrfToken },
    ).then((data) => {
      if (data) {
        dispatch(loadSearch(data));
      }
      else {
        dispatch(searchError());
      }
    }).catch((err) => {
      dispatch(searchError(JSON.stringify(err.message)));
    });
  };

  const errorHandler = useCallback((value) => {
    if (value.value.id && !value.hidden) {
      // only attempt to validate if not hidden
      dispatch(handlePossibleFormErrors(value));
    }
  }, [dispatch]);

  const handleSearchMethodSelection = useCallback((value) => {
    dispatch(setFormSearchMethod(value.text));
  }, [dispatch]);

  const aggregateValues = useCallback((value) => {
    if (value) {
      if (value.id === 'searchMethod') {
        console.log('Trigger State Change on Radio Change', value.text);
      }
      else {
        dispatch(handleValues(value));
      }
    }
  }, [dispatch]);

  const checkCriteria = () => {
    dispatch(attemptedFormSubmission());
    if (errorCheck === false) {
      search(criteria);
    }
  };

  const getReport = (monthAndYear) => {
    // get the CSRF Token from App State
    handleMonthlyReport(monthAndYear, { accessToken, CSRF_STATE_TOKEN: csrfToken }).then((data) => {
      const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onload = () => {
        const tempElement = document.createElement('a');
        tempElement.href = reader.result;
        tempElement.download = `${monthAndYear}_ChildCareMonthlyReport.xlsx`;
        document.body.appendChild(tempElement);
        tempElement.click();
        document.body.removeChild(tempElement);
      };
    }).catch(() => {
      console.debug(`An error ocurred downloading monthly report ${monthAndYear}`);
    });
  };

  useEffect(() => {
    if (months.length === 0) {
      dispatch(generateMonths());
    }
  }, [dispatch, months]);

  return (

    <section className={`center-search-items ${typeof month === 'string' ? 'center-no-months' : ''}`}>

      <Box>
        <CHRadioGroupRow
          isRequired
          id="searchMethod"
          helperText=""
          selectedValue={selectedSearchMethod}
          handler={handleSearchMethodSelection}
          formSubmitting={isFormSubmissionHappening}
          errorHandler={errorHandler}
        />
      </Box>
      <p
        style={{ display: (selectedSearchMethod === 'searchByCase') ? 'block' : 'none' }}
        className="primary bold"
      >
        Please enter Case Number and select County:
      </p>
      <CHTextField
        key="caseNumKey"
        id="caseNum"
        label="Case Number"
        type="search"
        variant="outlined"
        color="primary"
        helperText=""
        isRequired
        handler={aggregateValues}
        maxLength={7}
        sx={{ display: (selectedSearchMethod === 'searchByCase') ? 'inline-flex' : 'none' }}
        hidden={selectedSearchMethod !== 'searchByCase'}
        validation={useMemo(() => [
          { type: 'required', message: 'Case Number is required', args: [] },
        ], [])}
        formSubmitting={isFormSubmissionHappening}
        errorHandler={errorHandler}
      />
      <CHSelectCounty
        isRequired
        id="countyCode"
        helperText=""
        variant="outlined"
        color="primary"
        handler={aggregateValues}
        validation={useMemo(() => [
          { type: 'required', message: 'County is required', args: [] },
          { type: 'isCountyCode', message: 'County is required', args: [] },
        ], [])}
        sx={{ display: (selectedSearchMethod === 'searchByCase') ? 'inline-flex' : 'none' }}
        hidden={selectedSearchMethod !== 'searchByCase'}
        formSubmitting={isFormSubmissionHappening}
        errorHandler={errorHandler}
      />
      <p
        style={{ display: (selectedSearchMethod === 'searchByName') ? 'inline-flex' : 'none' }}
        className="primary bold"
      >
        Please enter exact parent/caretaker name below:
      </p>
      <CHTextField
        className="firstname"
        id="firstName"
        label="First Name"
        type="search"
        variant="outlined"
        helperText=""
        color="primary"
        isRequired
        validation={useMemo(() => [
          { type: 'required', message: 'First Name is required', args: [] },
        ], [])}
        maxLength={30}
        formSubmitting={isFormSubmissionHappening}
        handler={aggregateValues}
        errorHandler={errorHandler}
        sx={{ display: (selectedSearchMethod === 'searchByName') ? 'inline-flex' : 'none' }}
        hidden={selectedSearchMethod !== 'searchByName'}
      />
      <CHTextField
        id="middleInitial"
        label="Middle Initial"
        type="search"
        variant="outlined"
        color="primary"
        helperText="Optional"
        className="optional"
        handler={aggregateValues}
        formSubmitting={isFormSubmissionHappening}
        maxLength={1}
        errorHandler={errorHandler}
        sx={{ display: (selectedSearchMethod === 'searchByName') ? 'inline-flex' : 'none' }}
        hidden={selectedSearchMethod !== 'searchByName'}
      />
      <CHTextField
        className="lastname"
        id="lastName"
        label="Last Name"
        type="search"
        variant="outlined"
        helperText=""
        isRequired
        validation={useMemo(() => [
          { type: 'required', message: 'Last Name is required', args: [] },
        ], [])}
        formSubmitting={isFormSubmissionHappening}
        color="primary"
        maxLength={30}
        handler={aggregateValues}
        errorHandler={errorHandler}
        sx={{ display: (selectedSearchMethod === 'searchByName') ? 'inline-flex' : 'none' }}
        hidden={selectedSearchMethod !== 'searchByName'}
      />
      <CHTextField
        key="caseNumberKey"
        id="caseNumber"
        label="Case Number"
        type="search"
        variant="outlined"
        color="primary"
        helperText="Optional"
        className="optional"
        handler={aggregateValues}
        maxLength={7}
        formSubmitting={isFormSubmissionHappening}
        errorHandler={errorHandler}
        sx={{ display: (selectedSearchMethod === 'searchByName') ? 'inline-flex' : 'none' }}
        hidden={selectedSearchMethod !== 'searchByName'}
      />
      <CHTextField
        id="zipCode"
        label="Physical Zip Code"
        type="search"
        variant="outlined"
        color="primary"
        helperText="Optional"
        className="optional"
        handler={aggregateValues}
        formSubmitting={isFormSubmissionHappening}
        validation={useMemo(() => [
          { type: 'minLength', message: 'Invalid Physical Zip Code format', args: [{ minLength: 5 }] },
        ], [])}
        maxLength={5}
        errorHandler={errorHandler}
        sx={{ display: (selectedSearchMethod === 'searchByName') ? 'inline-flex' : 'none' }}
        hidden={selectedSearchMethod !== 'searchByName'}
      />
      <CHDatePicker
        id="dob"
        label="Date of Birth"
        type="search"
        format="MM/DD/YYYY"
        variant="outlined"
        color="primary"
        maxDate={dayjs().format('DD/MM/YYYY')}
        minDate="1900-01-01"
        helperText="Optional"
        maxDateMessage="Date of Birth cannot be in the future"
        minDateMessage="Date of Birth cannot be before 01/01/1900"
        invalidDateMessage="Invalid Date of Birth format"
        handler={aggregateValues}
        formSubmitting={isFormSubmissionHappening}
        errorHandler={errorHandler}
        sx={{ display: (selectedSearchMethod === 'searchByName') ? 'inline-flex' : 'none' }}
        hidden={selectedSearchMethod !== 'searchByName'}
      />
      <br />
      <div className="flex-column squish">
        <CHButton
          id="search-btn"
          color="primary"
          variant="outlined"
          buttonText="Search"
          classNames="push-down search-criteria"
          handleClick={checkCriteria}
        />
      </div>
      <div className="flex-column squish historical-reports">
        {
          typeof months !== 'string'
            ? (
              <>
                <p className="primary bold"> Historical Reports: </p>
                {
                  months.map((month, index) => (
                    <CHButton
                      id={`historical${index}-btn`}
                      key={`${month.number}-button`}
                      color="secondary"
                      variant="outlined"
                      buttonText={month.word}
                      classNames="push-down"
                      handleClick={() => getReport(month.number)}
                    />
                  ))
                }
              </>
            )
            : null
        }
      </div>
    </section>

  );
};

export default React.memo(SearchCriteria);
