import React from 'react';
import PropTypes from 'prop-types';
import { Card } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';

const CHAddressCard = ({ physical, mailing }) => (

  <Card>
    <Table aria-label="simple table">
      <TableBody>
        <TableRow>
          <TableCell scope="row" className="card-label-column">
            Mailing
          </TableCell>
          <TableCell align="left">
            {mailing || '-'}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell scope="row" className="card-label-column">
            Physical
          </TableCell>
          <TableCell align="left">
            {physical || '-'}
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  </Card>

);

CHAddressCard.propTypes = {
  physical: PropTypes.string,
  mailing: PropTypes.string,
};

CHAddressCard.defaultProps = {
  physical: '-',
  mailing: '-',
};

export default CHAddressCard;
