import React from 'react';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import logo from '../assets/no_hits.svg';
import mainLogo from '../assets/too-many-results.png';
import beacon from '../assets/beacon_img.PNG';

export const SummaryContent = () => (
  <p>
    The
    <b> Summary </b>
    page displays detailed information on a record. It contains 3 sections; lets take a look at each of them.
  </p>
);
export const DetailsContent = () => (
  <p>
    The
    <b> Parent/Caretaker Information </b>
    section contains the Name, DOB, Status, Last Active Month of CalWORKS,
    Reason for Needing Child Care, Phone Numbers, E-mail, and Address Information for each parent or caretaker.
  </p>
);
export const ChildrenContent = () => (
  <p>
    The
    <b> Children Information </b>
    section contains the Name, DOB, Status, and Number of hours on Child Care authorized on the Stage One
    Child Care authorization information.
  </p>
);
export const ChildcareProviderContent = () => (
  <p>
    In addition, the
    <b> Children Information </b>
    section contains the
    <b> Child Care Provider </b>
    information, where you can find the Provider, Provider
    Rates, Address, and Phone Numbers information.
  </p>
);
export const AddSectionContent = () => (
  <p>
    The
    <b> Additional Information </b>
    section contains Income Information, Family Breakdown, and Other CalWORKs AU, and non-AU family members in the
    household information.
  </p>
);

export const HeaderPanel = () => (
  <p>
    The Header Panel contains the
    <b> Report an Issue</b>
    ,
    <b> Help</b>
    , and
    <b> Logout </b>
    buttons.
  </p>
);

export const ReportIssueContent = () => (
  <p>
    The
    <b> Report an Issue </b>
    button will be supported in a future release.
  </p>
);

export const GetHelpContent = () => (
  <p>
    The
    <b> Help </b>
    button lets you run the Site Walkthrough or launch the CalSAWS Functional Presentation Video.
  </p>
);
export const FirstNameContent = () => (
  <p>
    The
    <b> First Name</b>
  </p>
);
export const LogoutContent = () => (
  <p>
    The
    <b> Logout </b>
    button terminates a user&apos;s section and redirects the user to the login page.
  </p>
);
export const LastNameContent = () => (
  <p>
    and the
    {' '}
    <b>Last Name</b>
    .
  </p>
);
export const SearchCriteriaContent = () => (
  <p>
    Clicking the
    <b> Search </b>
    button runs your query and displays the results in
    the Results panel, which we will explore in more detail later.
  </p>
);
export const HistoricalContent = () => (
  <p>
    The historical reports section lets you access the three most recent
    CalWORKs Stage Two Administrator monthly reports, sorted from newest to oldest.
  </p>
);
export const SearchCriteriaNoResultsContent = () => (
  <div>
    <p>
      When a search query does not return results, a
      <b> No Results </b>
      message displays in the results panel.
    </p>
    <h3 style={{ color: '#2738A5' }}>We couldn&apos;t find anything</h3>
    <img src={logo} alt="no-results" width="150px" height="150px" />
    <p style={{ color: '#2738A5' }}>Try entering new search criteria</p>
  </div>
);
export const SearchCriteriaTooManyContent = () => (
  <div>
    <p>
      When a search returns over 20 results,
      a message directs you to enter additional search criteria to refine your search.
    </p>
    <h3 style={{ color: '#2738A5' }}> Too many results! </h3>
    <img width="150px" height="150px" src={mainLogo} alt="too-many-results" />
    <p style={{ color: '#2738A5' }}>
      Let&apos;s narrow this down. Try entering additional
      <br />
      search criteria.
    </p>
  </div>
);
export const ExampleSearchContent = () => (
  <div>
    <p>
      Lets try searching! Type in a name and click
      <b> Search. </b>
      Continue the tour by clicking on the blinking beacon upon receiving the search results.
    </p>
    <img src={beacon} alt="beacon" />
  </div>
);

export const InfoRowContent = () => (
  <div>
    <p>
      Hovering over a search result row shows an ellipsis icon
      {' '}
      <MoreHorizIcon style={{ fontSize: '12px' }} />
      {' '}
      on the far right.
      Click on it to view the
      <b> Summary </b>
      page. When it loads, click on the blinking beacon to continue the tour.
    </p>
  </div>
);
