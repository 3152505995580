import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { revokeToken, signout } from '../api/apiRequest';
import '../App.css';
import { getAccessToken, logout } from '../store/AppState.slice';

const Unauthorized = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const accessToken = useSelector(getAccessToken);

  revokeToken(accessToken).then(() => {
    signout().then(() => {
      dispatch(logout());
      history.push('/redirect');
    }).catch(() => {
      document.cookie = 'iPlanetDirectoryPro=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
      sessionStorage.clear();
      dispatch(logout());
      history.push('/redirect');
    });
  }).catch(() => {
  });

  return (
    <>
      <section />
    </>
  );
};

export default Unauthorized;
