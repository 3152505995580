import React, { useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Card } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import TableRow from '@mui/material/TableRow';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import Grid from '@mui/material/Grid';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import CHBox from '../shared-components/CHBox';
import {
  initCaseDetails,
  loadCaseDetails,
  errorLoadingCaseDetails,
  areCaseDetailsLoading,
  areCaseDetailsLoaded,
  getCaseDetails,
  cleanup,
  caseDetailsHasError,
} from '../store/CaseDetails.slice';
import apiRequest, { DETAIL_CHILD_CARE_ENDPOINT } from '../api/apiRequest';
import { getAccessToken, isWalkthroughHappening, getCSRFToken } from '../store/AppState.slice';
import ChildInfo from '../components/ChildInfo';
import CHNameCard from '../shared-components/CHNameCard';
import TabPanel from '../components/TabPanel';
import CHTabbedSection from '../shared-components/CHTabbedSection';
import CHLoading from '../shared-components/CHLoading';
import TourSummaryPage from '../components/TourSummaryPage';
import SysDownLogo from '../components/SysDownLogo';

const AntTabs = withStyles({
  root: {
    borderBottom: '1px solid #e8e8e8',
  },
  indicator: {
    backgroundColor: '#3f51b5',
  },
})(Tabs);

const AntTab = withStyles((theme) => ({
  root: {
    textTransform: 'none',
    minWidth: 72,
    fontWeight: theme.typography.fontWeightBold,
    marginRight: theme.spacing(4),
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:hover': {
      color: '#2738A5',
      opacity: 1,
    },
    '&$selected': {
      color: '#3f51b5',
    },
    '&:focus': {
      color: '#2738A5',
    },
  },
  selected: {},
}))((props) => <Tab disableRipple {...props} />);

const UserDetails = () => {
  const dispatch = useDispatch();
  const { caseNumber, programId, programPersonId } = useParams();
  const [value, setValue] = React.useState(0);
  const accessToken = useSelector(getAccessToken);
  const csrfToken = useSelector(getCSRFToken);
  const caseDetailsIsLoading = useSelector(areCaseDetailsLoading);
  const caseDetailsHasLoaded = useSelector(areCaseDetailsLoaded);
  const walkthroughInProgress = useSelector(isWalkthroughHappening);
  const castDetailsHasError = useSelector(caseDetailsHasError);

  const data = useSelector(getCaseDetails);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (caseDetailsIsLoading === false
      && caseDetailsHasLoaded === false
      && castDetailsHasError === false
      && accessToken !== null
      && csrfToken !== null
    ) {
      dispatch(initCaseDetails());
      apiRequest(
        DETAIL_CHILD_CARE_ENDPOINT.method,
        `${DETAIL_CHILD_CARE_ENDPOINT.url}/${programPersonId}/${caseNumber}`,
        null, null, { accessToken, CSRF_STATE_TOKEN: csrfToken },
      ).then((response) => {
        dispatch(loadCaseDetails(response));
      }).catch((err) => {
        dispatch(errorLoadingCaseDetails(err));
      });
    }
    return () => {
      if (caseDetailsIsLoading === false
        && (caseDetailsHasLoaded === true || castDetailsHasError === true)) {
        dispatch(cleanup());
      }
    };
  }, [castDetailsHasError,
    caseNumber, dispatch,
    caseDetailsIsLoading, caseDetailsHasLoaded, programId, programPersonId, accessToken, csrfToken]);

  function a11yProps(index) {
    return {
      id: `scrollable-auto-tab-${index}`,
      'aria-controls': `scrollable-auto-tabpanel-child-${index}`,
    };
  }

  return (
    <>
      {
        caseDetailsIsLoading === true && caseDetailsHasLoaded === false
          ? (
            <CHLoading applyEllipses />
          ) : (
            <section className="details-page">
              {walkthroughInProgress && !castDetailsHasError ? <TourSummaryPage /> : null}
              <section className="page">
                <section className="navigation">
                  <ArrowBackIosIcon fontSize="small" />
                  <Link to="/childCareSearch">Return to Dashboard</Link>
                </section>
                <br />
                <br />
                {castDetailsHasError ? <SysDownLogo /> : (
                  <>
                    <header className="flex">
                      <h1>Summary</h1>
                      <div className="justify-end">
                        <div>
                          <div className="primary blue-box bold">CalWORKs Program Discontinued/Denied on</div>
                          <div className="blue-box">{data.cwPgmClosureDate || '-'}</div>
                        </div>
                        <div>
                          <div className="primary blue-box bold">Case Number</div>
                          <div className="blue-box">{caseNumber}</div>
                        </div>
                        <div>
                          <div className="primary blue-box bold">CalWORKs Status</div>
                          <div className="blue-box">{data.cwStatus}</div>
                        </div>
                      </div>
                    </header>
                    <CHBox header="Parent/Caretaker Information">
                      <CHTabbedSection
                        people={data.parents}
                        parent
                      />
                    </CHBox>
                    {
                      data.children && data.children.length > 0
                        ? (
                          <div className="children-info">
                            <CHBox header="Children Information">
                              <AntTabs
                                onChange={handleChange}
                                value={value}
                                indicatorColor="primary"
                                textColor="primary"
                                variant="scrollable"
                                scrollButtons="auto"
                              >
                                {data.children.map((i, index) => (
                                  <AntTab
                                    className="children-tab"
                                    // eslint-disable-next-line react/no-array-index-key
                                    key={`${i.firstName || '-'}-${index}`}
                                    label={`${i.firstName || '-'} ${i.lastName || '-'}`}
                                    {...a11yProps(index)}
                                  />
                                ))}
                              </AntTabs>
                              {data.children.map((i, index) => (
                                // eslint-disable-next-line react/no-array-index-key
                                <TabPanel
                                  key={`${i.firstName || '-'}-${value}`}
                                  value={`child-${value}`}
                                  index={`child-${index}`}
                                >
                                  <ChildInfo data={{ ...i, childMapIndex: index }} />
                                </TabPanel>
                              ))}
                            </CHBox>
                          </div>
                        )
                        : null
                    }
                    <div className="add-section">
                      <CHBox header="Additional Information">
                        <div className="additional-section">
                          <Grid container spacing={5}>
                            <Grid item xs={6}>
                              {
                                data.incomeSources
                                  ? (
                                    <>
                                      <h2> Income Information </h2>
                                      <Card>
                                        <Table aria-label="simple table">
                                          <TableBody>
                                            <TableRow>
                                              <TableCell scope="row" className="card-label-column">
                                                Sources of Income
                                              </TableCell>
                                              <TableCell align="left">
                                                {data.incomeSources.map((i) => (
                                                  <div key={i}>
                                                    {i}
                                                  </div>
                                                ))}
                                              </TableCell>
                                            </TableRow>
                                            <TableRow>
                                              <TableCell scope="row" className="card-label-column">
                                                Adjusted Monthly Income
                                              </TableCell>
                                              <TableCell align="left">
                                                {data.adjustedMonthlyIncome || '-'}
                                              </TableCell>
                                            </TableRow>
                                          </TableBody>
                                        </Table>
                                      </Card>
                                    </>
                                  )
                                  : null
                              }
                              <Grid />
                            </Grid>
                            <Grid item xs={6}>
                              {
                                data.familyBreakdown
                                  ? (
                                    <>
                                      <h2> Family Breakdown </h2>
                                      <Card>
                                        <Table aria-label="simple table">
                                          <TableBody>
                                            <TableRow>
                                              <TableCell scope="row" className="card-label-column">
                                                Number of Adults
                                              </TableCell>
                                              <TableCell align="left">
                                                {data.familyBreakdown.adultCount}
                                              </TableCell>
                                            </TableRow>
                                            <TableRow>
                                              <TableCell scope="row" className="card-label-column">
                                                Number of Children
                                              </TableCell>
                                              <TableCell align="left">
                                                {data.familyBreakdown.childCount}
                                              </TableCell>
                                            </TableRow>
                                            <TableRow>
                                              <TableCell scope="row" className="card-label-column">
                                                Family Size
                                              </TableCell>
                                              <TableCell align="left">
                                                {data.familyBreakdown.totalCount}
                                              </TableCell>
                                            </TableRow>
                                          </TableBody>
                                        </Table>
                                      </Card>
                                    </>
                                  )
                                  : null
                              }
                            </Grid>
                          </Grid>
                          {
                            data.memberInfo
                              ? (
                                <>
                                  <h2> Other CalWORKs AU and non-AU family members in the household </h2>
                                  <Grid container spacing={4}>
                                    {data.memberInfo.map((i) => (
                                      <Grid key={i.firstName || '-'} item xs={4}>
                                        <CHNameCard
                                          first={i.firstName}
                                          middle={i.middleName}
                                          last={i.lastName}
                                        />
                                      </Grid>
                                    ))}
                                  </Grid>
                                </>
                              )
                              : null
                          }
                        </div>
                      </CHBox>
                    </div>
                  </>
                )}
              </section>
            </section>
          )
      }
    </>
  );
};

export default UserDetails;
