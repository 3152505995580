import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CHTextField from '../shared-components/CHTextField';
import CHButton from '../shared-components/CHButton';
import {
  handleValues,
  handlePossibleformErrors,
  searchCriteriaSelector,
  formErrorChecker,
  attemptedFormSubmission,
  isFormBeingSubmitted,
  resetSCFormChecker,
} from '../store/ReferralSearchCriteria.slice';
import {
  initSearch,
  loadSearch,
  searchError,
} from '../store/ReferralSearch.slice';
import { getAccessToken, getCSRFToken } from '../store/AppState.slice';
import apiRequest, { SEARCH_REFERRAL_ENDPOINT } from '../api/apiRequest';
import '../App.css';

const ReferralSearchCriteria = () => {
  const dispatch = useDispatch();

  const criteria = useSelector(searchCriteriaSelector);
  const errorCheck = useSelector(formErrorChecker);
  const accessToken = useSelector(getAccessToken);
  const isFormSubmissionHappening = useSelector(isFormBeingSubmitted);
  const csrfToken = useSelector(getCSRFToken);

  const search = () => {
    dispatch(initSearch());
    apiRequest(
      SEARCH_REFERRAL_ENDPOINT.method, SEARCH_REFERRAL_ENDPOINT.url,
      criteria, null, { accessToken, CSRF_STATE_TOKEN: csrfToken },
    ).then((data) => {
      dispatch(loadSearch(data));
    }).catch((err) => {
      dispatch(searchError(err));
    });
  };

  const errorHandler = useCallback((value) => {
    dispatch(handlePossibleformErrors(value));
  }, [dispatch]);

  const aggregateValues = useCallback((value) => {
    dispatch(handleValues(value));
  }, [dispatch]);

  const checkCriteria = () => {
    dispatch(attemptedFormSubmission());
    if (errorCheck === false) {
      search(criteria);
      dispatch(resetSCFormChecker());
    }
  };

  return (

    <section className="center-search-items">

      <p className="primary bold"> Please enter Referral ID and County Code below: </p>
      <CHTextField
        className="referralId"
        id="referralId"
        label="Referral ID"
        type="search"
        variant="outlined"
        helperText=""
        color="primary"
        isRequired
        validation={useMemo(() => [
          { type: 'required', message: 'Referral ID is required', args: [] },
          { type: 'maxLength', message: 'Invalid Referral ID format', args: [{ maxLength: 28 }] },
          { type: 'isNumeric', message: 'Invalid Referral ID format', args: [] },
        ], [])}
        maxLength={28}
        formSubmitting={isFormSubmissionHappening}
        handler={aggregateValues}
        errorHandler={errorHandler}
      />
      <CHTextField
        id="countyCode"
        label="County Code (Ex: 33)"
        type="search"
        variant="outlined"
        color="primary"
        isRequired
        handler={aggregateValues}
        formSubmitting={isFormSubmissionHappening}
        validation={useMemo(() => [
          { type: 'required', message: 'County Code is required', args: [] },
          { type: 'minLength', message: 'Invalid County Code format', args: [{ minLength: 2 }] },
          { type: 'maxLength', message: 'Invalid County Code format', args: [{ maxLength: 2 }] },
          { type: 'isCountyCode', message: 'Invalid County Code format', args: [] },
        ], [])}
        maxLength={2}
        errorHandler={errorHandler}
      />
      <br />
      <div className="flex-column squish">
        <CHButton
          id="search-btn"
          color="primary"
          variant="outlined"
          buttonText="Search"
          classNames="push-down search-criteria"
          handleClick={checkCriteria}
        />
      </div>
    </section>

  );
};

export default ReferralSearchCriteria;
