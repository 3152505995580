import { createSlice } from '@reduxjs/toolkit';
import moment from 'moment';

const dataMap = [
  'REFERRAL_ID',
  'SERIAL_NUM_IDENTIF',
  'FIRST_NAME',
  'MID_NAME',
  'LAST_NAME',
  'NEED_TYPE_DECODE',
  'REFERRAL_DATE',
  'shouldShowButton',
  'KEY_ID',
];

/* eslint-disable no-param-reassign */
export const referralSearchSlice = createSlice({
  name: 'referralSearch',
  initialState: {
    loaded: false,
    loading: false,
    searchError: false,
    data: [],
    fullSearchResults: {},
    headers:
      [
        'Referral ID',
        'Case Number',
        'First Name',
        'M. I.',
        'Last Name',
        'Need Type',
        'Referral Date',
        '',
      ],
  },
  reducers: {
    initSearch: (state) => {
      state.loaded = false;
      state.loading = true;
      state.searchError = false;
      state.searchResultsIsEmpty = false;
    },
    loadSearch: (state, action) => {
      state.loaded = true;
      state.loading = false;
      state.searchError = false;
      state.searchResultsIsEmpty = action.payload.length === 0;
      state.data = action.payload.map((obj) => dataMap.reduce((transform, el) => {
        let value;
        switch (el) {
          case 'shouldShowButton':
            value = '';
            break;
          case 'DOB':
            value = moment(obj[el], 'MM/DD/YYYY').format('MM/DD/YYYY');
            break;
          case 'MID_NAME':
            value = obj[el] === null ? '-' : obj[el];
            break;
          default:
            value = obj[el];
            break;
        }
        transform[el] = value;
        return transform;
      }, {}));
      state.fullSearchResults = { ...action.payload[0] };
    },
    loadUpdates: (state, action) => {
      // sets the new Status and Reason value after Edit/Save
      state.fullSearchResults = { ...action.payload };
    },
    searchError: (state) => {
      state.searchError = true;
    },
  },
});

export const {
  initSearch, loadSearch, loadUpdates, searchError,
} = referralSearchSlice.actions;

export const loadedSelector = (state) => state.referralSearch.loaded;
export const searchIsEmptySelector = (state) => state.referralSearch.searchResultsIsEmpty;
export const searchErrorSelector = (state) => state.referralSearch.searchError;
export const loadingSelector = (state) => state.referralSearch.loading;
export const dataSelector = (state) => state.referralSearch.data;
export const fullSearchResultsSelector = (state) => state.referralSearch.fullSearchResults;
export const headerSelector = (state) => state.referralSearch.headers;

export default referralSearchSlice.reducer;
