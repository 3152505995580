import React from 'react';
import PropTypes from 'prop-types';
import JoyRide from 'react-joyride';
import { useSelector } from 'react-redux';
import { caseDetailsHasError } from '../store/CaseDetails.slice';
import {
  HeaderPanel,
  ReportIssueContent,
  GetHelpContent,
  FirstNameContent,
  LastNameContent,
  LogoutContent,
  SearchCriteriaContent,
  HistoricalContent,
  SearchCriteriaNoResultsContent,
  SearchCriteriaTooManyContent,
  ExampleSearchContent,
} from './TourStepsContent';

const steps = [
  {
    target: '.heading-logo',
    title: 'Welcome',
    content: 'This is the site walkthrough of the CalSAWS Child Care Portal!',
    placement: 'center',
  },
  {
    target: '.heading-logo',
    title: 'The Purpose',
    content: 'This portal allows stage two or three administrators to search for parent or'
      + ' caretaker case information, enroll a family in CalWORK\'s Child Care'
      + ' Services, or to transfer a family between stages.',
    placement: 'center',
  },
  {
    target: '.header',
    title: 'The Header Panel',
    content: <HeaderPanel />,
    styles: {
      spotlight: {
        backgroundColor: 'rgba(238, 238, 238, 0.1)',
      },
    },
  },
  {
    target: '.reportissue',
    content: <ReportIssueContent />,
  },
  {
    target: '.gethelp',
    content: <GetHelpContent />,
  },
  {
    target: '.logout',
    content: <LogoutContent />,
  },
  {
    target: '.search-container',
    title: 'Person Search',
    content: 'The search criteria section lets you enter the first name,'
      + ' middle inital, last name, case number, physical zip code, and date'
      + ' of birth on your search query. There are 2 required fields...',
    placement: 'right-start',
  },
  {
    target: '.firstname',
    content: <FirstNameContent />,
    placement: 'right',
    styles: {
      options: {
        width: 300,
      },
    },
  },
  {
    target: '.lastname',
    content: <LastNameContent />,
    placement: 'right',
    styles: {
      options: {
        width: 300,
      },
    },
  },
  {
    target: '.search-criteria',
    content: <SearchCriteriaContent />,
    placement: 'right',
  },
  {
    target: '.historical-reports',
    title: 'Historical Reports',
    content: <HistoricalContent />,
    placement: 'right',
  },
  {
    target: '.sept-report',
    content: 'Clicking any report button prompts you to download the file',
    placement: 'right',
  },
  {
    target: '.search-criteria',
    content: 'When a search returns results, they display on the right side'
      + ' of the page in a table on the Results panel. A maximum of 20 results can be returned.',
    placement: 'right',
  },
  {
    target: '.search-criteria',
    content: <SearchCriteriaNoResultsContent />,
    placement: 'center',
  },
  {
    target: '.search-criteria',
    content: <SearchCriteriaTooManyContent />,
    placement: 'center',

  },
  {
    target: '.search-criteria',
    title: 'Example Search',
    content: <ExampleSearchContent />,
    placement: 'right',
  },
];

const redirectSteps = [
  {
    target: '.heading-logo',
    content: 'Return to the dashboard to resume or restart the walkthrough!',
    placement: 'center',
  },
];

const Tour = ({ run, callback }) => {
  const castDetailsHasError = useSelector(caseDetailsHasError);
  return (
    <>
      <JoyRide
        // {...tourState}
        callback={callback}
        run={run}
        steps={!castDetailsHasError ? steps : redirectSteps}
        continuous
        disableScrolling
        showSkipButton
        styles={{
          tooltipContainer: {
            textAlign: 'center',
          },
          tooltipTitle: {
            color: '#2738A5',
          },
          options: {
            primaryColor: '#2738A5',
            width: 500,
            maxHeight: 450,
          },

        }}
        locale={{
          last: 'Continue',
          skip: 'Skip Tour',
        }}
      />
    </>
  );
};

export default Tour;

Tour.propTypes = {
  run: PropTypes.bool.isRequired,
  callback: PropTypes.func.isRequired,
};
