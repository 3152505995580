import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import CaretakerInfo from '../components/CaretakerInfo';
import TabPanel from '../components/TabPanel';

const AntTabs = withStyles({
  root: {
    borderBottom: '1px solid #e8e8e8',
  },
  indicator: {
    backgroundColor: '#3f51b5',
  },
})(Tabs);

const AntTab = withStyles((theme) => ({
  root: {
    textTransform: 'none',
    minWidth: 72,
    fontWeight: theme.typography.fontWeightBold,
    marginRight: theme.spacing(4),
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:hover': {
      color: '#2738A5',
      opacity: 1,
    },
    '&$selected': {
      color: '#3f51b5',
    },
    '&:focus': {
      color: '#2738A5',
    },
  },
  selected: {},
}))((props) => <Tab disableRipple {...props} />);

const CHTabbedSection = ({ people }) => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  function a11yProps(index) {
    return {
      id: `scrollable-auto-tab-${index}`,
      'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
  }
  return (

    <div>
      <AntTabs
        value={value}
        onChange={handleChange}
        indicatorColor="primary"
        textColor="primary"
        variant="scrollable"
        scrollButtons="auto"
      >
        {people.map((person, i) => (
          <AntTab
            className="parent-tabs"
            key={person.firstName || '-'}
            label={`${person.firstName || '-'} ${person.lastName || '-'}`}
            {...a11yProps(`parent-${i}`)}
          />
        ))}

      </AntTabs>
      {people.map((person, i) => (
        <TabPanel key={`panel-${value}-${person.firstName || '-'}`} value={`parent-${value}`} index={`parent-${i}`}>
          <CaretakerInfo data={person} />
        </TabPanel>
      ))}

    </div>
  );
};

CHTabbedSection.propTypes = {
  people: PropTypes.arrayOf(PropTypes.shape({})),
};

CHTabbedSection.defaultProps = {
  people: [{ firstname: 'Parent/Caretaker', lastName: 'Unavailable' }],
};

export default CHTabbedSection;
