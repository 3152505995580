import React from 'react';
import JoyRide, { ACTIONS, EVENTS } from 'react-joyride';
import { useDispatch } from 'react-redux';
import { endWalkthrough } from '../store/AppState.slice';
import {
  SummaryContent,
  DetailsContent,
  ChildrenContent,
  ChildcareProviderContent,
  AddSectionContent,
} from './TourStepsContent';

const steps = [
  {
    target: '.page',
    title: 'Summary Page',
    content: <SummaryContent />,
    placement: 'auto',
  },
  {
    target: '.details-container',
    content: <DetailsContent />,
    placement: 'auto',
  },
  {
    target: '.parent-tabs',
    content: 'This section displays a tab for each parent or caretaker.',
  },
  {
    target: '.children-info',
    content: <ChildrenContent />,
    placement: 'auto',
  },
  {
    target: '.children-tab',
    content: 'This section displays a tab for each child.',
  },
  {
    target: '.childcare-provider',
    content: <ChildcareProviderContent />,
  },
  {
    target: '.childcare-provider-tab',
    content: 'This section displays a tab for each Provider.',
  },
  {
    target: '.add-section',
    content: <AddSectionContent />,
  },
  {
    target: '.add-section',
    content: 'Thank you for completing the site walkthrough of the CalSAWS Child Care Portal!',
    placement: 'center',

  },

];

// define state of tour
/* const INITIAL_STATE = {
    run: false,
    stepIndex: 0,
    steps: steps,
    continuous: true,
    loading: false,
    key: new Date()
}

// reducer function to control actions
const reducer = (state = INITIAL_STATE, action) => {
    switch(action.type) {
        case "START":
            return {...state, run: true}
        case "RESET":
            return {...state, stepIndex: 0}
        case "STOP":
            return {...state, run: false}
        case "NEXT_OR_PREV":
            return {...state, ...action.payload}
        case "RESTART":
            return {...state, run: true, stepIndex: 0, loading: false, key: new Date()}
        default:
            return state;
    }
};
*/

const TourSummaryPage = () => {
  /* const[tourState, dispatch] = useReducer(reducer, INITIAL_STATE);

   // callback function to keep track of the state changes
   const callback = (data) => {
       const {action, index, type, status} = data;

       const finishedStatuses= [STATUS.SKIPPED, STATUS.FINISHED];
       const indexedEvents = [EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND];

       if (action === ACTIONS.CLOSE) {
           dispatch({type: "STOP"});
       }
       if (finishedStatuses.includes(status)) {
           dispatch({ type: "STOP "});
       } else if (indexedEvents.includes(type)) {
           dispatch({
               type: "NEXT_OR_PREV",
               payload: { stepIndex: index + (action === ACTIONS.PREV ? -1 : 1) }
           });
       }
   }

   const startTour = () => {
       dispatch({ type: "RESTART" })
   } */

  // const [run, setRun] = useState(true);
  const dispatch = useDispatch();

  const callback = (data) => {
    const { action, type } = data;
    if (action === ACTIONS.SKIP || type === EVENTS.TOUR_END) {
      dispatch(endWalkthrough());
    }
  };

  return (
    <>
      <JoyRide
        steps={steps}
        /* run={run} */
        callback={callback}
        continuous
        disableScrolling
        showSkipButton
        styles={{
          tooltipContainer: {
            textAlign: 'center',
          },
          tooltipTitle: {
            color: '#2738A5',
          },
          options: {
            primaryColor: '#2738A5',
            width: 500,
            // backgroundColor: "#F4FFE8"
          },

        }}
        locale={{
          last: 'End Tour',
          skip: 'Skip Tour',
        }}
      />
    </>
  );
};

export default TourSummaryPage;
