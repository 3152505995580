import { createSlice } from '@reduxjs/toolkit';

/* eslint-disable no-param-reassign */
export const profileSlice = createSlice({
  name: 'profile',
  initialState: {
    loaded: false,
    loading: false,
    data: {},
  },
  reducers: {
    initProfile: (state) => {
      state.loaded = false;
      state.loading = true;
    },
    loadProfile: (state, action) => {
      state.loaded = true;
      state.loading = false;
      state.data = action.payload;
    },
  },
});

export const {
  initProfile, loadProfile,
} = profileSlice.actions;

export const profileLoadedSelector = (state) => state.profile.loaded;
export const profileLoadingSelector = (state) => state.profile.loading;
export const profileSelector = (state) => state.profile.data;
export const headerSelector = (state) => state.profile.headers;

export default profileSlice.reducer;
