import React from 'react';
import { Button } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import PropTypes from 'prop-types';
import '../App.css';

const ReportIssue = ({ isOpen, handleClose }) => (
  <Dialog role="presentation" open={isOpen} onClose={handleClose} aria-labelledby="form-dialog-title">
    <DialogTitle id="form-dialog-title"> Ask CalSAWS </DialogTitle>
    <DialogContent>
      <DialogContentText>
        Please reach out to your County Welfare Department for assistance.
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={handleClose} color="primary">
        OK
      </Button>
    </DialogActions>
  </Dialog>
);

ReportIssue.propTypes = {
  profile: PropTypes.shape({ name: PropTypes.string }),
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

ReportIssue.defaultProps = {
  profile: { name: '' },
};

export default ReportIssue;
