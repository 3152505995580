import React, { useState } from 'react';
import JoyRide, { ACTIONS } from 'react-joyride';
import { useDispatch } from 'react-redux';
import { endWalkthrough } from '../store/AppState.slice';
import {
  InfoRowContent,
} from './TourStepsContent';

const steps = [
  {
    target: '.search-results',
    title: 'Search Results',
    content: 'The results panel displays the Case Number, Physical Zip Code, '
      + 'First Name, Middle Initial, Last Name, Parent/Caretaker DOB, and CalWORKs Status columns',
    placement: 'bottom-start',
  },
  {
    target: '.info-row',
    content: 'Your search results will be displayed by row here.',
  },
  {
    target: '.info-row',
    content: <InfoRowContent />,
    placement: 'bottom',
  },
];

const TourSearchResults = () => {
  const [run] = useState(true);
  const dispatch = useDispatch();

  const callback = (data) => {
    const { action } = data;
    if (action === ACTIONS.SKIP) {
      dispatch(endWalkthrough());
    }
  };

  return (
    <>
      <JoyRide
        run={run}
        callback={callback}
        steps={steps}
        disableScrolling
        continuous
        showSkipButton
        styles={{
          tooltipContainer: {
            textAlign: 'center',
          },
          tooltipTitle: {
            color: '#2738A5',
          },
          options: {
            primaryColor: '#2738A5',
            width: 500,
            // backgroundColor: "#F4FFE8"
          },

        }}
        locale={{
          last: 'Continue',
          skip: 'Skip Tour',
        }}
      />
    </>
  );
};

export default TourSearchResults;
