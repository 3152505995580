import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { styleWithRoot } from '../styles/CHButton.styles';

// wrapper for hard to reach css changes
const CssButton = withStyles(styleWithRoot)(Button);

const CHButton = ({
  color, variant, id, handleClick, buttonText, classNames,
}) => (
    // eslint-disable-next-line react/jsx-indent
    <CssButton
      id={id}
      className={classNames}
      variant={variant}
      title={buttonText}
      onClick={handleClick}
      color={color}
    >
      {buttonText}
    </CssButton>
    // eslint-disable-next-line indent
  );

CHButton.propTypes = {
  variant: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  handleClick: PropTypes.func.isRequired,
  buttonText: PropTypes.string.isRequired,
  classNames: PropTypes.string.isRequired,
};

export default CHButton;
