const style = {
  '& .profile-group': {
    // fontSize: '20px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '20px',
    '& span.profile-name': {
      marginLeft: '1em',
    },
    '& button': {
      marginLeft: '25px',
    },
  },
  '& .heading-logo': {
  },
  '& .large': {
    width: '55px',
    height: '55px',
  },
  '& .black': {
    backgroundColor: 'black',
  },
  padding: '1em 5em',
  '&.flex': {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    alignItems: 'center',
  },
  '&.MuiAppBar-colorPrimary': {
    backgroundColor: '#F4FFE8',
    color: 'black',
    height: '10vh',
  },
  '&.MuiPaper-elevation4': {
    boxShadow:
      '0px -5px 3px 5px #6b6a6a',
  },
};

export const styleWithRoot = {
  root: {
    ...style,
  },
};

export default style;
