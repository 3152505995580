import { createSlice } from '@reduxjs/toolkit';
import { counties } from './storeStaticData';
import {
  apiParamCapture, errorHandling, formChecker, resetFormChecker,
} from './shared-reducers';

/* eslint-disable no-param-reassign */
export const reportIssueSlice = createSlice({
  name: 'reportIssue',
  initialState: {
    values: {},
    counties,
    reportSuccess: false,
    reportFailed: false,
    formHasError: false,
    form: [],
    checkingForm: false,
  },
  reducers: {
    handleValues: apiParamCapture,
    handlePossibleformErrors: errorHandling,
    attemptedFormSubmission: formChecker,
    resetRIFormChecker: resetFormChecker,
    reportIssueWasSuccessful: (state) => {
      state.reportSuccess = true;
    },
    reportIssueHasFailed: (state) => {
      state.reportFailed = true;
    },
    goAwayReportIssueMessage: (state) => {
      state.reportSuccess = false;
      state.reportFailed = false;
    },
  },
});

export const {
  handleValues, handlePossibleformErrors, attemptedFormSubmission,
  resetRIFormChecker, reportIssueWasSuccessful, reportIssueHasFailed,
  goAwayReportIssueMessage,
} = reportIssueSlice.actions;

export const reportIssueSelector = (state) => state.reportIssue.values;
export const getCounties = (state) => state.reportIssue.counties;
export const wasReportIssueSuccessful = (state) => state.reportIssue.reportSuccess;
export const didReportIssueFail = (state) => state.reportIssue.reportFailed;
export const formErrorChecker = (state) => state.reportIssue.formHasError;
export const getFormErrorTable = (state) => state.reportIssue.form;
export const isFormBeingSubmitted = (state) => state.reportIssue.checkingForm;

export default reportIssueSlice.reducer;
