import ReferralSearch from '../containers/ReferralSearch';
import ReferralView from '../containers/ReferralView';
import Welcome from '../containers/Welcome';
import ChildCareSearch from '../containers/ChildCareSearch';
import UserDetails from '../containers/UserDetails';
import Unauthorized from '../containers/Unauthorized';

const routes = [
  {
    path: '/welcome',
    Artifact: Welcome,
    exact: true,
    privateRoute: true,
  },
  {
    path: '/referralSearch',
    Artifact: ReferralSearch,
    exact: true,
    privateRoute: true,
  },
  {
    path: '/referralView/:countyCode/:referralId',
    Artifact: ReferralView,
    privateRoute: true,
  },
  {
    path: '/childCareSearch',
    Artifact: ChildCareSearch,
    exact: false,
    privateRoute: true,
  },
  {
    path: '/childCareSummary/:caseNumber/:programId/:programPersonId',
    Artifact: UserDetails,
    privateRoute: true,
  },
  {
    path: '/unauthorized',
    Artifact: Unauthorized,
    privateRoute: false,
  },
  // when redirecting to external site, set artifact to null, and set a redirect property
  {
    path: '/redirect',
    Artifact: null,
    redirect: process.env.REACT_APP_ORIGIN,
  },
];

export default routes;
