import React from 'react';
import PropTypes from 'prop-types';
import { Card } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';

const RFChildNameCard = ({
  first, middle, last, dob,
}) => (
  <Card>
    <Table aria-label="simple table">
      <TableBody>
        <TableRow>
          <TableCell scope="row" className="card-label-column">
            First Name
          </TableCell>
          <TableCell align="left">
            {first || '-'}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell scope="row" className="card-label-column">
            Middle Initial
          </TableCell>
          <TableCell align="left">
            {middle || '-'}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell scope="row" className="card-label-column">
            Last Name
          </TableCell>
          <TableCell align="left">
            {last || '-'}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell scope="row" className="card-label-column">
            Date of Birth
          </TableCell>
          <TableCell align="left">
            {dob || '-'}
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  </Card>
);

RFChildNameCard.propTypes = {
  first: PropTypes.string,
  middle: PropTypes.string,
  last: PropTypes.string,
  dob: PropTypes.string,
};

RFChildNameCard.defaultProps = {
  first: '-',
  middle: '-',
  last: '-',
  dob: '-',
};

export default RFChildNameCard;
