import React, { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import withStyles from '@mui/styles/withStyles';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import NativeSelect from '@mui/material/NativeSelect';
import CHButton from '../shared-components/CHButton';
import CHBox from '../shared-components/CHBox';
import {
  getAccessToken, getCSRFToken, hasReferralEditRole,
} from '../store/AppState.slice';
import { fullSearchResultsSelector, loadUpdates } from '../store/ReferralSearch.slice';
import apiRequest, { UPDATE_REFERRAL_ENDPOINT } from '../api/apiRequest';
import RFChildInfo from '../components/RFChildInfo';
import TabPanel from '../components/TabPanel';
import RFTabbedSection from '../shared-components/RFTabbedSection';
import CHLoading from '../shared-components/CHLoading';
import SysDownLogo from '../components/SysDownLogo';

const AntTabs = withStyles({
  root: {
    borderBottom: '1px solid #e8e8e8',
  },
  indicator: {
    backgroundColor: '#3f51b5',
  },
})(Tabs);

const AntTab = withStyles((theme) => ({
  root: {
    textTransform: 'none',
    minWidth: 72,
    fontWeight: theme.typography.fontWeightBold,
    marginRight: theme.spacing(4),
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:hover': {
      color: '#2738A5',
      opacity: 1,
    },
    '&$selected': {
      color: '#3f51b5',
    },
    '&:focus': {
      color: '#2738A5',
    },
  },
  selected: {},
}))((props) => <Tab disableRipple {...props} />);

const ReferralView = () => {
  const [referralIsLoading, setReferralIsLoading] = useState(false);
  const [referralHasLoaded, setReferralHasLoaded] = useState(false);
  const [referralHasError, setReferralHasError] = useState(false);

  const dispatch = useDispatch();
  const { referralId, countyCode } = useParams();
  const [childTabIdValue, setChildTabIdValue] = useState(0);
  const [isEditMode, setEditMode] = useState(false);

  const data = useSelector(fullSearchResultsSelector);
  const accessToken = useSelector(getAccessToken);
  const csrfToken = useSelector(getCSRFToken);
  const userHasReferralEditRole = useSelector(hasReferralEditRole);

  // validate the params match the state data
  if (!referralIsLoading && !referralHasLoaded && !referralHasError
    && referralId !== data.REFERRAL_ID && countyCode !== data.COUNTY_CODE) {
    setReferralHasError(true);
    setReferralIsLoading(false);
    setReferralHasLoaded(true);
  }

  const handleChildTabChange = (event, newValue) => {
    setChildTabIdValue(newValue);
  };

  function a11yProps(index) {
    return {
      id: `scrollable-auto-tab-${index}`,
      'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
  }

  const statusMap = {
    RD: 'Referred',
    AC: 'Accepted',
    NA: 'Not Accepted',
  };

  const statusReasonMap = {
    '01': 'Eligible for Service',
    '05': 'Funding Available',
    '04': 'Resource Available',
    '02': 'No Resource Available',
    '06': 'Funding Not Available',
  };

  const statusToReasonMap = {
    RD: [{ value: '01', label: 'Eligible for Service' }],
    AC: [{ value: '05', label: 'Funding Available' }, { value: '04', label: 'Resource Available' }],
    NA: [{ value: '06', label: 'Funding Not Available' }, { value: '02', label: 'No Resource Available' }],
  };

  const handleEditButtonClick = () => {
    setEditMode(true);
  };

  const [referralStatusCode, setReferralStatusCode] = useState(data.STAT_CODE);
  const [referralStatusDecode, setReferralStatusDecode] = useState(data.STAT_DECODE);
  const [referralStatusReasonCode, setReferralStatusReasonCode] = useState(data.STAT_RSN_CODE);
  const [referralStatusReasonDecode, setReferralStatusReasonDecode] = useState(data.STAT_RSN_DECODE);
  const [referralStatusReasonOptions, setReferralStatusReasonOptions] = useState(statusToReasonMap[referralStatusCode]);

  const handleReferralStatusChange = (event) => {
    setReferralStatusCode(event.target.value);
    setReferralStatusDecode(statusMap[event.target.value]);
    setReferralStatusReasonOptions(statusToReasonMap[event.target.value]);
    // set the state for the Status Reason Code to be the first element for the new list of options
    setReferralStatusReasonCode(statusToReasonMap[event.target.value][0].value);
    setReferralStatusReasonDecode(statusToReasonMap[event.target.value][0].label);
  };

  const handleReferralStatusReasonChange = (event) => {
    setReferralStatusReasonCode(event.target.value);
    setReferralStatusReasonDecode(statusReasonMap[event.target.value]);
  };

  const handleSaveButtonClick = () => {
    setEditMode(false);
    setReferralIsLoading(true);
    setReferralHasLoaded(false);
    setReferralHasError(false);

    const putData = {
      referralId,
      countyCode,
      caseNumber: data.SERIAL_NUM_IDENTIF,
      referralStatusCode,
      referralStatusReasonCode,
      referralStatusDecode,
      referralStatusReasonDecode,
    };

    apiRequest(
      UPDATE_REFERRAL_ENDPOINT.method,
      UPDATE_REFERRAL_ENDPOINT.url,
      putData, null, { accessToken, CSRF_STATE_TOKEN: csrfToken },
    ).then((updatedData) => {
      if (updatedData) {
        dispatch(loadUpdates(
          {
            ...data,
            STAT_CODE: referralStatusCode,
            STAT_DECODE: referralStatusDecode,
            STAT_RSN_CODE: referralStatusReasonCode,
            STAT_RSN_DECODE: referralStatusReasonDecode,
          },
        ));
        setReferralIsLoading(false);
        setReferralHasLoaded(true);
      }
      else {
        setReferralIsLoading(false);
        setReferralHasLoaded(true);
        setReferralHasError(true);
      }
    }).catch(() => {
      // clear the loading screen and trigger error screen
      setReferralIsLoading(false);
      setReferralHasLoaded(true);
      setReferralHasError(true);
    });
  };

  const handleCancelButtonClick = () => {
    // reset state for status/reason fields from data object
    setReferralStatusCode(data.STAT_CODE);
    setReferralStatusDecode(data.STAT_DECODE);
    setReferralStatusReasonOptions(statusToReasonMap[data.STAT_CODE]);
    setReferralStatusReasonCode(data.STAT_RSN_CODE);
    setReferralStatusReasonDecode(data.STAT_RSN_DECODE);
    setEditMode(false);
  };

  return (
    <>
      {
        referralIsLoading === true && referralHasLoaded === false
          ? (
            <CHLoading applyEllipses />
          ) : (
            <section className="details-page">
              <section className="page">
                <section className="navigation">
                  <ArrowBackIosIcon fontSize="small" />
                  <Link to="/referralSearch">Return to Dashboard</Link>
                </section>
                <br />
                {referralHasError ? (
                  <>
                    <section className="start-search">
                      <h3>Please return to the Dashboard and try again.</h3>
                    </section>
                    <SysDownLogo />
                  </>
                ) : (
                  <>
                    <header className="flex">
                      <h1>Summary</h1>
                      <div className="justify-end">
                        <div>
                          <div className="primary blue-box bold">Referral ID</div>
                          <div className="blue-box">{data.REFERRAL_ID}</div>
                        </div>
                        <div>
                          <div className="primary blue-box bold">Case Number</div>
                          <div className="blue-box">{data.SERIAL_NUM_IDENTIF}</div>
                        </div>
                        <div>
                          <div className="primary blue-box bold">County</div>
                          <div className="blue-box">{data.COUNTY_NAME}</div>
                        </div>
                        <div>
                          <div className="primary blue-box bold">Referral Status</div>
                          <div className="blue-box">
                            {
                              isEditMode === true
                                ? (
                                  <>
                                    <NativeSelect
                                      value={referralStatusCode}
                                      inputProps={{
                                        name: 'referralStatus',
                                        id: 'referral-status',
                                      }}
                                      onChange={handleReferralStatusChange}
                                    >
                                      <option value="RD">Referred</option>
                                      <option value="AC">Accepted</option>
                                      <option value="NA">Not Accepted</option>
                                    </NativeSelect>
                                  </>
                                ) : (<>{referralStatusDecode}</>)
                            }
                          </div>
                        </div>
                        <div>
                          <div className="primary blue-box bold">Status Reason</div>
                          <div className="blue-box">
                            {
                              isEditMode === true
                                ? (
                                  <NativeSelect
                                    value={referralStatusReasonCode}
                                    inputProps={{
                                      name: 'referralStatusReason',
                                      id: 'referral-status-reason',
                                    }}
                                    onChange={handleReferralStatusReasonChange}
                                  >
                                    {referralStatusReasonOptions.map((option) => (
                                      <option key={option.value} value={option.value}>
                                        {option.label}
                                      </option>
                                    ))}
                                  </NativeSelect>
                                ) : (<>{referralStatusReasonDecode}</>)
                            }
                          </div>
                        </div>
                        <div>
                          {
                            userHasReferralEditRole && isEditMode === false
                              ? (
                                <div className="primary button-box">
                                  <CHButton
                                    id="edit-referral-button"
                                    color="primary"
                                    variant="outlined"
                                    buttonText="Edit"
                                    classNames="push-down"
                                    handleClick={handleEditButtonClick}
                                  />
                                </div>
                              ) : null
                          }
                        </div>
                        <div>
                          {
                            userHasReferralEditRole && isEditMode === true
                              ? (
                                <div className="primary button-box">
                                  <CHButton
                                    id="edit-referral-save-button"
                                    color="primary"
                                    variant="outlined"
                                    buttonText="Save"
                                    classNames="push-down"
                                    handleClick={handleSaveButtonClick}
                                  />
                                </div>
                              ) : null
                          }
                        </div>
                        <div>
                          {
                            userHasReferralEditRole && isEditMode === true
                              ? (
                                <div className="primary button-box">
                                  <CHButton
                                    id="edit-referral-cancel-button"
                                    color="primary"
                                    variant="outlined"
                                    buttonText="Cancel"
                                    classNames="push-down"
                                    handleClick={handleCancelButtonClick}
                                  />
                                </div>
                              ) : null
                          }
                        </div>
                      </div>
                    </header>
                    <CHBox header="Referral Information">
                      <RFTabbedSection
                        referral={data}
                      />
                    </CHBox>
                    {
                      data.CHILDREN && data.CHILDREN.length > 0
                        ? (
                          <div className="children-info">
                            <CHBox header="Children Information">
                              <AntTabs
                                onChange={handleChildTabChange}
                                value={childTabIdValue}
                                indicatorColor="primary"
                                textColor="primary"
                                variant="scrollable"
                                scrollButtons="auto"
                              >
                                {data.CHILDREN.map((i, index) => (
                                  <AntTab
                                    className="children-tab"
                                    // eslint-disable-next-line react/no-array-index-key
                                    key={`${i.CHILD_FIRST_NAME || '-'}-${index}`}
                                    label={`${i.CHILD_FIRST_NAME || '-'} ${i.CHILD_MID_NAME || ''}
                                    ${i.CHILD_LAST_NAME || '-'} ${i.CHILD_SUFFIX || ''}`}
                                    {...a11yProps(i)}
                                  />
                                ))}
                              </AntTabs>
                              {data.CHILDREN.map((i, index) => (
                                <TabPanel
                                  // eslint-disable-next-line react/no-array-index-key
                                  key={`${i.CHILD_PERS_ID || '-'}-${childTabIdValue}-${index}`}
                                  value={childTabIdValue}
                                  index={index}
                                >
                                  <RFChildInfo data={i} />
                                </TabPanel>
                              ))}
                            </CHBox>
                          </div>
                        )
                        : null
                    }
                  </>
                )}
              </section>
            </section>
          )
      }
    </>
  );
};

export default ReferralView;
