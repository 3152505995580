import React, { useState, useEffect } from 'react';
import { Button } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import PropTypes from 'prop-types';
import '../App.css';
import moment from 'moment';

const LogoutWarning = ({ isOpen, handleClose, logout }) => {
  /* 300,000 milliseconds = 5 minute warning */
  const [time, setTime] = useState(300000);

  let intervalId;

  if (!intervalId) {
    intervalId = setInterval(() => {
      let newTime = time - 1000;
      if (newTime <= 0) {
        newTime = 0;
      }
      setTime(newTime);
    }, 1000);
  }

  useEffect(() => {
    if (time === 0) {
      logout();
    }
    return () => {
      clearInterval(intervalId);
    };
  });

  function fetchTimeLanguage() {
    let lang = 'minutes';
    if (time === 60000) {
      lang = 'minute';
    }
    if (time < 60000) {
      lang = 'seconds';
    }
    if (time === 1000) {
      lang = 'second';
    }
    return lang;
  }

  return (
    <Dialog open={isOpen} onClose={handleClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title"> Inactivity Warning </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {
            `You are about to be logged out of the system due to
            inactivity in ${moment.utc(time).format('mm:ss')} ${fetchTimeLanguage()}.`
          }
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={logout} color="primary">
          Logout
        </Button>
        <Button
          onClick={() => {
            clearInterval(intervalId);
            handleClose();
          }}
          color="primary"
        >
          I&apos;m still here
        </Button>
      </DialogActions>
    </Dialog>
  );
};

LogoutWarning.propTypes = {
  logout: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func,
};

LogoutWarning.defaultProps = {
  handleClose: () => { },
};

export default LogoutWarning;
