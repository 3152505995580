import React from 'react';
import '../App.css';
import logo from '../assets/system_unavailable.svg';

const SysDownLogo = () => {
  const sysdownline1 = 'We\'re sorry, the system is temporarily unavailable.';
  const sysdownline2 = 'We hope to be back up and running soon!';

  return (
    <>
      <section className="start-search">
        <h1> System Unavailable</h1>
        <img title="System Unavailable" alt="System Unavailable" src={logo} />
        <p>
          {sysdownline1}
          <br />
          {sysdownline2}
        </p>
      </section>
    </>
  );
};

export default SysDownLogo;
