// list of all counties
export const counties = [
  { title: 'Alpine' },
  { title: 'Amador' },
  { title: 'Butte' },
  { title: 'Calaveras' },
  { title: 'Colusa' },
  { title: 'Contra Costa' },
  { title: 'Marin' },
  { title: 'Mariposa' },
  { title: 'Mendocino' },
  { title: 'Merced' },
  { title: 'Modoc' },
  { title: 'Mono' },
  { title: 'Monterey' },
  { title: 'Napa' },
  { title: 'Nevada' },
  { title: 'Orange' },
  { title: 'Madera' },
  { title: 'Plumas' },
  { title: 'Riverside' },
  { title: 'Sacramento' },
  { title: 'San Benito' },
  { title: 'San Bernardino' },
  { title: 'San Diego' },
  { title: 'San Francisco' },
  { title: 'San Joaquin' },
  { title: 'San Mateo' },
  { title: 'Santa Barbara' },
  { title: 'Santa Clara' },
  { title: 'Santa Cruz' },
  { title: 'Shasta' },
  { title: 'Sierra' },
  { title: 'Siskiyou' },
  { title: 'Solano' },
  { title: 'Del Norte' },
  { title: 'El Dorado' },
  { title: 'Fresno' },
  { title: 'Glenn' },
  { title: 'Humboldt' },
  { title: 'Imperial' },
  { title: 'Inyo' },
  { title: 'Kern' },
  { title: 'Kings' },
  { title: 'Lake' },
  { title: 'Lassen' },
  { title: 'Los Angeles' },
  { title: 'Placer' },
  { title: 'Sonoma' },
  { title: 'Stanislaus' },
  { title: 'Out of State' },
  { title: 'Sutter' },
  { title: 'Tehama' },
  { title: 'Trinity' },
  { title: 'Tulare' },
  { title: 'Tuolumne' },
  { title: 'Ventura' },
  { title: 'Yolo' },
  { title: 'Yuba' },
  { title: 'San Luis Obispo' },
];

export const childCareRoles = process.env.REACT_APP_CHILDCARE_ROLE_ID.split(';');
export const referralViewRoles = process.env.REACT_APP_REFERRAL_VIEW_ROLE_ID.split(';');
export const referralEditRoles = process.env.REACT_APP_REFERRAL_EDIT_ROLE_ID.split(';');
