import React from 'react';
import PropTypes from 'prop-types';
import { Card } from '@mui/material';
import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import CHTelephoneCard from '../shared-components/CHTelephoneCard';
import CHAddressCard from '../shared-components/CHAddressCard';
import CHTableCard from '../shared-components/CHTableCard';

const CaretakerInfo = ({ data }) => {
  const {
    firstName,
    middleInitial,
    lastName,
    // lastActiveMonthCW,
    phoneNumbers = [],
    dob,
    email,
    physicalAddress,
    mailingAddress,
    employerInformation,
    cwPersStat,
    cwPersStatRsn,
    cwLastAidedMonth,
    cwPersRole,
    cwPersRoleRsn,
  } = data;
  const reasonsNeedingChildCare = data.reasonsNeedingChildCare || '';
  return (
    <section className="details-container">
      <div>
        <Grid container spacing={5}>
          <Grid item xs={6}>
            <div className="h2height"> &nbsp; </div>
            <Card>
              <Table aria-label="simple table">
                <TableBody>
                  <TableRow>
                    <TableCell scope="row" className="card-label-column">
                      First Name
                    </TableCell>
                    <TableCell>
                      {firstName}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell scope="row" className="card-label-column">
                      Middle Initial
                    </TableCell>
                    <TableCell>
                      {middleInitial || '-'}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell scope="row" className="card-label-column">
                      Last Name
                    </TableCell>
                    <TableCell>
                      {lastName}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell scope="row" className="card-label-column">
                      Date of Birth
                    </TableCell>
                    <TableCell align="left">
                      {dob || '-'}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell scope="row" className="card-label-column">
                      Reasons for Needing Child Care
                    </TableCell>
                    <TableCell scope="row" align="left">
                      {
                        reasonsNeedingChildCare
                          ? (
                            reasonsNeedingChildCare.split(',').map((i) => (
                              <div key={i}>
                                {i}
                              </div>
                            ))
                          )
                          : (
                            <>
                              -
                            </>
                          )
                      }
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Card>
          </Grid>
          <Grid />
          <Grid item xs={6}>
            <Grid item xs={6}>
              <h2> CalWORKs Individual Information </h2>
            </Grid>
            <Card>
              <Table aria-label="simple table">
                <TableBody>
                  <TableRow>
                    <TableCell scope="row" className="card-label-column">
                      CalWORKs Person Status
                    </TableCell>
                    <TableCell align="left">
                      {cwPersStat || '-'}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell scope="row" className="card-label-column">
                      Reason for Denial/Discontinuance
                    </TableCell>
                    <TableCell align="left">
                      {cwPersStatRsn || '-'}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell scope="row" className="card-label-column">
                      Last Aided Month of CalWORKs
                    </TableCell>
                    <TableCell align="left">
                      {cwLastAidedMonth || '-'}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell scope="row" className="card-label-column">
                      CalWORKs Person Role
                    </TableCell>
                    <TableCell align="left">
                      {cwPersRole || '-'}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell scope="row" className="card-label-column">
                      CalWORKs Person Role Reason
                    </TableCell>
                    <TableCell align="left">
                      {cwPersRoleRsn || '-'}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Card>
          </Grid>
          <Grid />
          <Grid item xs={6}>
            <h2> Telephone Numbers </h2>
            <CHTelephoneCard phoneNumbers={phoneNumbers} />
          </Grid>
          <Grid item xs={6}>
            <h2> E-mail </h2>
            <Card>
              <Table aria-label="simple table">
                <TableBody>
                  <TableRow>
                    <TableCell align="left">
                      {email || '-'}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Card>
            <h2> Address </h2>
            <CHAddressCard
              physical={physicalAddress}
              mailing={mailingAddress}
            />
          </Grid>
          <Grid item xs={12}>
            <h2> Employment Training Information </h2>
            <CHTableCard
              dataList={employerInformation}
              headers={['Name', 'Address', 'Days', 'Start Time', 'Stop Time']}
            />
          </Grid>
        </Grid>
      </div>
    </section>

  );
};

CaretakerInfo.propTypes = {
  data: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    middleInitial: PropTypes.string,
    physicalAddress: PropTypes.string,
    mailingAddress: PropTypes.string,
    phoneNumbers: PropTypes.arrayOf(PropTypes.shape({
      type: PropTypes.string,
      number: PropTypes.string,
    })),
    reasonsNeedingChildCare: PropTypes.string,
    // lastActiveMonthCW: PropTypes.string,
    dob: PropTypes.string,
    email: PropTypes.string,
    employerInformation: PropTypes.arrayOf(PropTypes.shape()),
    cwPersStat: PropTypes.string,
    cwPersStatRsn: PropTypes.string,
    cwLastAidedMonth: PropTypes.string,
    cwPersRole: PropTypes.string,
    cwPersRoleRsn: PropTypes.string,
  }),
};

CaretakerInfo.defaultProps = {
  data: {},
};

export default CaretakerInfo;
