var data = {

  "ID": 806325159,
  "CW Status": "Discontinued",
  "Discontinued Date": "2/01/2008",
  "Case Number": "0085359",
  "Parent Caretaker": [
    {
      "First Name": "Erica",
      "Middle Initial": "F",
      "Last Name": "Cook",
      "NAME_SUFFIX": null,
      "DATE_OF_BIRTH": "12/01/1993",
      "Mailing Address Line 1": "806101239 Main St",
      "Mailing Address Line 2": null,
      "Mailing Address City Name": "MERCED",
      "Mailing Address State Code": "CA",
      "Mailing Address Zip Code Num": "95341",
      "Mailing Address Zip Suffix": "5447",
      "Physical Address Line 1": "806101239 Main St",
      "Physical Address Line 2": null,
      "Physical Address City Name": "MERCED",
      "Physical Address State Code": "CA",
      "Physical Address Zip Code Num": "95341",
      "Physical Address Zip Suffix": "5447",
      "Last Active Month of CW": "February 2008",
      "Reasons for needing child care": [
        "seeking permanent housing",
        'diversion'
      ],
      "employment_training_info":
        [
          {
            "name": "some university name",
            "address": "some university address",
            "days": "Tues Thu",
            "start time": "7:00 AM",
            "stop time": "9:00 AM"
          },
          {
            "name": "some university name",
            "address": "some university address",
            "days": "Sun Mon Tue Wed",
            "start time": "10:00 PM",
            "stop time": "11:00 PM"
          }
        ],
      "phoneNumbers":
        [
          {
            "type": "Home",
            "number": "(939)431-9430"
          },
          {
            "type": "Message",
            "number": "(174)798-5687"
          },
          {
            "type": "Work",
            "number": "(944)949-2731"
          },
          {
            "type": "Toll Free",
            "number": "(944)949-2731"
          },
          {
            "type": "TDD",
            "number": "(944)949-2731"
          }
        ]

    },
    {
      "First Name": "Donna",
      "Middle Initial": "F",
      "Last Name": "Cook",
      "NAME_SUFFIX": null,
      "DATE_OF_BIRTH": "12/01/1993",
      "Mailing Address Line 1": "806101239 Main St",
      "Mailing Address Line 2": null,
      "Mailing Address City Name": "MERCED",
      "Mailing Address State Code": "CA",
      "Mailing Address Zip Code Num": "95341",
      "Mailing Address Zip Suffix": "5447",
      "Physical Address Line 1": "806101239 Main St",
      "Physical Address Line 2": null,
      "Physical Address City Name": "MERCED",
      "Physical Address State Code": "CA",
      "Physical Address Zip Code Num": "95341",
      "Physical Address Zip Suffix": "5447",
      "Last Active Month of CW": "February 2008",
      "Reasons for needing child care": [
        'education or training'
      ],
      "employment_training_info":
        [
          {
            "name": "some university name",
            "address": "some university address",
            "days": "Tues Thu",
            "start time": "7:00 AM",
            "stop time": "9:00 AM"
          },
          {
            "name": "some university name",
            "address": "some university address",
            "days": "Sun Mon Tue Wed",
            "start time": "10:00 PM",
            "stop time": "11:00 PM"
          }
        ],
      "phoneNumbers":
        [
          {
            "type": "Home",
            "number": "(939)431-9430"
          },
          {
            "type": "Message",
            "number": "(174)798-5687"
          },
          {
            "type": "Work",
            "number": "(944)949-2731"
          },
          {
            "type": "Toll Free",
            "number": "(944)949-2731"
          },
          {
            "type": "TDD",
            "number": "(944)949-2731"
          }
        ]
    }
  ],

  "children":
    [
      {
        "First Name": "Alice",
        "Middle Initial": "N",
        "Last Name": "Cook",
        "DATE_OF_BIRTH": "12/01/2013",
        "CC_HOURS": "4 HOURS",
        "status": "pending",
        "Provider_Info": {
          "Type": "Licesed Center-Based Care",
          "Provider Rates List":
            [
              {
                "Schedule Type": "Regular",
                "Care Type": "Full Time",
                "Time": "Daytime",
                "Age Group": "Under 2",
                "Rate of Payment": "0.00/mo"
              },
              {
                "Schedule Type": "Vacation",
                "Care Type": "Part Time",
                "Time": "Daytime",
                "Age Group": "Under 2",
                "Rate of Payment": "485.89/wk"
              },
            ],
          "Name": "Elaine",
          "Mailing Address Line 1": "806101239 Main St",
          "Mailing Address Line 2": null,
          "Mailing Address City Name": "MERCED",
          "Mailing Address State Code": "CA",
          "Mailing Address Zip Code Num": "95341",
          "Mailing Address Zip Suffix": "5447",
          "Physical Address Line 1": "806101239 Main St",
          "Physical Address Line 2": null,
          "Physical Address City Name": "MERCED",
          "Physical Address State Code": "CA",
          "Physical Address Zip Code Num": "95341",
          "Physical Address Zip Suffix": "5447",
          "phoneNumbers": [
            {
              "type": "Work",
              "number": "(944)949-2731"
            }
          ]


        }

      },

      {
        "First Name": "Tim",
        "Middle Initial": "L",
        "Last Name": "Cook",
        "DATE_OF_BIRTH": "2/01/2010",
        "CC_HOURS": "4 HOURS",
        "status": "pending",
        "Provider_Info": {
          "Type": "Licesed-Exempt Relative Care",
          "Rate of Payment":
            [
              {
                "Schedule Type": "Regular",
                "Care Type": "Full Time",
                "Time": "Daytime",
                "Age Group": "Under 2",
                "Rate of Payment": "0.00/mo"
              },
              {
                "Schedule Type": "Vacation",
                "Care Type": "Part Time",
                "Time": "Daytime",
                "Age Group": "Under 2",
                "Rate of Payment": "6.00/mo"
              },
            ],
          "Name": "Fred",
          "Mailing Address Line 1": "806101239 Main St",
          "Mailing Address Line 2": null,
          "Mailing Address City Name": "MERCED",
          "Mailing Address State Code": "CA",
          "Mailing Address Zip Code Num": "95341",
          "Mailing Address Zip Suffix": "5447",
          "Physical Address Line 1": "806101239 Main St",
          "Physical Address Line 2": null,
          "Physical Address City Name": "MERCED",
          "Physical Address State Code": "CA",
          "Physical Address Zip Code Num": "95341",
          "Physical Address Zip Suffix": "5447",
          "phoneNumbers": [
            {
              "type": "Home",
              "number": "(939)431-9430"
            },
            {
              "type": "Fax",
              "number": "(174)798-5687"
            },
            {
              "type": "TDD",
              "number": "(944)949-2731"
            },
            {
              "type": "IVR",
              "number": "(944)949-2731"
            },
            {
              "type": "Work",
              "number": "(944)949-2731"
            }
          ]
        }

      },


      {
        "First Name": "Dannie",
        "Middle Initial": "S",
        "Last Name": "Cook",
        "DATE_OF_BIRTH": "2/01/2010",
        "status": "pending",
        "CC_HOURS": "4 HOURS",
        "Provider_Info": {
          "Type": "Licesed-Exempt non-relative Care",
          "Rate of Payment": {
            "Schedule Type": "Vacation",
            "Care Type": "Full Time",
            "Time": "Daytime",
            "Age Group": "Under 2",
            "Rate of Payment": "0.00/mo"
          },
          "Name": "Nancy",
          "Mailing Address Line 1": "806101239 Main St",
          "Mailing Address Line 2": null,
          "Mailing Address City Name": "MERCED",
          "Mailing Address State Code": "CA",
          "Mailing Address Zip Code Num": "95341",
          "Mailing Address Zip Suffix": "5447",
          "Physical Address Line 1": "806101239 Main St",
          "Physical Address Line 2": null,
          "Physical Address City Name": "MERCED",
          "Physical Address State Code": "CA",
          "Physical Address Zip Code Num": "95341",
          "Physical Address Zip Suffix": "5447",
          "phoneNumbers": [
            {
              "type": "Home",
              "number": "(939)431-9430"
            },
            {
              "type": "Work",
              "number": "(944)949-2731"
            }
          ]

        }

      }

    ],
  "Family Breakdown": {
    "adults": 2,
    "children": 2,
    "family size": 4
  },
  "sources of income": ["Employment", "Self-Employment", "Other Assistance"],
  "adjusted monthly income": "$2,000",
  "Other CalWORKs AU and non-AU family members in the household": [
    {
      "First Name": "Jane",
      "Middle Initial": "M",
      "Last Name": "Smith"
    },

    {
      "First Name": "Larry",
      "Middle Initial": "M",
      "Last Name": "Smith"
    },

    {
      "First Name": "Jennifer",
      "Middle Initial": "D",
      "Last Name": "Jones"
    },

  ]
}


export default data;







