import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import CHSearchList from '../shared-components/CHSearchList';
import SearchCriteria from '../components/SearchCriteria';
import StartSearchLogo from '../components/StartSearchLogo';
import SysDownLogo from '../components/SysDownLogo';
import NoResultsFound from '../components/NoResultsFound';
import TooManyFound from '../components/TooManyFound';
import {
  dataSelector,
  loadingSelector,
  loadedSelector,
  headerSelector,
  searchIsEmptySelector,
  searchErrorSelector,
  searchHasTooMany,
} from '../store/Search.slice';
import {
  awaitLoginSelector, getUser, getMonths, isWalkthroughHappening,
  showReportAnIssueButton, showHelpButton, showLogoutButton,
} from '../store/AppState.slice';
import '../App.css';
import CHLoading from '../shared-components/CHLoading';
import TourSearchResults from '../components/TourSearchResults';

const ChildCareSearch = () => {
  const dispatch = useDispatch();
  const loginInitiated = useSelector(awaitLoginSelector);
  const searchIsLoading = useSelector(loadingSelector);
  const searchHasLoaded = useSelector(loadedSelector);
  const searchHasError = useSelector(searchErrorSelector);
  const searchResults = useSelector(dataSelector);
  const searchIsEmpty = useSelector(searchIsEmptySelector);
  const tooManyError = useSelector(searchHasTooMany);
  const searchHeaders = useSelector(headerSelector);
  const user = useSelector(getUser);
  const walkthroughStarted = useSelector(isWalkthroughHappening);
  const months = useSelector(getMonths);

  // default the top buttons when the page loads
  useEffect(() => {
    dispatch(showReportAnIssueButton());
    dispatch(showHelpButton());
    dispatch(showLogoutButton());
  }, [dispatch]);

  return (
    <>
      {
        loginInitiated === false
          ? (
            <>
              <section className={`${typeof months === 'string' ? 'search-container-no-months' : ''} search-container`}>
                <SearchCriteria />
              </section>
              <section
                className={
                  `search-results-container
                  ${searchIsLoading === false && searchHasLoaded === true
                    ? 'add-scroll'
                    : ''
                  }`
                }
              >
                {(() => {
                  if (tooManyError === true) {
                    return <TooManyFound />;
                  }
                  if (searchHasError === true) {
                    return <SysDownLogo />;
                  }
                  if (searchIsEmpty === true) {
                    return <NoResultsFound />;
                  }
                  if (searchIsLoading === true && searchHasLoaded === false) {
                    return <CHLoading applyEllipses />;
                  }
                  if (searchIsLoading === false && searchHasLoaded === true && walkthroughStarted) {
                    return (
                      <div>
                        <CHSearchList
                          idKey="CASE_ID"
                          headers={searchHeaders}
                          data={searchResults}
                        />
                        <TourSearchResults />
                      </div>
                    );
                  }
                  if (searchIsLoading === false && searchHasLoaded === true) {
                    return <CHSearchList idKey="CASE_ID" headers={searchHeaders} data={searchResults} />;
                  }
                  return <StartSearchLogo name={user.first_name} />;
                })()}
              </section>
            </>
          )
          : (
            <CHLoading applyEllipses />
          )
      }
    </>
  );
};

export default ChildCareSearch;
