import { createSlice } from '@reduxjs/toolkit';

/* eslint-disable no-param-reassign */
export const chTextFieldSlice = createSlice({
  name: 'chTextField',
  initialState: {
    value: '',
  },
  reducers: {
    handleFieldValue: (state, action) => {
      state.value = action.payload;
    },
  },
});

export const {
  handleFieldValue,
} = chTextFieldSlice.actions;

export const chTextfieldSelector = (state) => state.chTextField.value;

export default chTextFieldSlice.reducer;
