import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import RFSearchList from '../shared-components/RFSearchList';
import ReferralSearchCriteria from '../components/ReferralSearchCriteria';
import StartReferralSearchLogo from '../components/StartReferralSearchLogo';
import SysDownLogo from '../components/SysDownLogo';
import NoResultsFound from '../components/NoResultsFound';
import {
  dataSelector,
  loadingSelector,
  loadedSelector,
  headerSelector,
  searchIsEmptySelector,
  searchErrorSelector,
} from '../store/ReferralSearch.slice';
import {
  awaitLoginSelector, getUser, showReportAnIssueButton, hideHelpButton, showLogoutButton,
} from '../store/AppState.slice';
import '../App.css';
import CHLoading from '../shared-components/CHLoading';

const ReferralSearch = () => {
  const loginInitiated = useSelector(awaitLoginSelector);
  const searchIsLoading = useSelector(loadingSelector);
  const searchHasLoaded = useSelector(loadedSelector);
  const searchHasError = useSelector(searchErrorSelector);
  const searchResults = useSelector(dataSelector);
  const searchIsEmpty = useSelector(searchIsEmptySelector);
  const searchHeaders = useSelector(headerSelector);
  const user = useSelector(getUser);

  // default the top buttons when the welcome page loads
  const dispatch = useDispatch();
  dispatch(showReportAnIssueButton());
  dispatch(hideHelpButton());
  dispatch(showLogoutButton());

  return (
    <>
      {
        loginInitiated === false
          ? (
            <>
              <section className="search-container">
                <ReferralSearchCriteria />
              </section>
              <section
                className={
                  `search-results-container
                  ${searchIsLoading === false && searchHasLoaded === true
                    ? 'add-scroll'
                    : ''
                  }`
                }
              >
                {(() => {
                  if (searchHasError === true) {
                    return <SysDownLogo />;
                  }
                  if (searchIsEmpty === true) {
                    return <NoResultsFound />;
                  }
                  if (searchIsLoading === true && searchHasLoaded === false) {
                    return <CHLoading applyEllipses />;
                  }
                  if (searchIsLoading === false && searchHasLoaded === true) {
                    return (
                      <div>
                        <RFSearchList
                          idKey="KEY_ID"
                          headers={searchHeaders}
                          data={searchResults}
                        />
                      </div>
                    );
                  }
                  if (searchIsLoading === false && searchHasLoaded === true) {
                    return <RFSearchList idKey="REFERRAL_ID" headers={searchHeaders} data={searchResults} />;
                  }
                  return <StartReferralSearchLogo name={user.first_name} />;
                })()}
              </section>
            </>
          )
          : (
            <CHLoading applyEllipses />
          )
      }
    </>
  );
};

export default ReferralSearch;
