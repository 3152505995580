import { configureStore } from '@reduxjs/toolkit';
import appStateReducer from './AppState.slice';
import chTextFieldReducer from './CHTextField.slice';
import searchReducer from './Search.slice';
import searchCriteriaSliceReducer from './SearchCriteria.slice';
import referralSearchReducer from './ReferralSearch.slice';
import referralSearchCriteriaSliceReducer from './ReferralSearchCriteria.slice';
import reportIssueSliceReducer from './ReportIssue.slice';
import profileSliceReducer from './Profile.slice';
import caseDetailsReducer from './CaseDetails.slice';

export default configureStore({
  reducer: {
    appState: appStateReducer,
    chTextField: chTextFieldReducer,
    search: searchReducer,
    searchCriteria: searchCriteriaSliceReducer,
    referralSearch: referralSearchReducer,
    referralSearchCriteria: referralSearchCriteriaSliceReducer,
    profile: profileSliceReducer,
    reportIssue: reportIssueSliceReducer,
    caseDetails: caseDetailsReducer,
  },
});
