import React from 'react';
import PropTypes from 'prop-types';

const SummaryInfo = ({ header, children }) => (
  <section className="details-container">
    <h1>
      {header}
    </h1>
    <section className="details">
      {children}
    </section>
  </section>
);

SummaryInfo.propTypes = {
  header: PropTypes.string,
  children: PropTypes.node.isRequired,
};

SummaryInfo.defaultProps = {
  header: '',
};

export default SummaryInfo;
