import React from 'react';
import '../App.css';
import PropTypes from 'prop-types';
import logo from '../assets/search_image.svg';

const StartSearchLogo = ({ name }) => {
  const startSearch = 'Please enter case details on the left';
  return (
    <section className="start-search">
      <h1>
        Welcome back
        {' '}
        {name}
      </h1>
      <img title="Search" alt={startSearch} src={logo} />
      <p>{startSearch}</p>
    </section>
  );
};

StartSearchLogo.propTypes = {
  name: PropTypes.string.isRequired,
};

StartSearchLogo.defaultProps = {
};

export default StartSearchLogo;
