import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { CircularProgress } from '@mui/material';

const CHLoading = ({ text, applyEllipses }) => {
  const [formattedText, setText] = useState(text);

  useEffect(() => {
    let intervalId;
    let ellipses = [];
    if (applyEllipses) {
      intervalId = setInterval(() => {
        const result = ellipses.some((el) => el === ' ');
        if (result === false) {
          ellipses = [' ', ' ', ' '];
        }
        else {
          const openSpace = ellipses.indexOf(' ');
          ellipses.splice(openSpace, 1, '.');
        }
        setText(`${text}${ellipses.join('')}`);
      }, 500);
    }
    return (
      () => {
        clearInterval(intervalId);
      }
    );
  }, [applyEllipses, text]);

  return (
    <div className="loading">
      <CircularProgress size={80} role="progressbar" />
      <br />
      <br />
      <p className="loading-text">
        {formattedText}
      </p>
    </div>
  );
};

CHLoading.propTypes = {
  text: PropTypes.string,
  applyEllipses: PropTypes.bool,
};

CHLoading.defaultProps = {
  applyEllipses: false,
  text: 'Loading',
};

export default CHLoading;
