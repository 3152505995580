export function apiParamCapture(state, action) {
  if (action.payload === null || Object.keys(action.payload).length === 0) {
    // eslint-disable-next-line no-param-reassign
    state.values = {};
  }
  else {
    const obj = {};
    if (action.payload.text) {
      obj[action.payload.id] = action.payload.text.trim();
      // eslint-disable-next-line no-param-reassign
      state.values = { ...state.values, ...obj };
    }
    else {
      // eslint-disable-next-line no-param-reassign
      delete state.values[action.payload.id];
    }
  }
}

export function errorHandling(state, action) {
  const { id, required, hasError } = action.payload.value;
  const { pristine } = action.payload;
  let formErrorArray = [...state.form];

  if (required === true && pristine === true) {
    formErrorArray.push(id);
  }
  else if (required === true && hasError === true && pristine === false) {
    formErrorArray.push(id);
  }
  else if (required === false && hasError === true) {
    formErrorArray.push(id);
  }
  else if (formErrorArray.indexOf(id) >= 0) {
    formErrorArray.splice(formErrorArray.indexOf(id), 1);
  }

  // filter out duplicate ids;
  formErrorArray = formErrorArray.filter((item, index) => formErrorArray.indexOf(item) === index);
  // eslint-disable-next-line no-param-reassign
  state.form = [...formErrorArray];

  // eslint-disable-next-line no-param-reassign
  state.formHasError = formErrorArray.length > 0;
}

export function formChecker(state) {
  // eslint-disable-next-line no-param-reassign
  state.checkingForm = true;
}

export function resetFormChecker(state) {
  // eslint-disable-next-line no-param-reassign
  state.checkingForm = false;
}
