import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import withStyles from '@mui/styles/withStyles';
import {
  AppBar, Menu, MenuItem,
} from '@mui/material';
import { ACTIONS, EVENTS } from 'react-joyride';
import { styleWithRoot } from '../styles/CHAppBar.styles';
import logo from '../assets/calsaws_logo.svg';
import {
  logout, getIdToken, getAccessToken, getUser, endWalkthrough, startWalkthrough,
  getShowReportAnIssueButton, getShowHelpButton, getShowLogoutButton,
} from '../store/AppState.slice';
import {
  loadedSelector, searchIsEmptySelector, searchErrorSelector, searchHasTooMany,
} from '../store/Search.slice';
import { areCaseDetailsLoaded, caseDetailsHasError } from '../store/CaseDetails.slice';
import CHButton from './CHButton';
import { signout, revokeToken } from '../api/apiRequest';
import ReportIssue from '../components/ReportIssue';
import CHDialog from './CHDialog';
import CHVideoCard from './CHVideoCard';
import vid from '../assets/training/ChildCareAdministratorPortalOverview.mp4';
import Tour from '../components/Tour';

const CssAppBar = withStyles(styleWithRoot)(AppBar);

const CHAppBar = () => {
  const dispatch = useDispatch();

  const [isReportIssueOpen, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [videoIsOpen, setVideo] = useState(false);

  const idToken = useSelector(getIdToken);
  const accessToken = useSelector(getAccessToken);
  const user = useSelector(getUser);

  const [run, setRun] = useState(false);

  const searchLoaded = useSelector(loadedSelector);
  const caseDetails = useSelector(areCaseDetailsLoaded);
  const hasError = useSelector(searchErrorSelector);
  const tooMany = useSelector(searchHasTooMany);
  const searchIsEmpty = useSelector(searchIsEmptySelector);
  const castDetailsHasError = useSelector(caseDetailsHasError);

  const isReportAnIssueButtonVisible = useSelector(getShowReportAnIssueButton);
  const isHelpButtonVisible = useSelector(getShowHelpButton);
  const isLogoutButtonVisible = useSelector(getShowLogoutButton);

  const handleLogout = () => {
    revokeToken(accessToken).then(() => {
      signout(idToken).then(() => {
        dispatch(logout());
      }).catch(() => {
        document.cookie = 'iPlanetDirectoryPro=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
        sessionStorage.clear();
        dispatch(logout());
      });
    }).catch(() => {
    });
  };

  const getHelp = (e) => {
    // do nothing right now
    setAnchorEl(e.currentTarget);
  };

  const getHelpClose = () => {
    setAnchorEl(null);
  };

  const showVideo = () => {
    getHelpClose();
    setVideo(true);
  };

  const startTour = () => {
    getHelpClose();
    if (searchLoaded === false && caseDetails === false) {
      setRun(true);
    }
    if (hasError || tooMany || searchIsEmpty || castDetailsHasError) {
      setRun(true);
    }
    dispatch(startWalkthrough());
  };

  const callback = (data) => {
    const { type, action } = data;
    if (type === EVENTS.TOUR_END && run) {
      setRun(false);
    }
    if (action === ACTIONS.CLOSE) {
      setRun(false);
      dispatch(endWalkthrough());
    }
    if (action === ACTIONS.SKIP) {
      dispatch(endWalkthrough());
    }
  };

  return (
    <CssAppBar className="flex" position="fixed" style={{ zIndex: 99, minHeight: 70 }}>
      <section className="heading-logo">
        <h1>
          <Link to="/welcome">
            <img title="CalSAWS" alt="CalSAWS Logo" src={logo} width={300} />
          </Link>
        </h1>
      </section>
      <section className="profile-group header">
        {
          isReportAnIssueButtonVisible === true
            ? (
              <>
                <CHButton
                  id="report-an-issue-button"
                  color="primary"
                  variant="outlined"
                  buttonText="Report an Issue"
                  classNames="push-down reportissue"
                  handleClick={() => {
                    setOpen(true);
                  }}
                />
                <ReportIssue
                  isOpen={isReportIssueOpen}
                  handleClose={() => setOpen(false)}
                  profile={user}
                />
              </>
            ) : null
        }
        {
          isHelpButtonVisible === true
            ? (
              <>
                <CHButton
                  id="help-btn"
                  color="primary"
                  variant="outlined"
                  buttonText="Help"
                  classNames="push-down gethelp"
                  handleClick={getHelp}
                />
                <Menu
                  id="help-menu"
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={getHelpClose}
                >
                  <MenuItem className="menu-item" onClick={startTour}>
                    <span className="primary bold">Site Walkthrough</span>
                  </MenuItem>
                  <MenuItem onClick={showVideo}><span className="primary bold">Video Training</span></MenuItem>
                </Menu>
                {
                  videoIsOpen === true
                    ? (
                      <CHDialog
                        id="video-training"
                        title="Video Training"
                        buttonText="Close"
                        isOpen={videoIsOpen}
                        handleClose={() => {
                          setVideo(false);
                        }}
                      >
                        <CHVideoCard
                          src={vid}
                          title="Childcare Portal Video Walkthrough"
                        />
                      </CHDialog>
                    )
                    : null
                }
              </>
            ) : null
        }
        {
          isLogoutButtonVisible === true
            ? (
              <CHButton
                id="logout-button"
                color="primary"
                variant="outlined"
                buttonText="logout"
                classNames="push-down logout"
                handleClick={handleLogout}
              />
            ) : null
        }
        <Tour run={run} callback={callback} />
      </section>
    </CssAppBar>
  );
};

CHAppBar.propTypes = {
};

CHAppBar.defaultProps = {
};

export default CHAppBar;
