import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Card } from '@mui/material';
import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import withStyles from '@mui/styles/withStyles';
import { TabContext, TabPanel } from '@mui/lab';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import CHNameCard from '../shared-components/CHNameCard';
import CHTelephoneCard from '../shared-components/CHTelephoneCard';
import CHAddressCard from '../shared-components/CHAddressCard';
import CHTableCard from '../shared-components/CHTableCard';

const AntTabs = withStyles({
  root: {
    borderBottom: '1px solid #e8e8e8',
  },
  indicator: {
    backgroundColor: '#3f51b5',
  },
})(Tabs);

const AntTab = withStyles((theme) => ({
  root: {
    textTransform: 'none',
    minWidth: 72,
    fontWeight: theme.typography.fontWeightBold,
    marginRight: theme.spacing(4),
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:hover': {
      color: '#2738A5',
      opacity: 1,
    },
    '&$selected': {
      color: '#3f51b5',
    },
    '&:focus': {
      color: '#2738A5',
    },
  },
  selected: {},
}))((props) => <Tab disableRipple {...props} />);

const ChildInfo = ({ data }) => {
  const {
    firstName,
    middleInitial,
    lastName,
    personStatus,
    weeklyHours,
    dob,
    childMapIndex,
  } = data;
  const providerInfo = data.providerInfo || {};

  let defaultProviderTab = `Provider-0_${childMapIndex}`;
  if (data.providerInfo && data.providerInfo[0].providerId) {
    defaultProviderTab = `${data.providerInfo[0].providerId.toString()}-0_${childMapIndex}`;
  }

  const [childProviderTabKey, setVal] = useState(defaultProviderTab);

  const handleTabChange = (e, newVal) => {
    setVal(String(newVal));
  };

  function a11yProps(index) {
    return {
      id: `scrollable-auto-tab-${index}`,
      'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
  }

  return (

    <section className="details-container">
      <div>
        <Grid container spacing={5}>
          <Grid item xs={6}>
            <CHNameCard
              first={firstName}
              middle={middleInitial}
              last={lastName}
            />
          </Grid>
          <Grid item xs={6}>
            <Card>
              <Table aria-label="simple table">
                <TableBody>
                  <TableRow>
                    <TableCell scope="row" className="card-label-column">
                      Date of Birth
                    </TableCell>
                    <TableCell align="left">
                      {dob || '-'}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell scope="row" className="card-label-column">
                      Status
                    </TableCell>
                    <TableCell align="left">
                      {personStatus || '-'}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell scope="row" className="card-label-column">
                      Number of hours of Child Care authorized on the Stage One Child Care authorization
                    </TableCell>
                    <TableCell align="left">
                      {weeklyHours || '-'}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Card>
          </Grid>
          <Grid item xs={12} style={{ paddingBottom: 0 }}>
            <h2 className="childcare-provider" style={{ marginBottom: 0 }}>Child Care Provider Info:</h2>
          </Grid>

          {providerInfo && providerInfo.length > 0 ? (
            <Grid item xs={12}>
              <TabContext value={childProviderTabKey}>
                <AntTabs
                  onChange={handleTabChange}
                  value={childProviderTabKey}
                  indicatorColor="primary"
                  textColor="primary"
                  variant="scrollable"
                  scrollButtons="auto"
                >
                  {
                    providerInfo.map((i, childProviderTabKeyIndex) => (
                      <AntTab
                        className="childcare-provider-tab"
                        // eslint-disable-next-line react/no-array-index-key
                        key={`${i.providerId || '-'}-${childProviderTabKeyIndex}`}
                        value={`${i.providerId || 'Provider'}-${childProviderTabKeyIndex}_${childMapIndex}`}
                        label={`${i.providerName || 'Provider'}`}
                        {...a11yProps(`${i.providerId || 'Provider'}-${childProviderTabKeyIndex}_${childMapIndex}`)}
                      />
                    ))
                  }
                </AntTabs>

                {providerInfo.map((i, childProviderTabKeyIndex) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <TabPanel
                    key={`${i.providerId || 'Provider'}-${childMapIndex}`}
                    value={`${i.providerId || 'Provider'}-${childProviderTabKeyIndex}_${childMapIndex}`}
                    index={`${i.providerId || 'Provider'}-${childProviderTabKeyIndex}_${childMapIndex}`}
                    style={{ padding: 0 }}
                    id={`scrollable-auto-tabpanel-${i.providerId
                      || 'Provider'}-${childProviderTabKeyIndex}_${childMapIndex}`}
                  >
                    <Grid
                      container
                      spacing={5}
                      // eslint-disable-next-line react/no-array-index-key
                      key={`${i.providerName || '-'}- ${childProviderTabKeyIndex}`}
                    >
                      <Grid
                        item
                        xs
                        // eslint-disable-next-line react/no-array-index-key
                        key={`${i.providerName || '-'} - ${childProviderTabKeyIndex}`}
                      >
                        <div className="h2height"> &nbsp; </div>
                        <Card>
                          <Table aria-label="simple table">
                            <TableBody>
                              <TableRow>
                                <TableCell scope="row" className="card-label-column">
                                  Name
                                </TableCell>
                                <TableCell align="left">
                                  {i.providerName || '-'}
                                </TableCell>
                              </TableRow>

                              <TableRow>
                                <TableCell scope="row" className="card-label-column">
                                  Type
                                </TableCell>
                                <TableCell align="left">
                                  {i.providerType || '-'}
                                </TableCell>
                              </TableRow>
                            </TableBody>
                          </Table>
                        </Card>
                        <h2> Address </h2>
                        <CHAddressCard
                          mailing={i.mailing}
                          physical={i.physical}
                        />
                        <h2> Telephone Numbers </h2>
                        <CHTelephoneCard phoneNumbers={i.phoneNumbers} />
                      </Grid>
                      <Grid item xs={6}>
                        <h2>Provider Rates List</h2>
                        <CHTableCard
                          dataList={i.rates}
                          headers={['Schedule Type', 'Care Type', 'Time', 'Age Group', 'Rate of Payment']}
                        />
                      </Grid>
                    </Grid>
                  </TabPanel>
                ))}
              </TabContext>
            </Grid>
          ) : null}

        </Grid>
      </div>
    </section>
  );
};

ChildInfo.propTypes = {
  data: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    middleInitial: PropTypes.string,
    dob: PropTypes.string,
    personStatus: PropTypes.string,
    weeklyHours: PropTypes.number,
    providerInfo: PropTypes.arrayOf(PropTypes.shape({
      providerName: PropTypes.string,
      providerId: PropTypes.number,
      providerType: PropTypes.string,
      mailing: PropTypes.string,
      physical: PropTypes.string,
      phoneNumbers: PropTypes.arrayOf(PropTypes.shape({
        type: PropTypes.string,
        number: PropTypes.string,
      })),
      providerRatesList: PropTypes.arrayOf(PropTypes.shape({
        scheduleType: PropTypes.string,
        careType: PropTypes.string,
        ageGroup: PropTypes.string,
        timeType: PropTypes.string,
        rateOfPayment: PropTypes.string,
      })),
    })),
    childMapIndex: PropTypes.number,
  }),
};

ChildInfo.defaultProps = {
  data: PropTypes.shape({
    firstName: '-',
    lastName: '-',
    middleInitial: '-',
    dob: '-',
    personStatus: '-',
    weeklyHours: '-',
    providerInfo: PropTypes.arrayOf(PropTypes.shape({
      providerName: '-',
      providerId: '-',
      providerType: '-',
      mailing: '-',
      physical: '-',
      phoneNumbers: PropTypes.arrayOf(PropTypes.shape({
        type: '-',
        number: '-',
      })),
      providerRatesList: PropTypes.arrayOf(PropTypes.shape({
        scheduleType: '-',
        careType: '-',
        ageGroup: '-',
        timeType: '-',
        rateOfPayment: '-',
      })),
    })),
    childMapIndex: 0,
  }),
};

export default ChildInfo;
