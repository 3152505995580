import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { UserManager } from 'oidc-client';
import moment from 'moment';
import {
  ThemeProvider, StyledEngineProvider, createTheme,
} from '@mui/material/styles';
import PortalRouter from './router/PortalRouter';
import {
  login, getAccessToken, refresh, logout, logoutWarning, getLogoutWarningFlag, resetLogoutWarning,
  loggedInSelector, getCSRFToken, setCSRFToken, awaitCSRFSelector, initCSRFToken, refreshCounter,
} from './store/AppState.slice';
import {
  AUTHORIZE, handleRefreshToken, SESSIONKEY, signout, revokeToken,
  REFRESH, ISSUER, USERINFO, JWKS, initializeCSRF,
} from './api/apiRequest';
import LogoutWarning from './components/LogoutWarning';

const theme = createTheme({});

function App() {
  let idleTime = 0;
  let intervalId;

  /* Seconds before session times out and redirects to logout.
     Setting to 20 X 60 = 1,200 for 20 minutes */
  const idleMax = 1200;
  /* Seconds before session warning appears.
     Setting to 15 X 60 = 900 for 15 minutes */
  const idleWarningMax = 900;
  /* Milliseconds between session timeout checking
     Setting to 1000 for checking every second */
  const idleInterval = 1000;
  /* used to refresh the session token every 19 minutes
     before it expires after 20 min. */
  const interval = 1140000;
  // 90% of the interval - to trigger new token refresh
  const intervalThreshold = Math.round(interval * 0.9);

  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();

  const accessToken = useSelector(getAccessToken);
  const shouldShowLogoutWarning = useSelector(getLogoutWarningFlag);
  const isLoggedIn = useSelector(loggedInSelector);
  const csrfToken = useSelector(getCSRFToken);
  const isWaitingForCSRFToken = useSelector(awaitCSRFSelector);
  const refreshCount = useSelector(refreshCounter);

  const urlParams = new URLSearchParams(location.search);
  const userManager = new UserManager({
    authority: AUTHORIZE,
    client_id: process.env.REACT_APP_CLIENT_ID,
    redirect_uri: process.env.REACT_APP_ORIGIN,
    response_type: process.env.REACT_APP_RESPONSE_TYPE,
    scope: process.env.REACT_APP_SCOPE,
    grant_type: 'authorization_code',
    accessTokenExpiringNotificationTime: 60,
    metadata: {
      issuer: ISSUER,
      authorization_endpoint: AUTHORIZE,
      token_endpoint: REFRESH,
      userinfo_endpoint: USERINFO,
      jwks_uri: JWKS,
    },
  });

  const updateStorage = (newToken) => {
    /*
        need to update storage when a new token comes in because upon refresh the library
        directly pulls from storage first to get the refresh token, if it's stale then the endpoint
        will error out.
    */
    const storage = JSON.parse(sessionStorage.getItem(SESSIONKEY));
    const newStorage = JSON.stringify({ ...storage, ...newToken });
    sessionStorage.setItem(SESSIONKEY, newStorage);
  };

  const refreshing = async (token) => {
    let tokens = {};
    handleRefreshToken(token).then((data) => {
      const {
        // eslint-disable-next-line camelcase
        access_token, id_token, refresh_token,
      } = data;
      tokens = {
        // eslint-disable-next-line camelcase
        accessToken: access_token,
        // eslint-disable-next-line camelcase
        idToken: id_token,
        // eslint-disable-next-line camelcase
        refreshToken: refresh_token,
      };
      // eslint-disable-next-line camelcase
      updateStorage({ access_token, id_token, refresh_token });
      dispatch(refresh(tokens));
      /**
       * set a timeout to call refresh again a minute before the token is set to expire (~19mins)
       */
      setTimeout(() => {
        refreshing(tokens.refreshToken);
      }, interval);
    }).catch(() => {
      /**
       * if token happens to error out or become invalid,
       * then we're clearing out the old token in storage and triggerring a refresh
       * so that it will automatically redirect to the forgerock login screen.
       */
      console.debug('Token Refresh Error: ');
      sessionStorage.clear();
      history.push('/redirect');
    });
  };

  // refresh the CSRF Tokens for this session
  useEffect(() => {
    const thisRefreshedTimestamp = new Date().getTime();
    const lastRefreshedTimestamp = sessionStorage.getItem('CSRF_REFRESHED');
    let millisecondsSinceLastRefresh = 0;
    if (lastRefreshedTimestamp) {
      millisecondsSinceLastRefresh = thisRefreshedTimestamp - lastRefreshedTimestamp;
    }

    if (!isWaitingForCSRFToken && csrfToken && isLoggedIn && accessToken
      && refreshCount > 1 && millisecondsSinceLastRefresh >= intervalThreshold) {
      console.debug('Attempting to get a new CSRF token prior to token expiration...');
      dispatch(initCSRFToken());
      initializeCSRF(accessToken, { CSRF_STATE_TOKEN: csrfToken }).then((data) => {
        if (data.SESSION_ID && data.CSRF_TOKEN) {
          // data.SESSION_ID goes in browser session storage under key: CSRF_SESSION_TOKEN
          sessionStorage.setItem('CSRF_SESSION_TOKEN', data.SESSION_ID);
          // current timestamp goes in browser session storage under key: CSRF_REFRESHED
          sessionStorage.setItem('CSRF_REFRESHED', thisRefreshedTimestamp);
          // data.CSRF_TOKEN goes in app state slice via dispatch(setCSRFToken())
          dispatch(setCSRFToken({ csrfToken: data.CSRF_TOKEN }));
        }
        else {
          console.debug('An error ocurred obtaining refreshed CSRF Token!');
        }
      }).catch(() => {
        console.debug('An error ocurred obtaining refreshed CSRF Token!');
      });
    }
  }, [accessToken, isWaitingForCSRFToken, isLoggedIn, csrfToken, dispatch, refreshCount, intervalThreshold]);

  const startLogout = () => {
    revokeToken(accessToken).then(() => {
      signout().then(() => {
        dispatch(logout());
        history.push('/redirect');
      }).catch(() => {
        document.cookie = 'iPlanetDirectoryPro=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
        sessionStorage.clear();
        dispatch(logout());
        history.push('/redirect');
      });
    }).catch(() => {
    });
  };

  const redirect = (authUser, shouldSetTimeout = true) => {
    const {
      // eslint-disable-next-line camelcase
      profile, access_token, id_token, refresh_token,
    } = authUser;
    const tokens = {
      profile,
      // eslint-disable-next-line camelcase
      accessToken: access_token,
      // eslint-disable-next-line camelcase
      idToken: id_token,
      // eslint-disable-next-line camelcase
      refreshToken: refresh_token,
    };
    dispatch(login(tokens));
    /**
     * refreshing on a page other than /welcome needs to be accounted for
     * Defaulting to /welcome
     * Appending retryCount parameter to prevent infinite retries
     */

    const retryParam = window.location.search
      // eslint-disable-next-line prefer-template
      ? '?' + window.location.search.split('&').filter((chunk) => chunk.startsWith('retryCount=')) : '';

    // eslint-disable-next-line prefer-template
    const newPath = location.pathname === '/' ? '/welcome' + retryParam : location.pathname;

    history.push(newPath);

    if (shouldSetTimeout) {
      setTimeout(() => {
        refreshing(tokens.refreshToken);
      }, interval);
    }
  };

  // prevents infinite loop of redirects when the page lands back to the app.
  if (accessToken === null) {
    // part 1 - this is the initial request to see if there is an authenticated user
    userManager.getUser().then((authUser) => {
      // if no user is found, then it will kick off login redirect code flow
      if (authUser === null && !urlParams.get('code')) {
        userManager.signinRedirect().then(() => {
        }).catch(() => {
          document.cookie = 'iPlanetDirectoryPro=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
          sessionStorage.clear();
          dispatch(logout());
          history.push('/redirect');
        });
      }
      else if (authUser !== null) {
        // this condition will run if there's a page refresh while there's still an authenticated user active
        // and will automatically refresh existing access token
        refreshing(authUser.refresh_token);
        redirect(authUser, false);
      }
      else {
        // part 2 - this will run after the redirect has occured which completes initial request
        userManager.signinRedirectCallback().then((cbAuthUser) => {
          redirect(cbAuthUser);
        }).catch(() => {
          document.cookie = 'iPlanetDirectoryPro=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
          sessionStorage.clear();
          dispatch(logout());
          history.push('/redirect');
        });
      }
    }).catch(() => {
    });
  }

  const el = document.getElementById('root');
  el.addEventListener('mousemove', () => {
    if (idleTime > 60) {
      console.debug('No longer idle...');
    }
    idleTime = 0;
  });
  window.addEventListener('keydown', () => {
    if (idleTime > 60) {
      console.debug('No longer idle...');
    }
    idleTime = 0;
  });
  if (!intervalId) {
    intervalId = setInterval(() => {
      idleTime++;
      /*
      Only display the console timer when the logout warning window is not visible
      */
      if (!shouldShowLogoutWarning) {
        /*
        Only display the console timer after a minute of inactivity and only every 10 seconds.
        */
        if (idleTime > 60 && idleTime % 10 === 0) {
          console.debug(`Idle Time: ${moment.utc(idleTime * 1000).format('mm:ss')} `);
        }
      }
      if (idleTime === idleWarningMax) {
        console.debug('Warning idle time reached.  Displaying modal warning window.');
        dispatch(logoutWarning());
      }
      else if (idleTime >= idleMax) {
        console.debug('Maximum idle time reached. Logging out now.');
        startLogout();
      }
    }, idleInterval);
  }

  useEffect(() => () => {
    clearInterval(intervalId);
    el.removeEventListener('mousemove', () => { });
    window.removeEventListener('keydown', () => { });
  });

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <PortalRouter
          startLogout={startLogout}
          loggedIn={accessToken}
          userManager={userManager}
        />
        {
          shouldShowLogoutWarning === true
            ? (
              <LogoutWarning
                isOpen={shouldShowLogoutWarning}
                logout={startLogout}
                handleClose={() => {
                  dispatch(resetLogoutWarning());
                }}
              />
            )
            : null
        }
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

App.propTypes = {
};

export default App;
