import axios from 'axios';
import { Buffer } from 'buffer';
import mock from '../mock-data/mock';

export const GET = 'get';
export const POST = 'post';
export const UPDATE = 'update';
export const PUT = 'put';
export const DELETE = 'delete';

const apiBaseUrl = process.env.REACT_APP_API_URL;
const forgerockBaseUrl = process.env.REACT_APP_FORGEROCK_BASE_URL;
const port = process.env.REACT_APP_PORT;
const childcarePath = 'childcare';
const referralPath = 'referral';

/* URLS BELOW */
const MONTHLY_REPORT_ENDPOINT = { method: 'get', url: `${apiBaseUrl}/${childcarePath}/monthly-report` };
const CSRF_ENDPOINT = { method: 'get', url: `${apiBaseUrl}/csrf-token/` };
export const SEARCH_REFERRAL_ENDPOINT = { method: 'get', url: `${apiBaseUrl}/${referralPath}/` };
export const UPDATE_REFERRAL_ENDPOINT = { method: 'put', url: `${apiBaseUrl}/${referralPath}/` };
export const SEARCH_CHILD_CARE_ENDPOINT = { method: 'get', url: `${apiBaseUrl}/${childcarePath}/search` };
export const DETAIL_CHILD_CARE_ENDPOINT = { method: 'get', url: `${apiBaseUrl}/${childcarePath}/case` };
export const SUBMIT_ISSUES = 'https://calsawsdev.service-now.com/api/now/table/incident'; // not currently in use
export const AUTHORIZE = `${forgerockBaseUrl}${port}/auth/oauth2/realms/root/realms/calsaws/authorize`;
export const REVOKE = `${forgerockBaseUrl}${port}/auth/oauth2/realms/root/realms/calsaws/token/revoke`;
export const SIGNOUT = `${forgerockBaseUrl}${port}/auth/json/realms/root/realms/calsaws/sessions/?_action=logout`;
export const REFRESH = `${forgerockBaseUrl}${port}/auth/oauth2/realms/root/realms/calsaws/access_token`;
export const USERINFO = `${forgerockBaseUrl}${port}/auth/oauth2/realms/root/realms/calsaws/userinfo`;
export const ISSUER = `${forgerockBaseUrl}/auth/oauth2`;
export const JWKS = `${forgerockBaseUrl}/auth/oauth2/connect/jwk_uri`;

// eslint-disable-next-line max-len
export const SESSIONKEY = `oidc.user:${forgerockBaseUrl}${port}/auth/oauth2/realms/root/realms/calsaws/authorize:${process.env.REACT_APP_CLIENT_ID}`;

function errorHandler(response) {
  return new Promise((_, reject) => {
    reject(response);
  });
}

function successHandler(response) {
  return new Promise((resolve) => {
    resolve(response.data.body);
  });
}

export async function revokeToken(accessToken) {
  try {
    const result = await axios({
      method: POST,
      url: REVOKE,
      params: {
        token: accessToken,
        client_id: process.env.REACT_APP_CLIENT_ID,
      },
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'Cache-Control': 'no-cache',
      },
    });
    return successHandler(result);
  }
  catch (err) {
    return errorHandler(err);
  }
}

export async function handleRefreshToken(token) {
  try {
    const result = await axios({
      method: POST,
      url: REFRESH,
      params: {
        grant_type: 'refresh_token',
        refresh_token: token,
        client_id: process.env.REACT_APP_CLIENT_ID,
        scope: process.env.REACT_APP_SCOPE,
      },
      data: {
      },
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'Cache-Control': 'no-cache',
      },
    });
    const response = {
      data: { body: { ...result.data } },
    };
    return successHandler(response);
  }
  catch (err) {
    return errorHandler(err);
  }
}

export async function signout() {
  try {
    const result = await axios({
      method: POST,
      url: SIGNOUT,
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        'Accept-API-Version': 'resource=3.1, protocol=1.0',
      },
      withCredentials: true,
    });
    sessionStorage.clear();
    return successHandler(result);
  }
  catch (err) {
    return errorHandler(err);
  }
}

function validateAxios403(statusCode, accessToken) {
  if (statusCode === 403) {
    // force sign out
    console.debug('CSRF Token Error!!! Signing out...');
    revokeToken(accessToken).then(() => {
      signout().then(() => {
        sessionStorage.clear();
        window.location.href = process.env.REACT_APP_ORIGIN;
      }).catch(() => {
        document.cookie = 'iPlanetDirectoryPro=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
        sessionStorage.clear();
        window.location.href = process.env.REACT_APP_ORIGIN;
      });
    }).catch(() => {
      sessionStorage.clear();
      window.location.href = process.env.REACT_APP_ORIGIN;
    });

    return false;
  }
  return true;
}

async function apiRequest(type, url, params = {}, data = {}, extraHeaders) {
  const headers = { 'Content-Type': 'application/json', 'Cache-Control': 'max-age=3600' };
  if (extraHeaders.accessToken) {
    headers.Authorization = `Bearer ${extraHeaders.accessToken} `;
  }
  if (extraHeaders.basicAuth === true) {
    const auth = 'basicform:basicform';
    const buff = Buffer.from(auth);
    const base64data = buff.toString('base64');
    headers.Authorization = `Basic ${base64data} `;
  }

  // pass on the CSRF Token in the 'csrf-token' header
  if (extraHeaders.CSRF_STATE_TOKEN) {
    headers['csrf-token'] = extraHeaders.CSRF_STATE_TOKEN;
  }

  // pass on the CSRF Session Key from browser sessionStorage in the 'csrf-session-token' header
  const csrfSessionToken = sessionStorage.getItem('CSRF_SESSION_TOKEN');
  if (csrfSessionToken) {
    headers['csrf-session-token'] = csrfSessionToken;
  }
  try {
    const result = await axios({
      method: type,
      url,
      params,
      data,
      headers,
      validateStatus: (statusCode) => validateAxios403(statusCode, extraHeaders.accessToken),
    });
    return successHandler(result);
  }
  catch (err) {
    return errorHandler(err);
  }
}

export default apiRequest;

export async function initializeCSRF(accessToken, extraHeaders) {
  const headers = {
    'Content-Type': 'application/json',
    'Cache-Control': 'max-age=3600',
  };
  if (accessToken) {
    headers.Authorization = `Bearer ${accessToken} `;
  }

  // pass on the CSRF Token in the 'csrf-token' header
  if (extraHeaders.CSRF_STATE_TOKEN) {
    headers['csrf-token'] = extraHeaders.CSRF_STATE_TOKEN;
  }

  // pass on the CSRF Session Key from browser sessionStorage in the 'csrf-session-token' header
  const csrfSessionToken = sessionStorage.getItem('CSRF_SESSION_TOKEN');
  if (csrfSessionToken) {
    headers['csrf-session-token'] = csrfSessionToken;
  }

  try {
    const result = await axios({
      method: CSRF_ENDPOINT.method,
      url: CSRF_ENDPOINT.url,
      responseType: 'text',
      headers,
      params: {},
      data: {},
      validateStatus: (statusCode) => {
        if (statusCode >= 400) {
          console.debug('CSRF Token Generation Failure!');
          return false;
        }
        return true;
      },
    });

    if (!result.data || (result.data && result.data.error)) {
      return errorHandler(result.data.error);
    }

    return new Promise((resolve) => {
      resolve(JSON.parse(result.data));
    });
  }
  catch (err) {
    return errorHandler(err);
  }
}

export async function handleMonthlyReport(month, extraHeaders, params = {}, data = {}) {
  const headers = {
    'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    Accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'Cache-Control': 'max-age=3600',
  };

  if (extraHeaders.accessToken) {
    headers.Authorization = `Bearer ${extraHeaders.accessToken}`;
  }

  // pass on the CSRF Token in the 'csrf-token' header
  if (extraHeaders.CSRF_STATE_TOKEN) {
    headers['csrf-token'] = extraHeaders.CSRF_STATE_TOKEN;
  }

  // pass on the CSRF Session Key from browser sessionStorage in the 'csrf-session-token' header
  const csrfSessionToken = sessionStorage.getItem('CSRF_SESSION_TOKEN');
  if (csrfSessionToken) {
    headers['csrf-session-token'] = csrfSessionToken;
  }

  const filename = `${month}_ChildCareMonthlyReport.xlsx`;
  try {
    const result = await axios({
      method: MONTHLY_REPORT_ENDPOINT.method,
      url: `${MONTHLY_REPORT_ENDPOINT.url}/${filename}`,
      params,
      responseType: 'blob',
      data,
      headers,
      validateStatus: (statusCode) => validateAxios403(statusCode, extraHeaders.accessToken),
    });

    // check the blob size to make sure we actually have an xlsx file
    if (result.data && result.data.size && result.data.size < 500) {
      return errorHandler('File not found!');
    }

    if (result.data && result.data.error) {
      return errorHandler(result.data.error);
    }

    return new Promise((resolve) => {
      resolve(result.data);
    });
  }
  catch (err) {
    return errorHandler(err);
  }
}

/* USED FOR MOCK/TESTING PURPOSES ONLY */
export async function apiMockGetRequest({ key, error = false }) {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      if (error === false) {
        resolve(mock[key]);
      }
      else {
        reject(new Error('something bad happened'));
      }
    }, 1000);
  });
}

export async function apiMockPostRequest({ data, error = false }) {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      if (error === false) {
        resolve(data);
      }
      else {
        reject(new Error('something bad happened'));
      }
    }, 1000);
  });
}
