import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import CHMoreHorizIcon from './CHMoreHorizIcon';
import { styleWithRoot } from '../styles/CHSearchList.styles';
import { getProgramPersonIdTable } from '../store/Search.slice';

const CssTable = withStyles(styleWithRoot)(Table);

const SearchList = ({ headers, data, idKey }) => {
  const [hoveredElement, setHoveredElement] = useState('');
  const history = useHistory();
  const programPersonIdTable = useSelector(getProgramPersonIdTable);

  const handleMouseOver = (id) => {
    setHoveredElement(id);
  };

  // for accessability purposes
  const handleFocus = (id) => {
    handleMouseOver(id);
  };

  const handleMouseLeave = () => {
    setHoveredElement('');
  };

  const handleRowClick = (row) => {
    const { programId, programPersonId, serialNumIdentif } = programPersonIdTable[row];
    history.push(`/childCareSummary/${serialNumIdentif}/${programId}/${programPersonId}`);
  };

  const handleKeyPress = (row, e) => {
    if (e.key === 'Enter') {
      handleRowClick(row);
    }
  };

  let rowCounter = 0;
  let columnCounter = 0;

  return (
    <TableContainer tabIndex="0">
      <CssTable aria-label="simple table">
        <TableHead>
          <TableRow className="search-results">
            {
              headers.map((header) => (
                <TableCell
                  key={`header-${header}`}
                  align="left"
                  className="header-row"
                >
                  {header}
                </TableCell>
              ))
            }
          </TableRow>
        </TableHead>
        <TableBody>
          {
            data.map((obj, index) => {
              const row = Object.keys(obj);
              rowCounter++;
              return (
                <TableRow
                  // eslint-disable-next-line react/no-array-index-key
                  id={`${obj[idKey]}-${index}`}
                  // eslint-disable-next-line react/no-array-index-key
                  key={`${obj[idKey]}-${index}`}
                  tabIndex="0"
                  className="info-row"
                  scope="row"
                  onMouseLeave={handleMouseLeave}
                  onMouseOver={() => handleMouseOver(obj[idKey])}
                  onFocus={() => handleFocus(obj[idKey])}
                >
                  {
                    row.map((cell) => {
                      let value = obj[cell];
                      const isButton = cell === 'shouldShowButton';
                      if (isButton) {
                        value = (
                          <CHMoreHorizIcon
                            dataTestId="user-detail-button"
                            key={`cell-${obj[cell]}-button`}
                            role="button"
                            className="last-cell"
                            handleRowClick={() => handleRowClick(obj[idKey])}
                            shouldShow={hoveredElement === obj[idKey]}
                            id={obj[idKey]}
                          />
                        );
                      }
                      // don't spit out a table cell for the CASE_ID data element
                      if (cell !== 'CASE_ID') {
                        columnCounter++;
                        return (
                          <TableCell
                            key={`cell-${obj[cell]}-${rowCounter}-${columnCounter}`}
                            tabIndex="0"
                            onKeyDown={isButton ? (e) => handleKeyPress(obj[idKey], e) : null}
                            align="left"
                          >
                            {value}
                          </TableCell>
                        );
                      }
                      return null;
                    })
                  }
                </TableRow>
              );
            })
          }
        </TableBody>
      </CssTable>
    </TableContainer>
  );
};

SearchList.propTypes = {
  headers: PropTypes.arrayOf(PropTypes.string),
  data: PropTypes.arrayOf(PropTypes.shape),
  idKey: PropTypes.string.isRequired,
};
SearchList.defaultProps = {
  headers: [],
  data: [{}],
};

export default SearchList;
