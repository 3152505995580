import React from 'react';
import PropTypes from 'prop-types';
import { Card } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';

const CHTelephoneCard = ({ phoneNumbers = [] }) => (
  <Card>
    <Table aria-label="simple table">
      <TableBody>
        {
          phoneNumbers.length === 0
            ? (
              <TableRow>
                <TableCell scope="row">
                  -
                </TableCell>
              </TableRow>
            )
            : null
        }
        {
          phoneNumbers.map((i) => (
            <TableRow key={`${i.number}-${i.type}`}>
              <TableCell scope="row" className="card-label-column">
                {i.type}
              </TableCell>
              <TableCell align="left">
                {i.number}
              </TableCell>
            </TableRow>
          ))
        }
      </TableBody>
    </Table>
  </Card>

);

CHTelephoneCard.propTypes = {
  phoneNumbers: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string,
      number: PropTypes.string,
    }),
  ),
};
CHTelephoneCard.defaultProps = {
  phoneNumbers: [{}],
};

export default CHTelephoneCard;
