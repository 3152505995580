import { createSlice } from '@reduxjs/toolkit';
import moment from 'moment';

const dataMap = [
  'SERIAL_NUM_IDENTIF',
  'ZIP_CODE_NUM',
  'FIRST_NAME',
  'MID_NAME',
  'LAST_NAME',
  'DOB',
  'CURRNT_STAT',
  'shouldShowButton',
  'CASE_ID',
];

/* eslint-disable no-param-reassign */
export const searchSlice = createSlice({
  name: 'search',
  initialState: {
    loaded: false,
    loading: false,
    searchError: false,
    data: [],
    tooManyResult: false,
    headers:
      [
        'Case Number',
        'Physical Zip Code',
        'First Name',
        'M. I.',
        'Last Name',
        'Parent/Caretaker DOB',
        'CalWORKs Status',
        '',
      ],
  },
  reducers: {
    initSearch: (state) => {
      state.loaded = false;
      state.loading = true;
      state.searchError = false;
      state.searchResultsIsEmpty = false;
      state.tooManyResult = false;
    },
    loadSearch: (state, action) => {
      state.loaded = true;
      state.loading = false;
      state.searchError = false;
      state.searchResultsIsEmpty = action.payload?.length === 0;
      state.tooManyResult = action.payload?.length === 21;
      state.data = action.payload?.map((obj) => dataMap.reduce((transform, el) => {
        let value;
        switch (el) {
          case 'shouldShowButton':
            value = '';
            break;
          case 'DOB':
            value = moment(obj[el], 'MM/DD/YYYY').format('MM/DD/YYYY');
            break;
          case 'MID_NAME':
            value = obj[el] === null ? '-' : obj[el];
            break;
          default:
            value = obj[el];
            break;
        }
        transform[el] = value;
        return transform;
      }, {}));
      state.programPersonIdTable = action.payload?.reduce((transform, el) => {
        transform[el.CASE_ID] = {};
        transform[el.CASE_ID].programId = el.PGM_ID;
        transform[el.CASE_ID].programPersonId = el.PGM_PERS_ID;
        transform[el.CASE_ID].serialNumIdentif = el.SERIAL_NUM_IDENTIF;
        return transform;
      }, {});
    },
    searchError: (state) => {
      state.searchError = true;
    },
  },
});

export const {
  initSearch, loadSearch, searchError,
} = searchSlice.actions;

export const loadedSelector = (state) => state.search.loaded;
export const searchIsEmptySelector = (state) => state.search.searchResultsIsEmpty;
export const searchErrorSelector = (state) => state.search.searchError;
export const searchHasTooMany = (state) => state.search.tooManyResult;
export const loadingSelector = (state) => state.search.loading;
export const dataSelector = (state) => state.search.data;
export const getProgramPersonIdTable = (state) => state.search.programPersonIdTable;
export const headerSelector = (state) => state.search.headers;

export default searchSlice.reducer;
