import { createSlice } from '@reduxjs/toolkit';
import {
  apiParamCapture,
  errorHandling, formChecker, resetFormChecker,
} from './shared-reducers';

const searchByCaseParameters = ['caseNum', 'countyCode'];
const searchByNameParameters = ['firstName', 'middleInitial', 'lastName', 'caseNumber', 'zipCode', 'dob'];

/* eslint-disable no-param-reassign */
export const searchCriteriaSlice = createSlice({
  name: 'searchCriteria',
  initialState: {
    values: {},
    formHasError: false,
    form: [],
    checkingForm: false,
    formSearchMethod: 'searchByName',
  },
  reducers: {
    handleValues: (state, action) => {
      if (state.formSearchMethod === 'searchByCase' && searchByCaseParameters.includes(action.payload.id)) {
        apiParamCapture(state, action);
        // rename the caseNum parameter and include the search by method value in the criteria payload
        const { caseNum, caseNumber, countyCode } = state.values;
        const caseNumberParameter = caseNum || caseNumber;
        state.values = {
          caseNumber: caseNumberParameter,
          countyCode,
          searchMethod: state.formSearchMethod,
        };
      }
      else if (state.formSearchMethod === 'searchByName' && searchByNameParameters.includes(action.payload.id)) {
        apiParamCapture(state, action);
        // include the search by method value in the criteria payload
        state.values = { ...state.values, searchMethod: state.formSearchMethod };
      }
    },
    handlePossibleFormErrors: errorHandling,
    attemptedFormSubmission: formChecker,
    resetSCFormChecker: resetFormChecker,
    setFormSearchMethod: (state, action) => {
      state.formSearchMethod = action.payload;
      state.form = [];
    },
  },
});

export const {
  handleValues, handlePossibleFormErrors, attemptedFormSubmission, resetSCFormChecker, setFormSearchMethod,
} = searchCriteriaSlice.actions;

export const searchCriteriaSelector = (state) => state.searchCriteria.values;
export const formErrorChecker = (state) => state.searchCriteria.formHasError;
export const getFormErrorTable = (state) => state.searchCriteria.form;
export const isFormBeingSubmitted = (state) => state.searchCriteria.checkingForm;
export const getFormSearchMethod = (state) => state.searchCriteria.formSearchMethod;

export default searchCriteriaSlice.reducer;
