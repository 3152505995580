import React from 'react';
import '../App.css';
import logo from '../assets/too-many-results.png';

const TooManyFound = () => {
  const tooMany = 'Let\'s narrow this down.';
  const secondPart = 'Try entering additional search criteria.';
  return (
    <section className="start-search">
      <h1> Too many results!   </h1>
      <img title="Too Many Results" height={500} alt={tooMany} src={logo} />
      <p>{tooMany}</p>
      <p>{secondPart}</p>
    </section>
  );
};

export default TooManyFound;
