import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { loggedInSelector, awaitLoginSelector } from '../store/AppState.slice';

function RouteWithSubRoutes({
  path, Artifact, redirect, privateRoute, routes, profile,
}) {
  const loggedIn = useSelector(loggedInSelector);
  const loginInitiated = useSelector(awaitLoginSelector);

  return (
    <Route
      path={path}
      profile={profile}
      performance={performance}
      render={(props) => {
        // redirect is happening
        if (Artifact === null) {
          window.location.replace(redirect);
          return null;
        }
        // public route
        if (privateRoute === false) {
          return <Artifact {...props} routes={routes} />;
        }
        // private route protected by login
        return loggedIn === true || loginInitiated === true
          ? <Artifact performance={performance} {...props} routes={routes} />
          : (
            <Redirect
              to={{
                // TODO: change to /redirect once forgerock is up
                pathname: '/redirect',
                state: { from: props.location },
              }}
            />
          );
      }}
    />
  );
}
RouteWithSubRoutes.propTypes = {
  location: PropTypes.shape({
    hash: PropTypes.string,
    key: PropTypes.string,
    pathname: PropTypes.string,
    search: PropTypes.string,
    state: PropTypes.string,
  }),
  path: PropTypes.string.isRequired,
  Artifact: PropTypes.func,
  redirect: PropTypes.string,
  privateRoute: PropTypes.bool,
  routes: PropTypes.shape({
    route: PropTypes.string,
  }),
  profile: PropTypes.shape({ sub: PropTypes.string }),
};

RouteWithSubRoutes.defaultProps = {
  location: {},
  routes: { route: null },
  profile: {},
  redirect: null,
  Artifact: null,
  privateRoute: false,
};

export default RouteWithSubRoutes;
