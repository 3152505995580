import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import routes from './PortalRouter.config';
import RouteWithSubRoutes from './RouteWithSubRoutes';
import CHAppBar from '../shared-components/CHAppBar';
import {
  goAwayReportIssueMessage,
  didReportIssueFail,
  wasReportIssueSuccessful,
} from '../store/ReportIssue.slice';
import {
  profileLoadingSelector,
  profileLoadedSelector,
} from '../store/Profile.slice';
import { getUser, hasPortalRoles } from '../store/AppState.slice';
import UnauthorizedUser from '../components/UnauthorizedUserModal';

const PortalRouter = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [isOpen, setOpen] = useState(true);
  const [bypassUserRole, setBypass] = useState(false);

  const profileIsLoading = useSelector(profileLoadingSelector);
  const profileHasLoaded = useSelector(profileLoadedSelector);
  const signedUser = useSelector(getUser);
  const userHasRoles = useSelector(hasPortalRoles);

  const reportSuccess = useSelector(wasReportIssueSuccessful);
  const reportFailed = useSelector(didReportIssueFail);

  const handleClose = () => {
    dispatch(goAwayReportIssueMessage());
  };
  const handleUnauthorizedClose = () => {
    setOpen(false);
    setBypass(true);
    history.push('/unauthorized');
  };

  return (
    <>
      <nav className="nav-wrapper">
        <CHAppBar
          hasLoaded={profileHasLoaded}
          isLoading={profileIsLoading}
          profile={signedUser}
        />
      </nav>
      {
        userHasRoles === false && bypassUserRole === false
          ? <UnauthorizedUser isOpen={isOpen} handleClose={handleUnauthorizedClose} />
          : (
            routes.map((route) => <RouteWithSubRoutes key={route.path} {...route} />)
          )
      }
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={reportSuccess}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <MuiAlert onClose={handleClose} severity="success">
          Thank you for reporting your issue. We will follow up soon.
        </MuiAlert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={reportFailed}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <MuiAlert onClose={handleClose} severity="error">
          There was an error reporting your issue. Please try again later.
        </MuiAlert>
      </Snackbar>
    </>
  );
};

PortalRouter.propTypes = {
  startLogout: PropTypes.func.isRequired,
};

export default PortalRouter;
