import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import Select from '@mui/material/Select';
import { styled } from '@mui/material/styles';
import validate from '../formValidationHelper';
import { resetSCFormChecker } from '../store/SearchCriteria.slice';

const CssSelect = styled(Select)({
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: 'rgb(40, 72, 141)',
  },
  '& .MuiOutlinedInput-input': {
    '&.Mui-error': {
      color: '#b71c1c',
      fontWeight: 400,
    },
  },
});

export default function BasicSelect({
  // eslint-disable-next-line react/prop-types
  id, isRequired, handler, validation, errorHandler, helperText, formSubmitting, sx, hidden,
}) {
  const dispatch = useDispatch();
  const [countyCode, setCountyCode] = useState('0');
  const [value, setValue] = useState({ required: isRequired, id, text: '' });
  const [pristine, setPristine] = useState(true);
  const [hasError, setError] = useState(false);
  const [message, setMessage] = useState(helperText);

  const handleChange = (e) => {
    const newValue = { ...value, text: e.target.value, hidden };
    const hasSpecialValidationError = validate(newValue, validation, hidden);
    setCountyCode(e.target.value);
    handler({
      required: isRequired, id, text: e.target.value, hidden,
    });
    errorHandler({ value: e.target.value, pristine, hidden });
    setError(hasSpecialValidationError !== null);
    newValue.hasError = hasSpecialValidationError !== null;
    setMessage(hasSpecialValidationError !== null ? hasSpecialValidationError.message : helperText);
    setValue(newValue);
    setPristine(false);
  };

  useEffect(() => {
    handler(value);
    errorHandler({ value, pristine, hidden });
    if (formSubmitting) {
      const hasSpecialValidationError = validate(value, validation, hidden);
      setError(hasSpecialValidationError !== null);
      setMessage(hasSpecialValidationError !== null ? hasSpecialValidationError.message : helperText);
      dispatch(resetSCFormChecker());
    }
  }, [value, handler, errorHandler, pristine, formSubmitting, dispatch, helperText, validation, hidden]);

  return (
    <FormControl fullWidth sx={sx} error={hasError}>
      <CssSelect
        id={id}
        value={countyCode}
        error={hasError}
        onChange={handleChange}
        sx={sx}
        hidden={hidden}
      >
        <MenuItem value="0">-Select County-</MenuItem>
        <MenuItem value="01">01 - Alameda</MenuItem>
        <MenuItem value="02">02 - Alpine</MenuItem>
        <MenuItem value="03">03 - Amador</MenuItem>
        <MenuItem value="04">04 - Butte</MenuItem>
        <MenuItem value="05">05 - Calaveras</MenuItem>
        <MenuItem value="06">06 - Colusa</MenuItem>
        <MenuItem value="07">07 - Contra Costa</MenuItem>
        <MenuItem value="08">08 - Del Norte</MenuItem>
        <MenuItem value="09">09 - El Dorado</MenuItem>
        <MenuItem value="10">10 - Fresno</MenuItem>
        <MenuItem value="11">11 - Glenn</MenuItem>
        <MenuItem value="12">12 - Humboldt</MenuItem>
        <MenuItem value="13">13 - Imperial</MenuItem>
        <MenuItem value="14">14 - Inyo</MenuItem>
        <MenuItem value="15">15 - Kern</MenuItem>
        <MenuItem value="16">16 - Kings</MenuItem>
        <MenuItem value="17">17 - Lake</MenuItem>
        <MenuItem value="18">18 - Lassen</MenuItem>
        <MenuItem value="19">19 - Los Angeles</MenuItem>
        <MenuItem value="20">20 - Madera</MenuItem>
        <MenuItem value="21">21 - Marin</MenuItem>
        <MenuItem value="22">22 - Mariposa </MenuItem>
        <MenuItem value="23">23 - Mendocino</MenuItem>
        <MenuItem value="24">24 - Merced</MenuItem>
        <MenuItem value="25">25 - Modoc</MenuItem>
        <MenuItem value="26">26 - Mono</MenuItem>
        <MenuItem value="27">27 - Monterey</MenuItem>
        <MenuItem value="28">28 - Napa</MenuItem>
        <MenuItem value="29">29 - Nevada</MenuItem>
        <MenuItem value="30">30 - Orange</MenuItem>
        <MenuItem value="31">31 - Placer</MenuItem>
        <MenuItem value="32">32 - Plumas</MenuItem>
        <MenuItem value="33">33 - Riverside</MenuItem>
        <MenuItem value="34">34 - Sacramento</MenuItem>
        <MenuItem value="35">35 - San Benito</MenuItem>
        <MenuItem value="36">36 - San Bernardino</MenuItem>
        <MenuItem value="37">37 - San Diego</MenuItem>
        <MenuItem value="38">38 - San Francisco</MenuItem>
        <MenuItem value="39">39 - San Joaquin</MenuItem>
        <MenuItem value="40">40 - San Luis Obispo</MenuItem>
        <MenuItem value="41">41 - San Mateo</MenuItem>
        <MenuItem value="42">42 - Santa Barbara</MenuItem>
        <MenuItem value="43">43 - Santa Clara</MenuItem>
        <MenuItem value="44">44 - Santa Cruz</MenuItem>
        <MenuItem value="45">45 - Shasta</MenuItem>
        <MenuItem value="46">46 - Sierra</MenuItem>
        <MenuItem value="47">47 - Siskiyou</MenuItem>
        <MenuItem value="48">48 - Solano</MenuItem>
        <MenuItem value="49">49 - Sonoma</MenuItem>
        <MenuItem value="50">50 - Stanislaus</MenuItem>
        <MenuItem value="51">51 - Sutter</MenuItem>
        <MenuItem value="52">52 - Tehama</MenuItem>
        <MenuItem value="53">53 - Trinity</MenuItem>
        <MenuItem value="54">54 - Tulare</MenuItem>
        <MenuItem value="55">55 - Tuolumne</MenuItem>
        <MenuItem value="56">56 - Ventura</MenuItem>
        <MenuItem value="57">57 - Yolo</MenuItem>
        <MenuItem value="58">58 - Yuba</MenuItem>
      </CssSelect>
      <FormHelperText style={{ color: '#b71c1c' }}>{message}</FormHelperText>
    </FormControl>
  );
}
