import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
  },
}));

const CHVideoCard = ({ src, title }) => {
  const classes = useStyles();

  return (
    <Card className={classes.root}>
      <CardMedia
        component="video"
        autoPlay
        height="100%"
        width="100%"
        controls
        src={src}
        title={title}
      />
    </Card>
  );
};

CHVideoCard.propTypes = {
  src: PropTypes.string.isRequired,
  title: PropTypes.string,
};

CHVideoCard.defaultProps = {
  title: '',
};

export default CHVideoCard;
