/* eslint-disable */
import moment from 'moment';
import { createSlice } from '@reduxjs/toolkit';
import data from '../mock-data/example';

export const caseDetailsSlice = createSlice({
  name: 'caseDetails',
  initialState: {
    caseDetails: {
      parents: [{}],
      income: [{}],
      memberInfo: [{}],
      rates: {},
      children: [{}],
    },
    loading: false,
    error: false,
    loaded: false,
  },
  reducers: {
    initCaseDetails: (state) => {
      state.loading = true;
      state.loaded = false;
    },
    loadCaseDetails: (state, action) => {
      state.error = false
      const parents = action.payload.parents.length > 0 ? action.payload.parents : [{}];
      const uniqueParents = getUniqueParents(parents, action.payload.employment);
      const formattedParents = uniqueParents.map((parent) => getParentInfo(parent));
      const children = action.payload.children.length > 0 ? action.payload.children : [{}];
      const groupedChildren = getGroupedChildrenData(children);
      const formattedChildrenArray = getFormattedChildren(groupedChildren.uniqueChildrenMap, groupedChildren.uniqueChildrenProviderMap, groupedChildren.uniqueChildrenProviderRatesMap);
      const formattedIncomeandFamily = action.payload.income.reduce((transform, child) => {
        transform = { ...getIncomeAndFamilyBreakdownInfo(child) };
        return transform;
      }, {});
      const formattedMemberInfo = action.payload.memberInfo.map((member) => getMemberInfo(member));
      state.caseDetails = {
        ...state.caseDetails,
        parents: formattedParents,
        children: formattedChildrenArray,
        ...formattedIncomeandFamily,
        cwStatus: formattedParents[0] && formattedParents[0].cwStatus || '-',
        memberInfo: formattedMemberInfo.length > 0 ? formattedMemberInfo : getMemberInfo({}, true),
        cwPgmClosureDate: formattedParents[0] && formattedParents[0].cwPgmClosureDate || '-',
        lastAidedAdultDate: formattedParents[0] && formattedParents[0].lastAidedAdultDate || '-',
      };
      state.loading = false;
      state.loaded = true;
    },
    errorLoadingCaseDetails: (state) => {
      state.error = true;
      state.loading = false;
      state.loaded = false;
    },
    cleanup: (state) => {
      state.error = false;
      state.loading = false;
      state.loaded = false;
      state.caseDetails = {};
    },
  },
});

export const {
  initCaseDetails, loadCaseDetails, errorLoadingCaseDetails, cleanup,
} = caseDetailsSlice.actions;

export const areCaseDetailsLoading = (state) => state.caseDetails.loading;
export const areCaseDetailsLoaded = (state) => state.caseDetails.loaded;
export const getCaseDetails = (state) => state.caseDetails.caseDetails;
export const caseDetailsHasError = (state) => state.caseDetails.error;

export default caseDetailsSlice.reducer;

/*
  functions below are being used primarily to alias the repoonse names coming from the backend so that
  if there's any changes to property names, or formating of the reponse it should have minimal affect
  on the front-end page
  ideally this will result in only needing to update the names on the left hand side
*/
function formatNumber(num) {
  if (!num) return '-';
  if (typeof num === 'string') {
    num = num.split(',')[0].trim();
  }
  if (typeof num === 'number') {
    num = num.toString();
  }
  const numLength = num.length;
  if (numLength === 10) {
    const first = num.substring(0, 3);
    const second = num.substring(3, 6);
    const last = num.substring(6);
    return `(${first})${second}-${last}`;
  }
  if (numLength === 11) {
    const prefix = num.substring(0, 1);
    const first = num.substring(1, 4);
    const second = num.substring(4, 7);
    const last = num.substring(7);
    return `+${prefix}(${first})${second}-${last}`;
  }
  return null;
}

function getMemberInfo(obj, isEmpty = false) {
  const {
    FIRST_NAME: firstName,
    LAST_NAME: lastName,
    MID_NAME: middleName,
  } = obj;

  if (isEmpty === true) {
    return [{
      firstName: '-',
      lastName: '-',
      middleName: '-',
    }];
  }
  return {
    firstName,
    lastName,
    middleName,
  };
}

function getIncomeAndFamilyBreakdownInfo(obj) {
  const {
    ADJ_MONTHLY_INC: adjustedMonthlyIncome,
    ADULT_CNT: adultCount,
    CHILD_CNT: childCount,
    INCOME_SOURCE: incomeSources,
    // PGM_ID: programId, not used or needed but keeping for posterity,
  } = obj;

  return {
    incomeSources: incomeSources && incomeSources.split(';') || ['-'],
    adjustedMonthlyIncome,
    familyBreakdown: {
      adultCount,
      childCount,
      totalCount: adultCount + childCount,
    },
  };
}

function getParentInfo(obj) {
  const {
    'First Name': firstName,
    'Last Name': lastName,
    'Middle Initial': middleInitial,
    DATE_OF_BIRTH: dob,
    'Last Active Month of CW': lastActiveMonthCW,
    'Reasons for needing child care': reasonsNeedingChildCare,
    EMAIL: email,
    MAILING_ADDR: mailingAddress,
    PHYSICAL_ADDR: physicalAddress,
    CELL_TEL: cell,
    FAX_TEL: fax,
    HOME_TEL: home,
    MAIN_TEL: main,
    MSG_TEL: msg,
    TDD_TEL: tdd,
    TOLL_FREE_TEL: tollFree,
    WRK_TEL: work,
    'Case Number': caseNumber,
    BEG_DATE: beginData,
    CASE_PERS_ID: casePersonId,
    CC_PGM_PERS_ID: CaseProgramPersonId,
    'CW Status': cwStatus,
    'Discontinued Date': discontinuedDate,
    LAST_AIDED_ADULT: lastAidedAdultDate,
    END_DATE: endDate,
    CW_PGM_CLOSURE_DATE: cwPgmClosureDate,
    CW_PERS_STAT: cwPersStat,
    CW_PERS_STAT_REASON: cwPersStatRsn,
    CW_LAST_AIDED_MONTH: cwLastAidedMonth,
    CW_PERS_ROLE: cwPersRole,
    CW_PERS_ROLE_REASON: cwPersRoleRsn,
    // EMPLOYER INFORMATION
    ORG_NAME: orgName,
    ORG_ADDRESS: orgAddress,
    WKDAY: workDays,
    START_TIME: startTime,
    STOP_TIME: stopTime,
    employment: employment,
  } = obj;
  const phoneNumbers = [
    { type: 'Cell', number: formatNumber(cell) },
    { type: 'Fax', number: formatNumber(fax) },
    { type: 'Home', number: formatNumber(home) },
    { type: 'Main', number: formatNumber(main) },
    { type: 'Message', number: formatNumber(msg) },
    { type: 'TDD', number: formatNumber(tdd) },
    { type: 'Toll Free', number: formatNumber(tollFree) },
    { type: 'Work', number: formatNumber(work) },
  ];

  const hyphenMailingAddress = insertHyphen(mailingAddress);
  const hyphenPhysicalAddress = insertHyphen(physicalAddress);

  return {
    firstName,
    lastName,
    middleInitial,
    dob: dob ? moment(dob, 'MM/DD/YYYY').format('MM/DD/YYYY') : '-',
    lastActiveMonthCW: lastActiveMonthCW ? moment(lastActiveMonthCW, 'MM/DD/YYYY').format('MMMM YYYY') : '-',
    reasonsNeedingChildCare,
    email,
    mailingAddress: /^ *$/.test(hyphenMailingAddress) === true ? null : hyphenMailingAddress,
    physicalAddress: /^ *$/.test(hyphenPhysicalAddress) === true ? null : hyphenPhysicalAddress,
    caseNumber,
    lastAidedAdultDate: lastAidedAdultDate ? moment(lastAidedAdultDate, 'MM/DD/YYYY').format('MM/DD/YYYY') : '-',
    cwStatus,
    employerInformation: employment,
    phoneNumbers: [...phoneNumbers],
    cwPgmClosureDate: cwPgmClosureDate ? moment(cwPgmClosureDate, 'MM/DD/YYYY').format('MM/DD/YYYY') : '-',
    cwLastAidedMonth,
    cwPersRole,
    cwPersRoleRsn,
    cwPersStat: !discontinuedDate ? cwPersStat : `${cwPersStat} on ${moment(discontinuedDate, 'MM/DD/YYYY').format('MM/DD/YYYY')}`,
    cwPersStatRsn,
  };
}

/* This function takes the maps of children, providers, and rates and returns an array 
 * 
 */
function getFormattedChildren(uniqueChildrenMap, uniqueChildrenProviderMap, uniqueChildrenProviderRatesMap) {
  const childArray = new Array();
  if (uniqueChildrenMap) {
    // loop through each child and set on the return array
    uniqueChildrenMap.forEach((child, childKey) => {
      if (childKey) {
        childArray.push(
          {
            firstName: child.firstName,
            middleInitial: child.middleInitial,
            lastName: child.lastName,
            dob: child.dob ? moment(child.dob, 'MM/DD/YYYY').format('MM/DD/YYYY') : '-',
            weeklyHours: child.weeklyHours ? Math.round(child.weeklyHours * 100) / 100 : null,
            personStatus: !child.discontinuedDate ? child.personStatus : `${child.personStatus} on ${moment(child.discontinuedDate, 'MM/DD/YYYY').format('MM/DD/YYYY')}`,
            providerInfo: getChildProviderArray(childKey, uniqueChildrenProviderMap, uniqueChildrenProviderRatesMap),
            serialIdentificationNumber: child.serialIdentificationNumber,
          }
        )
      }
    });
  }
  return childArray;
}

function getChildProviderArray(childId, uniqueChildrenProviderMap, uniqueChildrenProviderRatesMap) {
  const providerArray = new Array();

  // loop through each childProvider entry looking for the ones for this child
  uniqueChildrenProviderMap.forEach((provider, childProviderKey) => {

    if (provider.childKey == childId) {
      // add each provider to an array to return for this child
      providerArray.push({ ...provider, rates: uniqueChildrenProviderRatesMap.get(childProviderKey) });
    }

  });


  return providerArray;
}

function getUniqueParents(parents, employment = {}) {
  const dictionary = {};
  for (let i = 0; i < parents.length; i++) {
    const key = parents[i].PERS_ID;
    dictionary[key] = parents[i];
  }
  return Object.keys(dictionary).map((el) => {
    if (!employment[el]) {
      dictionary[el].employment = [{
        ORG_NAME: '',
        ORG_ADDRESS: '',
        WKDAY: '',
        START_TIME: '',
        STOP_TIME: '',
      }];
    }
    else {
      dictionary[el].employment = weekDaysSorting(employment[el]);
    }
    return dictionary[el];
  }).sort(orderParents);
};

function orderParents(a, b) {
  const dobA = moment(a.DATE_OF_BIRTH, 'MM/DD/YYYY').valueOf();
  const dobB = moment(b.DATE_OF_BIRTH, 'MM/DD/YYYY').valueOf();
  const difference = dobA - dobB;
  if (difference === 0) {
    let aName = `${a['First Name']} ${a['Last Name']}`;
    let bName = `${b['First Name']} ${b['Last Name']}`;
    return aName.localeCompare(bName);
  }
  return difference;
}

function weekDaysSorting(employmentInfo) {
  const workDayTable = {
    Mon: 1,
    Tue: 2,
    Wed: 3,
    Thu: 4,
    Fri: 5,
    Sat: 6,
    Sun: 7,
  };
  let infos = [];
  for (let i = 0; i < employmentInfo.length; i++) {
    let info = { ...employmentInfo[i] };
    if (typeof info.WKDAY === 'string') {
      info.WKDAY = info.WKDAY
        .split(' ')
        .map((day) => {
          const obj = {};
          obj.label = day;
          obj.num = workDayTable[day];
          return obj;
        }).sort(({ num: a }, { num: b }) => a - b)
        .map((days) => days.label)
        .join(' ');
    }
    else {
      infos.WKDAY = '-';
    }
    infos.push(info);
  }
  return infos;
}

/* This function loops through each Child element - each element is a rate for a provider for a child
 * 1) returns unique Child objects
 * 2) extracts and formats an array of Providers per Child (may empty)
 * 3) extracts and formats an array of Rates per Provider for the Child (may be empty)
 */
function getGroupedChildrenData(children) {

  let latestChildPgmPersId;
  let latestAuthId;

  // return an object containing three maps

  // this map contains child objects with the childPgmPersId as key
  let uniqueChildrenMap = new Map();
  // this map contains provider objects with the childPgmPersId|orgId as key
  let uniqueChildrenProviderMap = new Map();
  // this map contains an array of rate objects with the childPgmPersId|orgId as key
  let uniqueChildrenProviderRatesMap = new Map();

  for (let i = 0; i < children.length; i++) {
    // capture the child object elements and format
    const {
      CH_PERS_FNAME: firstName,
      CH_PERS_MID_NAME: middleInitial,
      CH_PERS_LNAME: lastName,
      CH_PERS_DOB: dob,
      WEEK_HRS: weeklyHours,
      PERSON_STATUS: personStatus,
      DISC_DATE: discontinuedDate,
      // provider info
      PROVIDER: providerName,
      ORG_ID: providerId,
      TYPE_ELGBL_PROVIDER: providerType,
      MAILING_ADDR: mailingAddress,
      PHYSICAL_ADDR: physicalAddress,
      // phone numbers
      CELL_TEL: cell,
      FAX_TEL: fax,
      HOME_TEL: home,
      MAIN_TEL: main,
      MSG_TEL: msg,
      TDD_TEL: tdd,
      TOLL_FREE_TEL: tollFree,
      WRK_TEL: work,
      rates: rates,
      SERIAL_NUM_IDENTIF: serialIdentificationNumber,
    } = children[i];
    const phoneNumbers = [
      { type: 'Cell', number: formatNumber(cell) },
      { type: 'Fax', number: formatNumber(fax) },
      { type: 'Home', number: formatNumber(home) },
      { type: 'Main', number: formatNumber(main) },
      { type: 'Message', number: formatNumber(msg) },
      { type: 'TDD', number: formatNumber(tdd) },
      { type: 'Toll Free', number: formatNumber(tollFree) },
      { type: 'Work', number: formatNumber(work) },
    ];
    const hyphenMailingAddress = insertHyphen(mailingAddress);
    const hyphenPhysicalAddress = insertHyphen(physicalAddress);

    // initialize the child and provider keys
    const childKey = children[i].PERS_ID;
    const childProviderKey = `${children[i].PERS_ID}|${children[i].ORG_ID}`;

    // if the child map does not already have this child id, then create the object and add to the map
    if (!uniqueChildrenMap.has(childKey)) {
      // set the child object data
      uniqueChildrenMap.set(childKey, {
        firstName: children[i].CH_PERS_FNAME,
        middleInitial: children[i].CH_PERS_MID_NAME,
        lastName: children[i].CH_PERS_LNAME,
        dob: children[i].CH_PERS_DOB,
        weeklyHours: children[i].WEEK_HRS,
        personStatus: children[i].PERSON_STATUS,
        discontinuedDate: children[i].DISC_DATE
      });
    }
    else {
      // accumulate the hours for this same child by provider/schedule
      if (latestChildPgmPersId == childKey &&
        latestAuthId != children[i].CC_AUTH_ID + '|' + children[i].SCHED_TYPE) {
        uniqueChildrenMap.get(childKey).weeklyHours += children[i].WEEK_HRS;
      }
    }

    // storing this iteration of child and auth id for next loop
    latestChildPgmPersId = childKey;
    latestAuthId = children[i].CC_AUTH_ID + '|' + children[i].SCHED_TYPE;

    // set the default rate placeholder array
    let rate = {
      scheduleType: '-',
      careType: '-',
      timeType: '-',
      ageGroup: '-',
      rateOfPayment: '-'
    };

    // if the provider is null for this row, skip the provider/rate data
    if (children[i].ORG_ID) {

      // if the provider map does not already have this child/provider, then create the object and add to the map
      if (!uniqueChildrenProviderMap.has(childProviderKey)) {
        uniqueChildrenProviderMap.set(childProviderKey, {
          // set the provider object data
          childKey,
          providerName,
          providerId,
          providerType: /^ *$/.test(providerType) === true ? null : providerType,
          mailing: /^ *$/.test(hyphenMailingAddress) === true ? null : hyphenMailingAddress,
          physical: /^ *$/.test(hyphenPhysicalAddress) === true ? null : hyphenPhysicalAddress,
          phoneNumbers: [...phoneNumbers]
        });
      }

      // if the rate data is valid, populate the data elements
      if (children[i].CARE_TYPE) {
        rate = {
          scheduleType: children[i].SCHED_TYPE,
          careType: children[i].CARE_TYPE,
          timeType: children[i].TIME_TYPE,
          ageGroup: children[i].AGE_TYPE,
          rateOfPayment: children[i].RATE_PAYMT,
        }
      };

      // if the rate map does not already have this child/provider, then create the object and add to the map
      if (!uniqueChildrenProviderRatesMap.has(childProviderKey)) {
        // create a new rate array
        uniqueChildrenProviderRatesMap.set(childProviderKey, [rate]);
      }
      else {
        // get the array to append to
        uniqueChildrenProviderRatesMap.get(childProviderKey).push(rate);
      }
    }
    else {
      // populate a default provider and rate block as a placeholder
      uniqueChildrenProviderMap.set(childProviderKey, {
        // set the provider object data
        childKey,
        providerName,
        providerId,
        providerType: /^ *$/.test(providerType) === true ? null : providerType,
        mailing: /^ *$/.test(hyphenMailingAddress) === true ? null : hyphenMailingAddress,
        physical: /^ *$/.test(hyphenPhysicalAddress) === true ? null : hyphenPhysicalAddress,
        phoneNumbers: [...phoneNumbers]
      });

      // set the rate array
      uniqueChildrenProviderRatesMap.set(childProviderKey, [rate]);
    }
  }

  return { uniqueChildrenMap, uniqueChildrenProviderMap, uniqueChildrenProviderRatesMap };
}

function insertHyphen(add) {
  if (!add || add.length === 0) return add;
  let strArray = add.split(" ");
  let len = strArray.length;
  let toCombind = [];
  let cond = true;
  let offset = 1;
  let loops = 0;
  while (cond === true) {
    let str = strArray[len - offset];
    let strLen = str.length;
    let parsedInt = parseInt(str);
    let strTest = /[^$,.\d]/.test(str);
    if (isNaN(parsedInt) === false
      && strTest === false) {
      if (strLen < 5) {
        offset++
      }
      if (strLen === 5) {
        cond = false;
      }
      toCombind.push(str);
    }
    else {
      cond = false;
    }
    loops++
    if (toCombind.length === 0) {
      loops = 0;
    }
  }
  strArray.splice(len - loops);
  strArray.push(toCombind.reverse().join("-"))
  return strArray.join(' ');
}
