const mockData = {
  emptySearchResults: [],
  searchResults: [{
    caseNumber: 1005467,
    zipCode: 90210,
    firstName: 'John',
    middleInitial: 'K.',
    lastName: 'Doe',
    caretakerDob: '01/24/90',
    status: 'Pending',
  },
  {
    caseNumber: 1005468,
    zipCode: 90211,
    firstName: 'James',
    middleInitial: 'H.',
    lastName: 'Hewet',
    caretakerDob: '01/25/90',
    status: 'Active',
  }, {
    caseNumber: 1005469,
    zipCode: 90210,
    firstName: 'Bailey',
    middleInitial: 'V.',
    lastName: 'Bucket',
    caretakerDob: '01/24/90',
    status: 'Discontinued',
  },
  {
    caseNumber: 1005470,
    zipCode: 90210,
    firstName: 'Cambridge',
    middleInitial: 'D.',
    lastName: 'Loftly',
    caretakerDob: '01/24/90',
    status: 'Denied',
  }, {
    caseNumber: 1005471,
    zipCode: 90233,
    firstName: 'Samesely',
    middleInitial: 'A.',
    lastName: 'Brewer',
    caretakerDob: '01/24/90',
    status: 'Pending',
  }],
  profileInfo: {
    name: "Jenson McGee",
  },
  login: {
    token: "1232dw1d454rDSD#D!",
  },
  notLoggedIn: {
    token: null,
  }
};

export default mockData;
