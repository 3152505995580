const style = {
  border: '1px solid #2738A5',
  margin: '1em 0em',
  backgroundColor: '#2738A5',
  fontWeight: 600,
  color: 'white',
  '&:hover': {
    backgroundColor: '#4558d3',
    borderColor: '#2738A5',
    boxShadow: 'none',
  },

};
export const styleWithRoot = {
  root: {
    ...style,
  },
};

export default style;
