import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import RFChildNameCard from '../shared-components/RFChildNameCard';

const RFChildInfo = ({ data }) => {
  const {
    CHILD_FIRST_NAME,
    CHILD_MID_NAME,
    CHILD_LAST_NAME,
    CHILD_DOB,
  } = data;

  return (

    <section className="details-container">
      <div>
        <Grid container spacing={5}>
          <Grid item xs={6}>
            <RFChildNameCard
              first={CHILD_FIRST_NAME}
              middle={CHILD_MID_NAME}
              last={CHILD_LAST_NAME}
              dob={CHILD_DOB}
            />
          </Grid>
        </Grid>
      </div>
    </section>
  );
};

RFChildInfo.propTypes = {
  data: PropTypes.shape({
    CHILD_FIRST_NAME: PropTypes.string,
    CHILD_LAST_NAME: PropTypes.string,
    CHILD_MID_NAME: PropTypes.string,
    CHILD_DOB: PropTypes.string,
  }),
};

RFChildInfo.defaultProps = {
  data: PropTypes.shape({
    firstName: '-',
    lastName: '-',
    middleInitial: '-',
    dob: '-',
    personStatus: '-',
    weeklyHours: '-',
    providerInfo: PropTypes.arrayOf(PropTypes.shape({
      providerName: '-',
      providerId: '-',
      providerType: '-',
      mailing: '-',
      physical: '-',
      phoneNumbers: PropTypes.arrayOf(PropTypes.shape({
        type: '-',
        number: '-',
      })),
      providerRatesList: PropTypes.arrayOf(PropTypes.shape({
        scheduleType: '-',
        careType: '-',
        ageGroup: '-',
        timeType: '-',
        rateOfPayment: '-',
      })),
    })),
  }),
};

export default RFChildInfo;
