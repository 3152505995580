import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import withStyles from '@mui/styles/withStyles';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import { styleWithRoot, labelHidden } from '../styles/CHTextField.styles';
import validate from '../formValidationHelper';
import { resetSCFormChecker } from '../store/SearchCriteria.slice';

// wrapper for hard to reach css changes
const CssTextField = withStyles(styleWithRoot)(TextField);
const CssInputHiddenLabel = withStyles(labelHidden)(InputLabel);

const CHTextField = ({
  label, variant, id, type, color, icon, addAdornment,
  handler, helperText, className, maxLength, defaultValue, isRequired,
  validation, errorHandler, formSubmitting, sx, hidden,
}) => {
  const dispatch = useDispatch();
  const [pristine, setPristine] = useState(true);
  const [value, setValue] = useState({ required: isRequired, id, text: '' });
  const [hasError, setError] = useState(false);
  const [message, setMessage] = useState(helperText);

  const handleChange = (e) => {
    const newValue = { ...value, text: e.target.value, hidden };
    const hasSpecialValidationError = validate(newValue, validation, hidden);
    setError(hasSpecialValidationError !== null);
    newValue.hasError = hasSpecialValidationError !== null;
    setMessage(hasSpecialValidationError !== null ? hasSpecialValidationError.message : helperText);
    setValue(newValue);
    setPristine(false);
  };

  useEffect(() => {
    handler(value);
    errorHandler({ value, pristine, hidden });
    if (formSubmitting) {
      const hasSpecialValidationError = validate(value, validation, hidden);
      setError(hasSpecialValidationError !== null);
      setMessage(hasSpecialValidationError !== null ? hasSpecialValidationError.message : helperText);
      dispatch(resetSCFormChecker());
    }
  }, [value, handler, errorHandler, pristine, formSubmitting, dispatch, helperText, validation, hidden]);

  return (
    <>
      <CssInputHiddenLabel htmlFor={id} sx={sx}>{label}</CssInputHiddenLabel>
      <CssTextField
        id={id}
        variant={variant}
        onChange={handleChange}
        value={value.text || defaultValue}
        type={type}
        label={label}
        color={color}
        error={hasError}
        autoComplete="off"
        className={className}
        helperText={message}
        InputProps={addAdornment !== null ? {
          startAdornment: (
            <InputAdornment position={addAdornment.position}>
              {icon}
            </InputAdornment>
          ),
        } : null}
        // eslint-disable-next-line react/jsx-no-duplicate-props
        inputProps={{ maxLength }}
        sx={sx}
        hidden={hidden}
      />
    </>
  );
};

CHTextField.propTypes = {
  label: PropTypes.string.isRequired,
  variant: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  addAdornment: PropTypes.shape({ position: PropTypes.string }),
  icon: PropTypes.element,
  handler: PropTypes.func,
  helperText: PropTypes.string,
  className: PropTypes.string,
  defaultValue: PropTypes.string,
  maxLength: PropTypes.number,
  isRequired: PropTypes.bool,
  errorHandler: PropTypes.func.isRequired,
  formSubmitting: PropTypes.bool.isRequired,
  validation: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string, message: PropTypes.string, args: PropTypes.arrayOf(PropTypes.shape),
    }),
  ),
  sx: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.bool]),
    ),
    PropTypes.func,
    PropTypes.object,
  ]),
  hidden: PropTypes.bool,
};

CHTextField.defaultProps = {
  icon: null,
  addAdornment: null,
  handler: () => { },
  helperText: '',
  className: '',
  defaultValue: '',
  maxLength: 1000,
  isRequired: false,
  validation: [],
  sx: {},
  hidden: false,
};

export default CHTextField;
