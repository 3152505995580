import { createSlice } from '@reduxjs/toolkit';
import {
  apiParamCapture, errorHandling, formChecker, resetFormChecker,
} from './shared-reducers';

/* eslint-disable no-param-reassign */
export const searchCriteriaSlice = createSlice({
  name: 'referralSearchCriteria',
  initialState: {
    values: {},
    formHasError: false,
    form: [],
    checkingForm: false,
  },
  reducers: {
    handleValues: apiParamCapture,
    handlePossibleformErrors: errorHandling,
    attemptedFormSubmission: formChecker,
    resetSCFormChecker: resetFormChecker,
  },
});

export const {
  handleValues, handlePossibleformErrors, attemptedFormSubmission, resetSCFormChecker,
} = searchCriteriaSlice.actions;

export const searchCriteriaSelector = (state) => state.referralSearchCriteria.values;
export const formErrorChecker = (state) => state.referralSearchCriteria.formHasError;
export const getFormErrorTable = (state) => state.referralSearchCriteria.form;
export const isFormBeingSubmitted = (state) => state.referralSearchCriteria.checkingForm;

export default searchCriteriaSlice.reducer;
