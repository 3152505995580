import { createSlice } from '@reduxjs/toolkit';
import moment from 'moment';
import { childCareRoles, referralViewRoles, referralEditRoles } from './storeStaticData';

/* eslint-disable no-param-reassign */
export const appStateSlice = createSlice({
  name: 'appState',
  initialState: {
    loggedIn: false,
    isWaitingForLogin: true,
    accessToken: null,
    intervalID: null,
    user: {},
    userHasPortalRoles: true,
    userHasChildCareRole: false,
    userHasReferralRole: false,
    isWalkthrough: false,
    months: [],
    showReportAnIssueButton: false,
    showHelpButton: false,
    showLogoutButton: false,
    isWaitingForCSRFToken: false,
    csrfToken: null,
    refreshCounter: 0,
  },
  reducers: {
    initLogin: (state) => {
      state.isWaitingForLogin = true;
    },
    notLoggedIn: (state) => {
      state.isWaitingForLogin = false;
    },
    login: (state, action) => {
      const token = JSON.parse(atob(action.payload.idToken.split('.')[1]));
      state.loggedIn = true;
      state.isWaitingForLogin = false;
      state.userRoles = action.payload.profile.roles;
      state.userHasChildCareRole = token.roles && token.roles.some((el) => childCareRoles.indexOf(el) > -1);
      state.userHasReferralViewRole = token.roles && token.roles.some((el) => referralViewRoles.indexOf(el) > -1);
      state.userHasReferralEditRole = token.roles && token.roles.some((el) => referralEditRoles.indexOf(el) > -1);
      state.userHasReferralRole = (state.userHasReferralViewRole || state.userHasReferralEditRole);
      state.userHasPortalRoles = (state.userHasChildCareRole
        || state.userHasReferralViewRole
        || state.userHasReferralEditRole);
      state.accessToken = action.payload.accessToken;
      state.user = token;
      state.refreshToken = action.payload.refreshToken;
      state.opsToken = action.payload.opsToken;
    },
    initCSRFToken: (state) => {
      state.isWaitingForCSRFToken = true;
    },
    setCSRFToken: (state, action) => {
      state.csrfToken = action.payload.csrfToken;
      state.isWaitingForCSRFToken = false;
    },
    refresh: (state, action) => {
      state.accessToken = action.payload.accessToken;
      state.idToken = action.payload.idToken;
      state.refreshToken = action.payload.refreshToken;
      state.refreshCounter++;
    },
    logout: (state) => {
      state.loggedIn = false;
      state.isWaitingForLogin = false;
      state.accessToken = null;
      state.user = null;
      state.refreshToken = null;
      state.csrfToken = null;
      sessionStorage.clear();
    },
    logoutWarning: (state) => {
      state.logoutWarning = true;
    },
    resetLogoutWarning: (state) => {
      state.logoutWarning = false;
    },
    startWalkthrough: (state) => {
      state.isWalkthrough = true;
    },
    endWalkthrough: (state) => {
      state.isWalkthrough = false;
    },
    hideReportAnIssueButton: (state) => {
      state.showReportAnIssueButton = false;
    },
    hideHelpButton: (state) => {
      state.showHelpButton = false;
    },
    hideLogoutButton: (state) => {
      state.showLogoutButton = false;
    },
    showReportAnIssueButton: (state) => {
      state.showReportAnIssueButton = true;
    },
    showHelpButton: (state) => {
      state.showHelpButton = true;
    },
    showLogoutButton: (state) => {
      state.showLogoutButton = true;
    },

    generateMonths: (state) => {
      const paramDate = process.env.REACT_APP_MONTHLY_REPORT_START;
      const date = /\d{1,2}\/\d{1,2}\/\d{4}/.test(paramDate) ? new Date(paramDate) : new Date();
      const todayStrFormat = moment(date, 'L');
      const today = moment(todayStrFormat, 'L');
      const jan = moment('01/01/2021', 'L');
      const feb = moment('02/01/2021', 'L');
      const mar = moment('03/01/2021', 'L');
      let months = [];

      let monthLimit = paramDate === 'off' && today.isBefore(jan) ? 0 : 3;

      if (today.isSameOrAfter(jan)) {
        monthLimit = 1;
      }
      if (today.isSameOrAfter(feb)) {
        monthLimit = 2;
      }
      if (today.isSameOrAfter(mar)) {
        monthLimit = 3;
      }

      for (let i = 0; i < monthLimit; i++) {
        const thisDay = moment(date, 'L');
        const todayInLopop = moment(thisDay, 'L');
        const m = todayInLopop.subtract(i, 'months');
        months.push({
          word: m.format('MMM YYYY'),
          number: m.format('M-yyyy'),
        });
      }
      if (months.length === 0) {
        months = 'notTime';
      }
      state.months = months;
    },
  },
});

export const {
  login, logout, initLogin, notLoggedIn, refresh, setIntervalID,
  logoutWarning, resetLogoutWarning, startWalkthrough, endWalkthrough, generateMonths,
  hideReportAnIssueButton, hideHelpButton, hideLogoutButton,
  showReportAnIssueButton, showHelpButton, showLogoutButton, setCSRFToken, initCSRFToken,
} = appStateSlice.actions;

export const loggedInSelector = (state) => state.appState.loggedIn;
export const awaitLoginSelector = (state) => state.appState.isWaitingForLogin;
export const getAccessToken = (state) => state.appState.accessToken;
export const getIdToken = (state) => state.appState.idToken;
export const getRefreshToken = (state) => state.appState.refreshToken;
export const getIntervalID = (state) => state.appState.getIntervalID;
export const getUser = (state) => state.appState.user;
export const getUserRoles = (state) => state.appState.userRoles;
export const hasChildCareRole = (state) => state.appState.userHasChildCareRole;
export const hasReferralRole = (state) => state.appState.userHasReferralRole;
export const hasReferralViewRole = (state) => state.appState.userHasReferralViewRole;
export const hasReferralEditRole = (state) => state.appState.userHasReferralEditRole;
export const hasPortalRoles = (state) => state.appState.userHasPortalRoles;
export const getLogoutWarningFlag = (state) => state.appState.logoutWarning;
export const isWalkthroughHappening = (state) => state.appState.isWalkthrough;
export const getMonths = (state) => state.appState.months;
export const getShowReportAnIssueButton = (state) => state.appState.showReportAnIssueButton;
export const getShowHelpButton = (state) => state.appState.showHelpButton;
export const getShowLogoutButton = (state) => state.appState.showLogoutButton;
export const getCSRFToken = (state) => state.appState.csrfToken;
export const awaitCSRFSelector = (state) => state.appState.isWaitingForCSRFToken;
export const refreshCounter = (state) => state.appState.refreshCounter;

export default appStateSlice.reducer;
