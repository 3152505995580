import React from 'react';
import PropTypes from 'prop-types';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import withStyles from '@mui/styles/withStyles';
import { styleWithRoot } from '../styles/CHMoreHorizIcon.styles';

const CssMoreHorizIcon = withStyles(styleWithRoot)(MoreHorizIcon);

const CHMoreHorizIcon = ({
  shouldShow, handleRowClick, role, dataTestId,
}) => {
  const style = {
    height: '24px',
    width: '24px',
    // marginRight: '20px', - this was causing the table row to resize upon hovering
    marginTop: '6px',
  };
  return shouldShow === true
    ? <CssMoreHorizIcon data-testid={dataTestId} role={role} onClick={handleRowClick} />
    : <div data-testid="not-button" style={style} />;
};

CHMoreHorizIcon.defaultProps = {
  handleRowClick: null,
};

CHMoreHorizIcon.propTypes = {
  shouldShow: PropTypes.bool.isRequired,
  handleRowClick: PropTypes.func,
  role: PropTypes.string.isRequired,
  dataTestId: PropTypes.string.isRequired,
};

export default CHMoreHorizIcon;
