import React from 'react';
import PropTypes from 'prop-types';
import { Card } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';

const CHNameCard = ({ first, middle, last }) => (
  <Card>
    <Table aria-label="simple table">
      <TableBody>

        <TableRow>
          <TableCell scope="row" className="card-label-column">
            First Name
          </TableCell>
          <TableCell align="left">
            {first || '-'}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell scope="row" className="card-label-column">
            Middle Initial
          </TableCell>
          <TableCell align="left">
            {middle || '-'}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell scope="row" className="card-label-column">
            Last Name
          </TableCell>
          <TableCell align="left">
            {last || '-'}
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  </Card>
);

CHNameCard.propTypes = {
  first: PropTypes.string,
  middle: PropTypes.string,
  last: PropTypes.string,
};

CHNameCard.defaultProps = {
  first: '-',
  middle: '-',
  last: '-',
};

export default CHNameCard;
