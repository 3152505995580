import React from 'react';
import '../App.css';
import logo from '../assets/no_hits.svg';

const NoResultsFound = () => {
  const nohits = 'Try entering new search criteria';
  return (
    <section className="start-search">
      <h1> We couldn&apos;t find anything </h1>
      <img title="Nothing found" alt={nohits} src={logo} />
      <p>{nohits}</p>
    </section>
  );
};

export default NoResultsFound;
