import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { Card } from '@mui/material';
import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TabPanel from '../components/TabPanel';

const AntTabs = withStyles({
  root: {
    borderBottom: '1px solid #e8e8e8',
  },
  indicator: {
    backgroundColor: '#3f51b5',
  },
})(Tabs);

const AntTab = withStyles((theme) => ({
  root: {
    textTransform: 'none',
    minWidth: 72,
    fontWeight: theme.typography.fontWeightBold,
    marginRight: theme.spacing(4),
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:hover': {
      color: '#2738A5',
      opacity: 1,
    },
    '&$selected': {
      color: '#3f51b5',
    },
    '&:focus': {
      color: '#2738A5',
    },
  },
  selected: {},
}))((props) => <Tab disableRipple {...props} />);

function insertPhoneNumberDividers(rawPhoneNumberString) {
  let phoneNumString = '';
  let phoneExtString = '';

  // split out the EXT
  const rawPhoneNumberArray = rawPhoneNumberString.split(' EXT ');
  const phoneNum = rawPhoneNumberArray[0];
  const ext = rawPhoneNumberArray[1];

  // check if the length is exactly 10 for phone number portion
  if (phoneNum.length === 10) {
    phoneNumString = `(${phoneNum.substring(0, 3)}) ${phoneNum.substring(3, 6)}-${phoneNum.substring(6, 10)}`;
  }
  else {
    phoneNumString = phoneNum;
  }

  // check if there is an extension
  if (ext) {
    phoneExtString = ` EXT ${ext}`;
  }

  return `${phoneNumString}${phoneExtString}`;
}

function formatPhoneNumbers(rawPhoneNumbersString) {
  if (rawPhoneNumbersString) {
    // split on pipe for multiple phone numbers
    const formattedPhoneListArray = rawPhoneNumbersString.split('|').map((n) => insertPhoneNumberDividers(n));
    // return joined with BR tag
    return formattedPhoneListArray.join('\n');
  }
  return null;
}

function formatDate(dateString) {
  if (!dateString || dateString === '12/01/9999' || dateString === '12/31/9999') {
    return '-';
  }
  return dateString;
}

const RFTabbedSection = ({ referral }) => {
  const [referralTabValue] = React.useState(0);

  function a11yProps(index) {
    return {
      id: `scrollable-auto-tab-${index}`,
      'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
  }

  return (
    <div>
      <AntTabs
        value={referralTabValue}
        indicatorColor="primary"
        textColor="primary"
        variant="scrollable"
        scrollButtons="auto"
      >
        <AntTab
          className="parent-tabs"
          key={referral.REFERRAL_ID || '-'}
          label={`Referral ID: ${referral.REFERRAL_ID || '-'}`}
          {...a11yProps(referral)}
        />
      </AntTabs>
      <TabPanel
        key={`panel-${referralTabValue}-${referral.REFERRAL_ID || '-'}`}
        value={referralTabValue}
        index={referralTabValue}
      >
        <section className="details-container">
          <div>
            <Grid container spacing={5}>
              <Grid item xs={6}>
                <Card>
                  <Table aria-label="simple table">
                    <TableBody>
                      <TableRow>
                        <TableCell scope="row" className="card-label-column">
                          First Name
                        </TableCell>
                        <TableCell align="left">
                          {referral.FIRST_NAME || '-'}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell scope="row" className="card-label-column">
                          Middle Initial
                        </TableCell>
                        <TableCell align="left">
                          {referral.MID_NAME || '-'}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell scope="row" className="card-label-column">
                          Last Name
                        </TableCell>
                        <TableCell align="left">
                          {referral.LAST_NAME || '-'}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell scope="row" className="card-label-column">
                          Date of Birth
                        </TableCell>
                        <TableCell align="left">
                          {formatDate(referral.DOB)}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell scope="row" className="card-label-column">
                          Language
                        </TableCell>
                        <TableCell align="left">
                          {referral.LANG_DECODE || '-'}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </Card>
              </Grid>
              <Grid item xs={6}>
                <Card>
                  <Table aria-label="simple table">
                    <TableBody>
                      <TableRow>
                        <TableCell scope="row" className="card-label-column">
                          Need Category
                        </TableCell>
                        <TableCell align="left">
                          {referral.NEED_CAT_DECODE || '-'}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell scope="row" className="card-label-column">
                          Need Type
                        </TableCell>
                        <TableCell align="left">
                          {referral.NEED_TYPE_DECODE || '-'}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell scope="row" className="card-label-column">
                          Need Begin Date
                        </TableCell>
                        <TableCell align="left">
                          {formatDate(referral.NEED_BEG_DATE)}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell scope="row" className="card-label-column">
                          Need End Date
                        </TableCell>
                        <TableCell align="left">
                          {formatDate(referral.NEED_END_DATE)}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell scope="row" className="card-label-column">
                          Program
                        </TableCell>
                        <TableCell align="left">
                          {referral.PGM_DECODE || '-'}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </Card>
              </Grid>
            </Grid>
            <Grid container spacing={5}>
              <Grid item xs={6}>
                <h5> Contact Information </h5>
                <Card>
                  <Table aria-label="simple table">
                    <TableBody>
                      <TableRow>
                        <TableCell scope="row" className="card-label-column">
                          Cell
                        </TableCell>
                        <TableCell align="left" className="wrap-new-lines">
                          {formatPhoneNumbers(referral.CELL) || '-'}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell scope="row" className="card-label-column">
                          Home
                        </TableCell>
                        <TableCell align="left" className="wrap-new-lines">
                          {formatPhoneNumbers(referral.HOME) || '-'}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell scope="row" className="card-label-column">
                          Main
                        </TableCell>
                        <TableCell align="left" className="wrap-new-lines">
                          {formatPhoneNumbers(referral.MAIN) || '-'}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell scope="row" className="card-label-column">
                          Mailing
                        </TableCell>
                        <TableCell align="left">
                          {referral.MAILING || '-'}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell scope="row" className="card-label-column">
                          Physical
                        </TableCell>
                        <TableCell align="left">
                          {referral.PHYSICAL || '-'}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell scope="row" className="card-label-column">
                          E-mail Address
                        </TableCell>
                        <TableCell align="left">
                          {referral.REFRL_EMAIL || '-'}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </Card>
              </Grid>
              <Grid item xs={6}>
                <h5> Case Manager Information </h5>
                <Card>
                  <Table aria-label="simple table">
                    <TableBody>
                      <TableRow>
                        <TableCell scope="row" className="card-label-column">
                          Name
                        </TableCell>
                        <TableCell align="left">
                          {referral.CASE_MANAGER_NAME || '-'}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell scope="row" className="card-label-column">
                          Phone Number
                        </TableCell>
                        <TableCell align="left" className="wrap-new-lines">
                          {formatPhoneNumbers(referral.CASE_MANAGER_PHONE) || '-'}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell scope="row" className="card-label-column">
                          E-mail Address
                        </TableCell>
                        <TableCell align="left">
                          {referral.CASE_MANAGER_EMAIL || '-'}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </Card>
              </Grid>
            </Grid>
            <Grid container spacing={5}>
              <Grid item xs={12}>
                <h4> Referral Comments </h4>
                <Card>
                  <Table aria-label="simple table">
                    <TableBody>
                      <TableRow>
                        <TableCell align="left">
                          <pre className="MuiTableCell-root" style={{ borderBottom: 0, padding: 0 }}>
                            {referral.REFRL_DESCR}
                          </pre>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </Card>
              </Grid>
            </Grid>
          </div>
        </section>
      </TabPanel>
    </div>
  );
};

RFTabbedSection.propTypes = {
  referral: PropTypes.shape({
    REFERRAL_ID: PropTypes.number,
    FIRST_NAME: PropTypes.string,
    MID_NAME: PropTypes.string,
    LAST_NAME: PropTypes.string,
    DOB: PropTypes.string,
    CHILD_PERS_ID: PropTypes.number,
    CHILD_FIRST_NAME: PropTypes.string,
    CHILD_MID_NAME: PropTypes.string,
    CHILD_LAST_NAME: PropTypes.string,
    CHILD_SUFFIX: PropTypes.string,
    CHILD_DOB: PropTypes.string,
    LANG_DECODE: PropTypes.string,
    NEED_CAT_CODE: PropTypes.string,
    NEED_CAT_DECODE: PropTypes.string,
    NEED_TYPE_CODE: PropTypes.string,
    NEED_TYPE_DECODE: PropTypes.string,
    NEED_BEG_DATE: PropTypes.string,
    NEED_END_DATE: PropTypes.string,
    REFRL_DESCR: PropTypes.string,
    PGM_DECODE: PropTypes.string,
    CELL: PropTypes.string,
    HOME: PropTypes.string,
    MAIN: PropTypes.string,
    MAILING: PropTypes.string,
    PHYSICAL: PropTypes.string,
    REFRL_EMAIL: PropTypes.string,
    CASE_MANAGER_NAME: PropTypes.string,
    CASE_MANAGER_PHONE: PropTypes.string,
    CASE_MANAGER_EMAIL: PropTypes.string,
  }).isRequired,
};

export default RFTabbedSection;
