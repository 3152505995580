import React from 'react';
import '../App.css';
import PropTypes from 'prop-types';
import logo from '../assets/search_image.svg';

const StartReferralSearchLogo = ({ name }) => {
  const startSearch = 'Please enter referral details on the left';
  return (
    <section className="start-search">
      <h1 style={{ fontSize: 70, margin: 0 }}>CalSAWS Referral Portal</h1>
      <p>
        Welcome back
        {' '}
        {name}
      </p>
      <img title="Search" alt={startSearch} src={logo} style={{ maxWidth: 400 }} />
      <p>{startSearch}</p>
    </section>
  );
};

StartReferralSearchLogo.propTypes = {
  name: PropTypes.string.isRequired,
};

StartReferralSearchLogo.defaultProps = {
};

export default StartReferralSearchLogo;
