const style = {
  '& .MuiTable-root': {
    width: '100%',
  },
  '& .MuiTableRow-root': {
    borderLeft: '5px solid transparent',
    '&.info-row': {
      '&:hover': {
        borderLeft: '5px solid #3f51b5',
        backgroundColor: 'rgba(226, 228, 246, .80)',
        transition: 'background-color 0.2s',
      },
    },
  },
  '& .MuiTableCell-root': {
    padding: '22px',
    fontWeight: 500,
    fontSize: '18px',
    '&.last-cell': {
      cursor: 'pointer',
    },
    '&.header-row': {
      borderBottom: 'none',
      color: '#3f51b5',
      fontWeight: 500,
      fontSize: '17px',
    },
    '&:first-of-type': {
      paddingLeft: '85px',
      paddingRight: '0px',
    },
    '&:not(:first-of-type)': {
      // paddingLeft: '30px',
      // paddingRight: '0px',
    },
    '&:last-of-type': {
      // paddingRight: '105px',
    },
  },
};
export const styleWithRoot = {
  root: {
    ...style,
  },
};

export default style;
