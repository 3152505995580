import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import SysDownLogo from '../components/SysDownLogo';
import {
  awaitLoginSelector, loggedInSelector, getCSRFToken, setCSRFToken, awaitCSRFSelector, initCSRFToken, getAccessToken,
  hideHelpButton,
  showReportAnIssueButton, showHelpButton, showLogoutButton,
  hasChildCareRole, hasReferralRole,
} from '../store/AppState.slice';
import '../App.css';
import logo from '../assets/search_image.svg';
import CHLoading from '../shared-components/CHLoading';
import CHButton from '../shared-components/CHButton';
import { initializeCSRF } from '../api/apiRequest';

function getBrowserUrlParams() {
  return window.location.search.substring(1).split('&').reduce((result, value) => {
    const parts = value.split('=');
    if (parts[0]) {
      // eslint-disable-next-line no-param-reassign
      result[decodeURIComponent(parts[0])] = decodeURIComponent(parts[1]);
    }
    return result;
  }, {});
}

const Welcome = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const loginInitiated = useSelector(awaitLoginSelector);
  const isLoggedIn = useSelector(loggedInSelector);
  const showChildCareButton = useSelector(hasChildCareRole);
  const showReferralButton = useSelector(hasReferralRole);
  const accessToken = useSelector(getAccessToken);
  const csrfToken = useSelector(getCSRFToken);
  const isWaitingForCSRFToken = useSelector(awaitCSRFSelector);

  // initialize the CSRF Tokens for this session
  useEffect(() => {
    if (!isWaitingForCSRFToken && !csrfToken && isLoggedIn && accessToken) {
      dispatch(initCSRFToken());
      initializeCSRF(accessToken, {}).then((data) => {
        if (data.SESSION_ID && data.CSRF_TOKEN) {
          // data.SESSION_ID goes in browser session storage under key: CSRF_SESSION_TOKEN
          sessionStorage.setItem('CSRF_SESSION_TOKEN', data.SESSION_ID);
          // current timestamp goes in browser session storage under key: CSRF_REFRESHED
          sessionStorage.setItem('CSRF_REFRESHED', new Date().getTime());
          // data.CSRF_TOKEN goes in app state slice via dispatch(setCSRFToken())
          dispatch(setCSRFToken({ csrfToken: data.CSRF_TOKEN }));
        }
        else {
          console.debug('An error ocurred obtaining CSRF Token during Init!  Retrying up to 5 times.');
          let { retryCount } = getBrowserUrlParams();

          if (!retryCount) {
            retryCount = 0;
          }
          retryCount++;
          console.log('Retry Count: ', retryCount);
          if (retryCount <= 5) {
            // eslint-disable-next-line prefer-template
            window.location.href = process.env.REACT_APP_ORIGIN + '?&retryCount=' + retryCount;
          }
        }
      }).catch(() => {
        console.debug('An error ocurred obtaining CSRF Token!  Retrying up to 5 times.');
        let { retryCount } = getBrowserUrlParams();

        if (!retryCount) {
          retryCount = 0;
        }
        retryCount++;
        console.log('Retry Count: ', retryCount);
        if (retryCount <= 5) {
          // eslint-disable-next-line prefer-template
          window.location.href = process.env.REACT_APP_ORIGIN + '?&retryCount=' + retryCount;
        }
        else {
          console.log('Cannot connect to backend!');
        }
      });
    }
  }, [isWaitingForCSRFToken, csrfToken, isLoggedIn, accessToken, dispatch]);

  const handleChildCarePortal = () => {
    dispatch(showReportAnIssueButton());
    dispatch(showHelpButton());
    dispatch(showLogoutButton());
    history.push('/childCareSearch');
  };

  const handleReferralPortal = () => {
    dispatch(showReportAnIssueButton());
    dispatch(hideHelpButton());
    dispatch(showLogoutButton());
    history.push('/referralSearch');
  };

  useEffect(() => {
    // set the window title
    document.title = 'CalSAWS Administrator Portal';
    // default the top buttons when the welcome page loads
    dispatch(showReportAnIssueButton());
    dispatch(hideHelpButton());
    dispatch(showLogoutButton());
  }, [dispatch]);

  return (
    <>
      {
        loginInitiated === false && csrfToken
          ? (
            <>
              <section className="details-page">
                <section className="start-search">
                  <h1>
                    Welcome to the CalSAWS Administrator Portal
                  </h1>
                  <img
                    title="Search"
                    alt="search logo"
                    src={logo}
                    style={
                      {
                        width: '100%', height: '100%', maxWidth: '400px', maxHeight: '303px',
                      }
                    }
                  />

                  {showChildCareButton ? (
                    <>
                      <br />
                      <CHButton
                        style={{ width: '250px' }}
                        id="child-care-portal-btn"
                        color="primary"
                        variant="outlined"
                        buttonText="ACCESS THE CHILD CARE ADMIN PORTAL"
                        classNames="push-down"
                        handleClick={handleChildCarePortal}
                      />
                    </>
                  ) : null}
                  {showReferralButton ? (
                    <>
                      <br />
                      <CHButton
                        id="child-care-portal-btn"
                        color="primary"
                        variant="outlined"
                        buttonText="ACCESS THE REFERRAL PORTAL"
                        classNames="push-down"
                        handleClick={handleReferralPortal}
                      />
                    </>
                  ) : null}
                </section>
              </section>
            </>
          )
          : (
            <>
              {getBrowserUrlParams().retryCount > 4 ? (
                <section className="below-header">
                  <SysDownLogo />
                </section>

              ) : <CHLoading applyEllipses />}
            </>
          )
      }
    </>
  );
};

export default Welcome;
