const style = {
  '& .MuiInputLabel-root': {
    borderColor: 'grey',
    fontWeight: '500',
    '&.Mui-error': {
      color: '#b71c1c !important',
    },
  },
  '& .MuiFormHelperText-root': {
    '&.Mui-error': {
      color: '#b71c1c',
      fontWeight: 400,
    },
  },
  '& .MuiInputBase-root': {
    letterSpacing: '0.13em',
  },
  '&.MuiFormControl-root': {
    marginTop: '0.5em',
    marginBottom: '0.5em',
  },
  '& .MuiInputLabel-outlined': {
    '&.MuiInputLabel-shrink': {
      transform: 'translate(14px, -6px) scale(1)',
    },
  },
  '& .MuiOutlinedInput-input': {
    letterSpacing: 'normal',
    '&::placeholder': {
      opacity: 0.6,
      fontWeight: 500,
      fontSize: '18px',
    },
  },
  '& .MuiOutlinedInput-root': {
    borderRadius: '5px',
    '& fieldset': {
      border: '1px solid rgb(40, 72, 141)',
      borderColor: 'rgb(40, 72, 141) !important',
    },
    '&.Mui-error fieldset': {
      borderColor: '#b71c1c !important',
    },
  },
};

const standard = {
  '& .MuiInputLabel-root': {
    color: 'grey',
    fontWeight: '500',
    '&.Mui-error': {
      color: 'rgb(40, 72, 141) !important',
    },
  },
  '& .MuiInputLabel-outlined': {
    '&.MuiInputLabel-shrink': {
      // transform: 'translate(14px, -6px) scale(0.90)',
    },
  },
  '& .MuiOutlinedInput-input': {
    // padding: '17.5px 14px',
    '&::placeholder': {
      color: 'green',
      opacity: 1,
      fontWeight: 600,
    },
  },
  '& .MuiOutlinedInput-root': {
    borderRadius: '5px',
    '& fieldset': {
      border: '2px solid grey',
      borderColor: 'orange !important',

    },
    '&:hover fieldset': {
      borderColor: 'yellow',
    },
    '&:focus fieldset': {
      borderColor: 'pink',
    },
    '&.Mui-focused fieldset': {
      borderColor: 'grey',
    },
    '&.Mui-error fieldset': {
      borderColor: 'rgb(40, 72, 141) !important',
    },
  },
};

// rgb(40, 72, 141)
export const labelHidden = {
  root: {
    // hidden but still accessible element
    '&.MuiInputLabel-root': {
      position: 'absolute',
      overflow: 'hidden',
      clip: 'rect(0 0 0 0)',
      height: '1px',
      width: '1px',
      margin: '-1px; padding: 0; border: 0',
    },
  },
};

export const styleWithRoot = {
  root: (props) => {
    switch (props.type) {
      case 'search':
        return { ...style };
      default:
        return { ...standard };
    }
  },
};

export default style;
