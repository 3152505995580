import React from 'react';
import PropTypes from 'prop-types';
import { Card, TableHead } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';

const CHTableCard = ({ dataList, headers }) => (
  <Card>
    <Table aria-label="simple table">
      <TableHead>
        <TableRow>
          {
            headers.map((i) => (
              <TableCell key={`${i}-header`} scope="row">
                {i}
              </TableCell>
            ))
          }
        </TableRow>
      </TableHead>
      <TableBody>
        {
          dataList.map((row, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <TableRow key={`${index}-row`}>
              {Object.keys(dataList[0]).map((i, mapIndex) => (
                // eslint-disable-next-line react/no-array-index-key
                <TableCell key={`${index}-${row[i]}-${mapIndex}-data`} scope="row">
                  {row[i] || '-'}
                </TableCell>
              ))}

            </TableRow>
          ))
        }
      </TableBody>
    </Table>
  </Card>

);

CHTableCard.propTypes = {
  dataList: PropTypes.arrayOf(PropTypes.shape()),
  headers: PropTypes.arrayOf(PropTypes.string),
};

CHTableCard.defaultProps = {
  dataList: [],
  headers: [],
};

export default CHTableCard;
