import React, { useState } from 'react';
import FormControl from '@mui/material/FormControl';
import Radio from '@mui/material/Radio';
import Grid from '@mui/material/Grid';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';

export default function CHRadioGroupRow({
  // eslint-disable-next-line react/prop-types
  id, isRequired, handler, errorHandler, selectedValue,
}) {
  const [searchBy, setSearchBy] = useState(selectedValue || 'searchByName');
  const [value, setValue] = useState({ required: isRequired, id, text: '' });
  const [pristine, setPristine] = useState(true);

  const handleChange = (e) => {
    const newValue = { ...value, text: e.target.value };
    setSearchBy(e.target.value);
    handler({ required: isRequired, id, text: e.target.value });
    errorHandler({ value: e.target.value, pristine });
    setValue(newValue);
    setPristine(false);
  };

  return (
    <FormControl
      fullWidth
    >
      <RadioGroup
        row
        aria-labelledby="Search by Name or Case"
        name={id}
        defaultValue="searchByName"
        value={searchBy}
        onChange={handleChange}
        sx={{ width: 1 }}
      >
        <Grid container spacing={2}>
          <Grid item xs>
            <FormControlLabel
              value="searchByName"
              control={<Radio size="small" />}
              label="Search by Name"
              className="primary"
              sx={{
                '& .MuiFormControlLabel-label': {
                  fontWeight: 'bold',
                },
              }}
            />
          </Grid>
          <Grid
            item
            xs
            sx={{
              textAlign: 'right',
            }}
          >
            <FormControlLabel
              value="searchByCase"
              control={
                (
                  <Radio
                    size="small"
                  />
                )
              }
              label="Search by Case"
              className="primary"
              sx={{
                '& .MuiFormControlLabel-label': {
                  fontWeight: 'bold',
                },
                marginRight: 0,
              }}
            />
          </Grid>
        </Grid>
      </RadioGroup>
    </FormControl>
  );
}
